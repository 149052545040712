/** @jsxImportSource @emotion/react */
import UDText from "../../../../ud-ui/components/text";
import { theme } from "../../../../../styles/theme/theme";
import RotatePhoneIcon from "../../../../ud-ui/components/icon/rotate-phone-icon";
import ArrowUpIcon from "../../../../ud-ui/components/icon/arrow-up-icon";
import TMLabel from "../../components/TMLabel";
import { DivProps } from "../../../../../typings";
import { RotateBlock, WaterMarkCss } from "./style";

function RotatePage(props: DivProps) {
  return (
    <RotateBlock {...props}>
      <UDText
        className='fw-700'
        type='subhead'
        style={{ fontSize: 22 }}
        color={theme.colors.DarkYellow}
      >
        Внимание!
      </UDText>
      <UDText type='title-3' className='mt-3' color={theme.colors.WhiteGray}>Переверните устройство в</UDText>
      <UDText type='title-3' color='white'>горизонатльноую ориентацию</UDText>
      <RotatePhoneIcon className='mt-7'/>
      <ArrowUpIcon style={{ position: "relative", right: -57, marginTop: 5 }}/>
      <TMLabel
        fontSize={320}
        lineHeight={320}
        style={{ position: "absolute", bottom: -49 }}
        css={WaterMarkCss}
      />
    </RotateBlock>
  );
}

export default RotatePage;
