import { MainBlock } from "./styles";
import Header from "../../../components/board/header";
import Score from "../../../components/board/score";
import Advertisement from "../../../components/board/advertisement";
import { Team } from "../../../../domain/intefaces";
import size from "../../../../domain/constants/size";
import RotatePage from "../../shared/rotate-page";
import Period from "../../../components/board/period";
import Timer from "../../../components/board/timer";

interface IProps {
  teams: [Team, Team],
  period: number,
  time: number,
  roundTo: 'floor' | 'ceil'
}

function Mobile(props: IProps) {
  const {
    teams,
    period,
    time,
    roundTo,
  } = props
  
  const orientation = window.screen.orientation.type.includes('portrait') ? 'portrait' : 'landscape'
  const isMobileInPortrait = orientation === 'portrait' && window.innerWidth < size.mobile.width
  if (isMobileInPortrait) {
    return (<RotatePage/>)
  }
  
  return (
    <MainBlock>
      <Header value='ПРИВЕТСТВУЕМ УЧАСТНИКОВ ИГРЫ'/>
      <div className='flex-center flex-row h-100'>
        <Score id={'score'} score={teams[ 0 ].score} teamName={teams[ 0 ].name}/>
        <div className='flex-column'>
          <Period id='period' value={period}/>
          <Timer id='timer' milliseconds={time} roundTo={roundTo}/>
        </div>
        <Score id={'score'} score={teams[ 1 ].score} teamName={teams[ 1 ].name}/>
      </div>
      <Advertisement/>
    </MainBlock>
  );
}

export default Mobile;
