import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { TeamGroup } from 'modules/tourneys/domain/interfaces/TeamGroup';
import { Team } from 'modules/teams/domain/interfaces/Team';
import UDText from 'modules/ud-ui/components/text';
import EditIcon from 'modules/ud-ui/components/icon/edit-icon';
import { useModal } from 'modules/ud-ui/components/modal/useModal';
import UDButton from 'modules/ud-ui/components/button';
import { AppDispatch } from 'store/store';
import { Table } from './stage-group-table'
import { deleteStageTeamGroup, upsertStageTeamGroup } from '../../../../../../store/stages/stages.actions';

const StateGroupWrapper = styled.div`
  margin-bottom: 40px;
`;

const EditButtonWrapper = styled.span`
  cursor: pointer;
`;

const EditButton = (props: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <EditButtonWrapper {...props}>
      <EditIcon width={16} height={16} />
    </EditButtonWrapper>
  );
};

type StageGroupProps = {
  group: TeamGroup;
  teams: Team[];
};

const StageGroup = ({ group, teams }: StageGroupProps) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [groupTeams, setGroupTeams] = useState<(Team | null)[]>(group.teams);
  const dispatch: AppDispatch = useDispatch();

  const {
    open: openDeleteModal,
    close: closeDeleteModal,
    Modal: ConfirmDeleteModal,
  } = useModal({ defaultShow: false });

  const handleEditModeClick = useCallback(() => {
    setGroupTeams([
      ...groupTeams,
      null,
    ]);

    setIsEditMode(true);
  }, [groupTeams]);

  const handleSaveClick = useCallback(() => {
    const newGroupTeams = groupTeams.filter(team => !!team) as Team[];
    const teamIds = newGroupTeams.map(t => t.id);

    dispatch(upsertStageTeamGroup({
      id: group.id,
      stageId: group.stageId,
      name: group.name,
      teamIds,
    })).then(() => {
      setGroupTeams(newGroupTeams);
      setIsEditMode(false);
    });
  }, [dispatch, group, groupTeams]);

  const handleRemoveTeam = useCallback((index: number) => {
    setGroupTeams([
      ...groupTeams.slice(0, index),
      ...groupTeams.slice(index + 1)
    ]);
  }, [groupTeams]);

  const handleTeamChange = useCallback((index: number, teamId: number) => {
    const team = teams.find(team => team.id === teamId);
    if (!team) {
      return;
    }

    const newTeams = groupTeams.map((item, i) => i === index ? team : item);

    if (!groupTeams[index]) {
      newTeams.push(null);
    }

    setGroupTeams(newTeams);
  }, [groupTeams, teams]);

  const handleDeleteStageGroupClick = useCallback(() => {
    dispatch(deleteStageTeamGroup(group.id));
  }, [dispatch, group.id]);

  return (
    <StateGroupWrapper>
      <UDText className="mb-4" type="title-3">
        {group.name}

        {!isEditMode && (
          <EditButton
            className="ml-4"
            onClick={handleEditModeClick}
          />
        )}
      </UDText>

      <Table
        isEditMode={isEditMode}
        teams={groupTeams}
        stageTeams={teams}
        statistics={group.statistics}
        editClick={handleEditModeClick}
        saveClick={handleSaveClick}
        deleteClick={openDeleteModal}
        removeTeamClick={handleRemoveTeam}
        teamChange={handleTeamChange}
      />

      <ConfirmDeleteModal
        header={{title: 'Подтвердить удаление', subtitle: 'Вы действительно хотите удалить группу?'}}
      >
        <div className='mt-10 d-flex justify-content-between'>
          <UDButton
            variant='secondary'
            className='mb-3'
            type='button'
            onClick={closeDeleteModal}
          >
            Отмена
          </UDButton>

          <UDButton
            variant='primary'
            className='mb-3'
            type='button'
            onClick={handleDeleteStageGroupClick}
          >
            Удалить
          </UDButton>
        </div>
      </ConfirmDeleteModal>
    </StateGroupWrapper>
  );
};

export default StageGroup;
