/** @jsxImportSource @emotion/react */
import TeamName from "../../../components/control-panel/team-name";
import { MainBlock } from "./styles";
import { Team, Time } from "../../../../domain/intefaces";
import Period from "../../../components/control-panel/period";
import size from "../../../../domain/constants/size";
import { Header } from "../../../components/typography";
import Score from "../../../components/control-panel/score";
import Timer from "../../../components/control-panel/timer";
import Button from "../../../components/button";
import MonitorWithStandIcon from "../../../../../ud-ui/components/icon/monitor-with-stand-icon";
import RotatePage from "../../shared/rotate-page";

interface IProps {
  teams: [Team, Team]
  handleChangeTeamName: (name: string, index: 0 | 1) => void
  handleChangePeriod: (period: number) => void
  handleChangeScore: (index: 0 | 1, score: number) => void
  handleChangeTimer: (milliseconds: number) => void
  handleToggleTimer: () => void
  handleToggleReverse: () => void
  handleOpenBoard: () => void
  time: number
  period: number
  timer: Time
}

function Mobile(props: IProps) {
  const {
    time,
    timer,
    teams,
    period,
    handleOpenBoard,
    handleChangeScore,
    handleChangeTimer,
    handleToggleTimer,
    handleChangePeriod,
    handleToggleReverse,
    handleChangeTeamName,
  } = props
  
  const orientation = window.screen.orientation.type.includes('portrait') ? 'portrait' : 'landscape'
  const isMobileInPortrait = orientation === 'portrait' && window.innerWidth <= size.mobile.width
  // const isMobileInLandscape = orientation === 'landscape' && window.innerHeight < size.mobile.height && window.innerWidth < size.tablet
  
  if (isMobileInPortrait) {
    return (<RotatePage/>)
  }
  
  return (
    <MainBlock>
      <div id='upper-block' className='flex-column flex-center mt-auto'>
        <Header>тайм</Header>
        <div className='flex-row gap-6'>
          <TeamName
            id='team-name'
            index={0}
            name={teams[ 0 ].name}
            setName={(name: string) => {handleChangeTeamName(name, 0)}}
          />
          <Period disabled={timer.isActive} id='period' value={period} setValue={handleChangePeriod}/>
          <TeamName
            id='team-name'
            index={1}
            name={teams[ 1 ].name}
            setName={(name: string) => {handleChangeTeamName(name, 1)}}
          />
        </div>
      </div>
      <div className='flex-row gap-7 mb-auto' style={{ marginTop: 10 }}>
        <Score id='score' setValue={(value) => handleChangeScore(0, value)} value={teams[ 0 ].score}/>
        <Timer
          id='timer'
          milliseconds={time}
          setValue={handleChangeTimer}
          isReverse={timer.isReverse}
          isTimerStart={timer.isActive}
          handlerToggleReverse={handleToggleReverse}
          handlerToggleTimer={handleToggleTimer}
        />
        <Score id='score' setValue={(value) => handleChangeScore(1, value)} value={teams[ 1 ].score}/>
      </div>
      
      <Button id='open-board-button' onClick={handleOpenBoard} variant='blue' shape='circle'>
        <MonitorWithStandIcon id='icon'/>
        Посмотреть табло
      </Button>
    </MainBlock>
  );
}

export default Mobile;
