/** @jsxImportSource @emotion/react */
import useModal from "../../../../../../ud-ui/components/new-modal/useModal";
import { OnlineModalCss } from "../styles";
import { useMemo } from "react";
import { generateUUID } from "../../../../../../utils/uuid";
import UDText from "../../../../../../ud-ui/components/text";
import { theme } from "../../../../../../../styles/theme/theme";
import UrlInput from "../urlInput";
import Button from "../../../../components/button";
import TMLabel from "../../../../components/TMLabel";
import { WaterMarkCss } from "../../../../components/styles";
import { Pages } from "../../../../../../navigation/domain/enums/pages";
import CrossThinIcon from "../../../../../../ud-ui/components/icon/cross-thin-icon";

const domain = window.location.origin

function useOnlineModal() {
  const { defaultOpen, close, Modal: OnlineModal } = useModal()
  
  const open = () => {
    defaultOpen(
      {
        containerProps: {
          css: OnlineModalCss,
          style: {
            padding: '42px 60px',
            width: '80%',
            maxWidth: 938,
            height: 479,
            overflow: "hidden",
            zIndex: 0,
          },
        },
      },
      <CrossThinIcon color={theme.colors.Gray} className='modal-close-icon'/>,
    )
  }
  
  const id = useMemo(() => {
    const localId = localStorage.getItem('board-id')
    if (localId) {return localId}
    
    const generatedId = generateUUID()
    localStorage.setItem('board-id', generatedId)
    return generatedId
  }, [])
  
  const panelUrl = `${domain}${Pages.MASTER_BOARDS.builders.controlPanel(id)}`
  const boardUrl = `${domain}${Pages.MASTER_BOARDS.builders.board(id)}`
  
  const handleOpenPanel = () => {
    window.open(panelUrl, '_blank')
  }
  
  const handleOpenBoard = () => {
    window.open(boardUrl, '_blank')
  }
  
  const Modal = () => (
    <OnlineModal>
      <UDText type={'title-large'} className={'fw-800 header'} color={theme.colors.DarkBlue}>
        Все готово чтобы начать!
      </UDText>
      <div className='controls flex-column'>
        <div className='flex-column controls-item'>
          <UDText
            className='description'
            color={theme.colors.Blue}
            type={'subhead'}
            style={{ fontSize: 22, marginTop: 37 }}
          >
            Ссылка для открытия
            <span className={'text fw-700'} style={{ fontSize: 22, marginTop: 37, color: 'inherit' }}>
          {' панели управления табло'}
        </span>
          </UDText>
          <div className={'mt-3 flex-row justify-content-between gap-6 control'}>
            <UrlInput className='url-open-panel' value={panelUrl}/>
            <Button onClick={handleOpenPanel} className='open-panel-button' variant={'dark-yellow'}>Открыть</Button>
          </div>
        </div>
        <div className='flex-column controls-item'>
          <UDText
            className='description'
            color={theme.colors.Blue}
            type={'subhead'}
            style={{ fontSize: 22, marginTop: 37 }}
          >
            Ссылка для открытия
            <span className={'text fw-700'} style={{ fontSize: 22, marginTop: 37, color: 'inherit' }}>
          {' табло'}
        </span>
            , можете им поделиться
          </UDText>
          <div className={'mt-3 flex-row justify-content-between gap-6 control'}>
            <UrlInput className='url-open-panel' value={boardUrl}/>
            <Button onClick={handleOpenBoard} className='open-panel-button' variant={'dark-yellow'}>Открыть</Button>
          </div>
        </div>
      </div>
      <UDText
        className='tip'
        type={'subhead'}
        style={{ fontSize: 18, marginTop: 34, width: '70%', lineHeight: 'unset', fontWeight: 300 }}
        color={'#8E8E8E'}
      >
        Скопируйте ссылку для того, чтобы поделиться ей или открыть в новой вкладке браузера.
      </UDText>
      
      <TMLabel className='watermark' fontSize={320} lineHeight={320} css={WaterMarkCss}/>
    </OnlineModal>
  )
  
  return { open, close, Modal }
}

export default useOnlineModal
