import { useSelector } from "react-redux";
import {
  selectChampionship,
  selectFormatStartDate,
  selectFouls,
  selectGoals,
  selectLoading,
  selectMatch,
  selectOrganizer,
  selectSynchronization,
  selectTeams,
} from "../../store/gameInfo/selectors";
import { Championship, Organizer } from "../../domain/interfaces/gameInfo/gameInfoMapped";
import { Fouls, Goals, Match, Teams } from "../../domain/interfaces/gameInfo/state";

export interface GameInfo {
  organizer?: Organizer;
  match?: Match
  championship?: Championship
  teams?: Teams
  isSynchronization: boolean
  isSynchronize: boolean
  isLoading: boolean
  isLoaded: boolean
  goals: Goals
  fouls: Fouls
  formatStartDate: {
    date: string
    time: string
  }
}

const useGameInfo = (): GameInfo => {
  const organizer = useSelector(selectOrganizer)
  const match = useSelector(selectMatch)
  const championship = useSelector(selectChampionship)
  const teams = useSelector(selectTeams)
  const sync = useSelector(selectSynchronization)
  const load = useSelector(selectLoading)
  const goals = useSelector(selectGoals)
  const fouls = useSelector(selectFouls)
  const formatStartDate = useSelector(selectFormatStartDate)
  
  return {
    organizer,
    match,
    championship,
    teams,
    ...sync,
    ...load,
    goals,
    fouls,
    formatStartDate,
  }
}

export default useGameInfo
