import Logo from "./logo";
import UDText from "../../../../../ud-ui/components/text";
import { MainBlock } from "./styles";
import React from "react";
import { DivProps } from "../../../../../../typings";

interface RegularTextProps {
  children: React.ReactNode;
}

const RegularText = (props: RegularTextProps) => {
  return (
    <UDText
      className={'fw-400'}
      color={'#B5B7C5'}
      style={{ fontSize: 22, lineHeight: '28px' }}
      type={'subhead'}
    >
      {props.children}
    </UDText>
  );
};

const HighlightText = (props: RegularTextProps) => {
  return (
    <span
      className={'text fw-700'}
      style={{ fontSize: 22, color: 'white' }}
    >
      {props.children}
    </span>
  );
};

function LeftSide(props: DivProps) {
  
  return (
    <MainBlock {...props}>
      <Logo/>
      <UDText style={{ marginTop: 30 }} color={'white'} className={'fw-800 header'} type={'title-huge'}>
        Привет! Создай свое табло для матча
      </UDText>
      <div style={{ marginTop: 30 }} className={'flex-column gap-2 description'}>
        <RegularText>
          Это универсальное табло для трансляции матчей под вашим управлением через панель управления.
        </RegularText>
        <RegularText>
          Вы можете создать табло как для <HighlightText>офлайн</HighlightText> так и
          для <HighlightText>онлайн</HighlightText> <HighlightText>режимов</HighlightText>.
        </RegularText>
        <RegularText>
          <HighlightText>Офлайн</HighlightText> табло будет доступно только вам, вы можете использовать его, например,
          для вывода на табло стадиона в
          реалтайм времени.
        </RegularText>
        <RegularText>
          <HighlightText>Онлайн</HighlightText> табло можно поделиться по ссылке. При этом управление табло будет
          доступно только вам. Его можно
          публиковать, например, на сайтах или других онлай трансляциях.
        </RegularText>
      </div>
    </MainBlock>
  );
}

export default LeftSide;
