import Counter from "./counter";
import { Header, Reset } from "../typography";
import getFontSize from "../../../domain/helpers/getFontSize";
import { DivProps } from "../../../../../typings";
import classNames from "classnames";

interface Props extends DivProps {
  value: number
  setValue: (value: number) => void
}

function Score(props: Props) {
  const { value, setValue, ...rest } = props
  
  const fontSize = getFontSize({
    mobile: 100,
    tablet: 180,
    desktop: 240,
  })
  
  return (
    <div {...rest} className={classNames('flex-center flex-column', rest.className)}>
      <Header>счет</Header>
      <Counter
        value={value}
        setValue={setValue}
        fontSize={fontSize}
      />
      <Reset onClick={() => setValue(0)}>
        СБРОСИТЬ
      </Reset>
    </div>
  );
}

export default Score;
