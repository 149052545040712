import Minus from "./minus";
import UDText from "../../../../ud-ui/components/text";
import { theme } from "../../../../../styles/theme/theme";
import Plus from "./plus";
import React from "react";

interface IProps {
  setValue: (value: number) => void
  value: number
  fontSize?: number
  disabled?: boolean
}

function Counter(props: IProps) {
  const {
    value,
    setValue,
    fontSize = 100,
    disabled,
  } = props
  
  const handleIncrement = () => {
    if (disabled) return
    setValue(value + 1)
  }
  
  const handleDecrement = () => {
    if (disabled) return
    setValue(value - 1)
  }
  
  return (
    <div className='flex-row flex-center gap-4'>
      <Minus disabled={disabled} id='counter-button' onClick={handleDecrement}/>
      <UDText
        id='value'
        type={'subhead'}
        color={theme.colors.DarkYellow}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
          fontSize: fontSize,
          lineHeight: `${fontSize}px`,
          fontFamily: 'Saira Stencil One',
          userSelect: 'none',
          fontVariantNumeric: 'tabular-nums',
        }}
      >
        {value}
      </UDText>
      <Plus disabled={disabled} id='counter-button' onClick={handleIncrement}/>
    </div>
  );
}

export default Counter;
