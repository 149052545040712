import React, { ReactElement, useCallback } from 'react';
import { FieldProps, useFormikContext } from 'formik';
import UDSelect, { UDSelectProps } from './component';
import { UDFormComponentProps } from '../types';
import UDFormFieldWrapper from '../../wrapper';

type UDFormSelectProps<isMulti extends boolean> = UDFormComponentProps & UDSelectProps<isMulti> & {
  selectComponent?: (props: any) => ReactElement<any, any> | null;
};

type Returning = <isMulti extends boolean = false>(
  props: UDFormSelectProps<isMulti>,
) => JSX.Element;

const UDFormSelect: Returning = (props) => {
  const {
    name,
    containerProps,
    required,
    label,
    labelProps,
    selectComponent,
    ...otherSelectProps
  } = props;

  const { setFieldValue } = useFormikContext();
  const onChange = useCallback(
    (value: any, isMulti: any) => {
      setFieldValue(name, value);

      if (props.onChange) {
        props.onChange(value, isMulti);
      }
    },
    [name, props, setFieldValue],
  );

  const Component = selectComponent || UDSelect;

  return (
    <UDFormFieldWrapper
      name={name}
      containerProps={containerProps}
      label={label}
      labelProps={labelProps}
      required={required}>
      {({ field }: FieldProps<any>) => (
        <Component {...otherSelectProps} {...field} onChange={onChange} />
      )}
    </UDFormFieldWrapper>
  );
};

export default UDFormSelect;
