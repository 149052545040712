import { Player, Team } from "../../../../../stream/domain/utils/convertor.event-to-replacer";
import { Object } from "fabric/fabric-impl";
import { ReplacerAbstract } from "./replacerAbstract";

class ReplaceEvent extends ReplacerAbstract {
  replace(props: IProps) {
    this.replaceText(props)
    this.replaceImage(props)
  }
  
  private replaceText(props: IProps) {
    const { data, startOpacity } = props
    
    const {
      players,
      teams,
      currentPeriod,
      eventMinute,
    } = data ?? {}
    
    const firstPlayerName = players?.first?.name ? `${players?.first?.lastName} ${players?.first?.name}` : ''
    const secondPlayerName = players?.second?.name ? `${players?.second?.lastName} ${players?.second?.name}` : ''
    
    this.replacer.replaceTextByIndicator({
      indicator: 'playerName',
      text: firstPlayerName,
      options: { playerIndex: 0, opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'playerName',
      text: secondPlayerName,
      options: { playerIndex: 1, opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'playerNumber',
      text: players?.first?.number?.toString() ?? '',
      options: { playerIndex: 0, offset: { x: 0, y: 2.5 }, opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'playerNumber',
      text: players?.second?.number?.toString() ?? '',
      options: { playerIndex: 1, offset: { x: 0, y: 2.5 }, opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'teamName',
      text: teams?.team?.name ?? '',
      options: { reverse: false, opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'teamName',
      text: teams?.reversedTeam?.name ?? '',
      options: { reverse: true, opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'score',
      text: teams?.team?.score?.toString() ?? '',
      options: { reverse: false, offset: { x: 0, y: 2.5 }, opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'score',
      text: teams?.reversedTeam?.score?.toString() ?? '',
      options: { reverse: true, offset: { x: 0, y: 2.5 }, opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'fouls',
      text: teams?.team?.fouls?.toString() ?? '',
      options: { reverse: false, offset: { x: 0, y: 2.5 }, opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'fouls',
      text: teams?.reversedTeam?.fouls?.toString() ?? '',
      options: { reverse: true, offset: { x: 0, y: 2.5 }, opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'currentPeriod',
      text: currentPeriod?.toString() ?? '',
      options: { offset: { x: 0, y: 2.5 }, opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'eventMinute',
      text: eventMinute !== undefined ? (eventMinute + "'") : '',
      options: { offset: { x: 0, y: 2.5 }, opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'playerRedCardCount',
      text: players?.first?.qtyRedCards?.toString() ?? '',
      options: { playerIndex: 0, offset: { x: 0, y: 2.5 }, opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'playerRedCardCount',
      text: players?.second?.qtyRedCards?.toString() ?? '',
      options: { playerIndex: 1, offset: { x: 0, y: 2.5 }, opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'playerYellowCardCount',
      text: players?.first?.qtyYellowCards?.toString() ?? '',
      options: { playerIndex: 0, offset: { x: 0, y: 2.5 }, opacity: startOpacity },
    })
    this.replacer.replaceTextByIndicator({
      indicator: 'playerYellowCardCount',
      text: players?.second?.qtyYellowCards?.toString() ?? '',
      options: { playerIndex: 1, offset: { x: 0, y: 2.5 }, opacity: startOpacity },
    })
  }
  
  private replaceImage(props: IProps) {
    const { data, startOpacity, isFadeIn } = props
    const { players, teams } = data ?? {}
    
    const fadeIn = this.fadeIn?.fadeIn.bind(this.fadeIn)
    const smoothAppearance = (object: Object) => {isFadeIn && fadeIn && fadeIn([object])}
    const avatar = 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png';
    
    
    this.replacer.replaceImageByIndicator({
      indicator: 'playerAvatar',
      url: players?.first?.avatar ?? avatar,
      options: { playerIndex: 0, opacity: startOpacity, onLoaded: smoothAppearance },
    })
    this.replacer.replaceImageByIndicator({
      indicator: 'playerAvatar',
      url: players?.second?.avatar ?? avatar,
      options: { playerIndex: 1, opacity: startOpacity, onLoaded: smoothAppearance },
    })
    this.replacer.replaceImageByIndicator({
      indicator: 'teamLogo',
      url: teams?.team?.logo ?? '',
      options: { reverse: false, opacity: startOpacity, onLoaded: smoothAppearance },
    })
    this.replacer.replaceImageByIndicator({
      indicator: 'teamLogo',
      url: teams?.reversedTeam?.logo ?? '',
      options: { reverse: true, opacity: startOpacity, onLoaded: smoothAppearance },
    })
  }
}

export default ReplaceEvent

export interface IData {
  players?: {
    first?: Player
    second?: Player
  }
  teams?: {
    team?: Team
    reversedTeam?: Team
  }
  currentPeriod?: number
  eventMinute?: number
}

interface IProps {
  data?: IData
  startOpacity: number
  isFadeIn?: boolean
}
