import React, { useMemo } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import classNames from 'classnames';
import UDTabs from 'modules/ud-ui/components/tabs';
import { defaultTab, TourneyEditFormPart } from './constants';
import { Championship } from 'modules/tourneys/domain/interfaces/Championship';
import UDText from '../../../../ud-ui/components/text';
import { useDispatch, useSelector } from 'react-redux';
import { Tab } from "../../../../ud-ui/components/tabs/interfaces";
import { Pages } from "../../../../navigation/domain/enums/pages";
import { selectedStageSelector, stagesActions } from "../../../store/stages";
import { AppDispatch } from "../../../../../store/store";
import { showChampionshipStageForms } from "../../../store/championship/actions";

type CreateEditFormParams = {
  tab: Tab;
  count?: number;
  isActive?: boolean;
  subLabel?: string;
  action?: () => void;
};

const createEditFormTab = (params: CreateEditFormParams): Tab => {
  const { tab, count, isActive, subLabel, action } = params;
  
  if (count !== undefined) {
    const badgeBgColor = isActive
      ? 'bgc-SurfaceWhite'
      : 'bgc-AccentNapplesYellow';
    const className = classNames([
      'color-SurfaceRaisinBlack50',
      badgeBgColor,
      'p-1',
      'br-4',
      'ml-2',
      'line-height-1',
      'caption-1',
    ]);
    
    return {
      hash: tab.hash,
      label: (
        <>
          {tab.label}{' '}
          <UDText type='subhead' className={className}>
            {count}
          </UDText>
        </>
      ),
    };
  }
  
  return { ...tab, subLabel, onClick: action };
};

type TourneyEditFormTabsProps = {
  tourney: Championship;
};

const TourneyEditFormTabs = ({ tourney }: TourneyEditFormTabsProps) => {
  const dispatch = useDispatch<AppDispatch>()
  
  const location = useLocation();
  const activeTab = (location.hash || '').split('/')[ 0 ];
  const isTeam = useMatch(`${Pages.TOURNEYS.EDIT}/teams/:teamId`);
  const isStage = useMatch(`${Pages.TOURNEYS.EDIT}/steps`);
  const stage = useSelector(selectedStageSelector)
  
  let subLabel: string | undefined;
  let urlTab: string | undefined;
  let action = () => {};
  
  if (isTeam) {
    urlTab = 'teams';
    subLabel = tourney?.teams?.find((t) => t.id === Number(isTeam.params.teamId))?.name;
  }
  
  if (isStage) {
    urlTab = 'steps';
    subLabel = stage?.name
    action = () => {
      dispatch(stagesActions.stageSelected(null));
      dispatch(showChampionshipStageForms(false));
    };
  }
  
  const tabs = useMemo(() => {
    return Object.values(TourneyEditFormPart).map((tab) =>
      createEditFormTab({
        tab: tab,
        isActive: tab.hash === activeTab,
        subLabel: urlTab === tab.hash ? subLabel : undefined,
        action: urlTab === tab.hash ? action : undefined,
      }),
    );
  }, [activeTab, urlTab, subLabel]);
  
  return <UDTabs tabs={tabs} defaultTab={defaultTab}/>;
};

export default TourneyEditFormTabs;
