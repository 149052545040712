/** @jsxImportSource @emotion/react */
import Clock from "./clock";
import { Header, Reset } from "../typography";
import * as S from "./styles";
import { SmoothText } from "./styles";
import classNames from "classnames";
import UDText from "../../../../ud-ui/components/text";
import { theme } from "../../../../../styles/theme/theme";
import { DivProps } from "../../../../../typings";
import EmptyButton from "../../../../ud-ui/components/emptyButton";

interface IProps extends DivProps {
  milliseconds: number
  setValue: (milliseconds: number) => void
  isTimerStart: boolean
  handlerToggleTimer: () => void
  isReverse: boolean
  handlerToggleReverse: () => void
}

function Timer(props: IProps) {
  const {
    milliseconds,
    setValue,
    isTimerStart,
    handlerToggleTimer,
    isReverse,
    handlerToggleReverse,
    ...rest
  } = props
  
  const handlerReset = () => setValue(0)
  
  return (
    <S.Timer {...rest}>
      <Header id='header'>время</Header>
      <EmptyButton onClick={handlerReset} disabled={isTimerStart}>
        <Reset disabled={isTimerStart} id='reset' className='mt-1'>
          CБРОСИТЬ ВРЕМЯ
        </Reset>
      </EmptyButton>
      
      <Clock
        id='clock'
        disabled={isTimerStart}
        style={{ marginTop: 16 }}
        milliseconds={milliseconds}
        onChange={setValue}
      />
      
      <S.StartButton
        id='start-button'
        isActive={!isTimerStart}
        style={{ marginTop: 18, position: "relative" }}
        onClick={handlerToggleTimer}
      >
        <div
          style={{ position: 'absolute' }}
          css={SmoothText}
          className={classNames({ 'hide': isTimerStart })}
        >
          старт
        </div>
        <div
          style={{ position: 'absolute' }}
          css={SmoothText}
          className={classNames({ 'hide': !isTimerStart })}
        >
          стоп
        </div>
      </S.StartButton>
      <S.ReverseButton
        className='reverse-button'
        id='reverse-button'
        style={{ marginTop: 24 }}
        onClick={handlerToggleReverse}
        isActive={isReverse}
      >
        <UDText className='fw-700' type='subhead' color={theme.colors.DarkBlue}>
          РЕВЕРСИВНЫЙ ТАЙМЕР
        </UDText>
      </S.ReverseButton>
    </S.Timer>
  );
}

export default Timer;
