import { createAsyncThunk } from "@reduxjs/toolkit";
import supabase from "../../../core/supabase/supabase";
import { GetTmBoard, UpsertTmBoard } from "../../../core/domain/interfaces/supabase/tm-boards/actions";
import { TMBoard } from "../../../core/domain/interfaces/supabase/tm-boards/entity";

const PREFIX = 'tmBoard';

export const upsertTmBoard = createAsyncThunk<boolean, UpsertTmBoard>(
  `${PREFIX}/saveData`,
  async (payload, { rejectWithValue }) => {
    try {
      return supabase.tmBoards.upsertBoard(payload);
    } catch (error) {
      console.error(`${PREFIX}/saveData error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const fetchTmBoard = createAsyncThunk<TMBoard, GetTmBoard>(
  `${PREFIX}/fetchTmBoard`,
  async (payload, { rejectWithValue }) => {
    try {
      return supabase.tmBoards.getOrCreateBoard(payload);
    } catch (error) {
      console.error(`${PREFIX}/fetchTmBoard error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);
