import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function MonitorWithStandIcon(props: IProps) {
  const {
    width = 26,
    height = 24,
    ...rest
  } = props;
  
  return (
    <svg width={width} height={height} viewBox='0 0 26 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        d='M19 23L13 18.1111L7 23M6.76 18.1111H19.24C21.2562 18.1111 22.2643 18.1111 23.0344 17.7115C23.7118 17.3599 24.2625 16.799 24.6076 16.1091C25 15.3247 25 14.298 25 12.2444V6.86667C25 4.81314 25 3.78638 24.6076 3.00203C24.2625 2.31211 23.7118 1.75118 23.0344 1.39964C22.2643 1 21.2562 1 19.24 1H6.76C4.74381 1 3.73572 1 2.96563 1.39964C2.28825 1.75118 1.73752 2.31211 1.39238 3.00203C1 3.78638 1 4.81314 1 6.86667V12.2444C1 14.298 1 15.3247 1.39238 16.1091C1.73752 16.799 2.28825 17.3599 2.96563 17.7115C3.73572 18.1111 4.74381 18.1111 6.76 18.1111Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
