function checkInitTime(time: number, startTime: number | null, isReverse: boolean) {
  const modifier = isReverse ? -1 : 1
  const additionalTime = startTime ? (Date.now() - startTime) * modifier : 0
  const initTime = time + additionalTime
  
  if (startTime && initTime) {
    localStorage.setItem('time', initTime.toString())
  }
  
  localStorage.removeItem('startTime')
  return initTime
}

export default checkInitTime
