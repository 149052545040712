import { Replacer } from "../addons/replacer/replacerMain";
import { useEffect, useMemo } from "react";
import Static from "../canvases/static";
import Interactive from "../canvases/interactive";

export default function useReplacer(manager: Static | undefined) {
  const replacer = useMemo(() => new Replacer(), [])
  useEffect(() => {
    if (!manager) return
    replacer.init(manager)
  }, [manager, replacer])
  return replacer
}
