import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import * as syncAction from "../../../store/tmBoard/tmBoard";
import { setIsReverse, setIsTimerStart } from "../../../store/tmBoard/tmBoard";
import useTmBoard from "../../hooks/useTmBoard";
import useTimer from "../../../../../shared/hooks/useTimer";
import { useEffect, useRef, useState } from "react";
import Desktop from "./desktop/desktop";
import size from "../../../domain/constants/size";
import Tablet from "./tablet/tablet";
import Mobile from "./mobile/mobile";
import { useParams } from "react-router-dom";
import { fetchTmBoard, upsertTmBoard } from "../../../store/tmBoard/actions";

function PanelPage() {
  const dispatch = useDispatch<AppDispatch>()
  const {
    timer,
    teams,
    period,
    status,
    teamNames,
    score,
  } = useTmBoard()
  
  const id = useParams()?.id
  const [popup, setPopup] = useState<Window | null>()
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  
  useEffect(() => {
    if (!id) { return; }
    dispatch(fetchTmBoard({ id }))
  }, [dispatch, id]);
  
  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const handleChangeTime = (milliseconds: number) => {
    dispatch(syncAction.setTime({ milliseconds }))
  }
  
  const { timer: time, setTimer } = useTimer({ ...timer, setTime: handleChangeTime })
  
  const handleChangeTimer = (milliseconds: number) => {
    setTimer(milliseconds)
  }
  
  const handleChangeTeamName = (name: string, index: 0 | 1) => {
    dispatch(syncAction.setTeamName({ index, name }))
  }
  
  const handleChangePeriod = (period: number) => {
    dispatch(syncAction.setPeriod({ period }))
  }
  
  const handleToggleTimer = () => {
    dispatch(syncAction.toggleTimer())
  }
  
  const handleToggleReverse = () => {
    dispatch(syncAction.toggleReverse())
  }
  
  const handleChangeScore = (index: 0 | 1, score: number) => {
    dispatch(syncAction.setScore({ index, score }))
  }
  
  const handleOpenBoard = () => {
    // toolbar=false,location=false,directories=false,status=false,menubar=false,scrollbars=no,resizable=false,
    const windowProps = `width=1200,height=800,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no`;
    
    const url = `${window.location.origin}/master-board/board/`
    const popup = window.open(url, "master-board Offline", windowProps);
    setPopup(popup)
  }
  
  useEffect(() => {
    popup?.postMessage(JSON.stringify({ type: 'syncState', time, teams, period }), '*');
  }, [period, popup, teams, time]);
  
  useEffect(() => {
    if (!id || !status.isLoaded) return;
    
    dispatch(upsertTmBoard({
      id,
      time: timer.time,
      isReverse: timer.isReverse,
      startTime: timer.startTime,
      score: score as [number, number],
      period,
    }))
  }, [dispatch, id, period, score, status.isLoaded, timer.isReverse, timer.startTime, timer.time])
  
  useEffect(() => {
    if (!id || !status.isLoaded) return;
    
    // Очищаем предыдущий таймаут
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    
    debounceTimeout.current = setTimeout(() => {
      dispatch(
        upsertTmBoard({
          id,
          teams: teamNames.map((teamName) => ({ name: teamName })) as [
            { name: string },
            { name: string }
          ],
        }),
      );
    }, 1000); // Ждем 1 секунду
  }, [id, status.isLoaded, teamNames, dispatch]);
  
  useEffect(() => {
    if (!timer.isReverse) return;
    if (time <= 999) {
      dispatch(setIsTimerStart({ isTimerStart: false }));
      dispatch(setIsReverse({ isReverse: false }));
    }
  }, [dispatch, time, timer.isReverse]);
  
  const orientation = window.screen.orientation.type.includes('portrait') ? 'portrait' : 'landscape'
  const isMobileInPortrait = orientation === 'portrait' && window.innerWidth < size.mobile.width
  const isMobileInLandscape = orientation === 'landscape' && window.innerHeight < size.mobile.height && window.innerWidth < size.tablet
  
  
  switch (true) {
    case isMobileInPortrait || isMobileInLandscape:
      return (
        <Mobile
          time={time}
          timer={timer}
          teams={teams}
          period={period}
          handleOpenBoard={handleOpenBoard}
          handleChangeScore={handleChangeScore}
          handleChangeTimer={handleChangeTimer}
          handleToggleTimer={handleToggleTimer}
          handleChangePeriod={handleChangePeriod}
          handleToggleReverse={handleToggleReverse}
          handleChangeTeamName={handleChangeTeamName}
        />
      )
    case innerWidth <= size.tablet:
      return (
        <Tablet
          time={time}
          timer={timer}
          teams={teams}
          period={period}
          handleOpenBoard={handleOpenBoard}
          handleChangeScore={handleChangeScore}
          handleChangeTimer={handleChangeTimer}
          handleToggleTimer={handleToggleTimer}
          handleChangePeriod={handleChangePeriod}
          handleToggleReverse={handleToggleReverse}
          handleChangeTeamName={handleChangeTeamName}
        />
      )
    default:
      return (
        <Desktop
          time={time}
          timer={timer}
          teams={teams}
          period={period}
          handleOpenBoard={handleOpenBoard}
          handleChangeScore={handleChangeScore}
          handleChangeTimer={handleChangeTimer}
          handleToggleTimer={handleToggleTimer}
          handleChangePeriod={handleChangePeriod}
          handleToggleReverse={handleToggleReverse}
          handleChangeTeamName={handleChangeTeamName}
        />
      )
  }
}

export default PanelPage;
