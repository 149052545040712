import useGameInfo from "../../hooks/useGameInfo";
import { useMemo } from "react";
import { DivProps } from "../../../../../typings";
import UDText from "../../../../ud-ui/components/text";
import { PanelItemWrapper } from "./styles";
import Score from "./score";
import Fouls from "./fouls";
import PlayersList from "./playersList";

interface IProps extends DivProps {
  teamIndex: number
}

function TeamPanel(props: IProps) {
  const { teamIndex, ...rest } = props
  
  const { teams } = useGameInfo()
  const team = useMemo(() => {
    if (teamIndex === 0) return teams?.first
    if (teamIndex === 1) return teams?.second
  }, [teamIndex, teams])
  
  return (
    <PanelItemWrapper {...rest}>
      <div className={'flex-center w-100'}>
        <UDText type={"headline"} color={"#686A79"}>
          {team?.name ?? 'Неизвестная команда'}
        </UDText>
      </div>
      
      <div className={'flex-row gap-8 flex-wrap'}>
        <div className={'flex-column flex-grow-1'}>
          <Score className={'mt-2'} teamIndex={teamIndex}/>
          <Fouls className={'mt-2'} teamIndex={teamIndex}/>
        </div>
        <div>
          <PlayersList teamIndex={teamIndex}/>
        </div>
      </div>
    </PanelItemWrapper>
  );
}

export default TeamPanel;
