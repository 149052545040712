import { useEffect } from "react";
import { setActiveEventId, shiftEvent } from "../../../../../stream/store/overlay/overlay";
import { Scheme } from "../../types";
import { time } from "../../../../../stream/domain/constants/time";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../store/store";
import Static from "../../canvases/static";
import useReplacer from "../useReplacer";
import useDataForCommon from "../../../../../stream/ui/hooks/useDataForCommon";
import convertorEventToReplacer from "../../../../../stream/domain/utils/convertor.event-to-replacer";
import useGameInfo from "../../../../../stream/ui/hooks/useGameInfo";
import { selectActiveEventId, selectEvents } from "../../../../../stream/store/overlay/selectors";
import { selectActiveTemplate } from "../../../../../template-editor/store/templates/selectors";
import useFade from "../useFade";

interface IProps {
  manager: Static
}


function useLoadEvent(props: IProps) {
  const { manager } = props
  const dispatch = useDispatch<AppDispatch>()
  const replacer = useReplacer(manager)
  const events = useSelector(selectEvents)
  const boards = useSelector(selectActiveTemplate)?.boards
  const activeEvent = useSelector(selectActiveEventId)
  const fadeIn = useFade(manager)
  const gameInfo = useGameInfo()
  const commonData = useDataForCommon()
  
  useEffect(() => {
    if (!manager) return
    const event = events[ 0 ]
    if (!event) return
    if (activeEvent) return
    if (!boards) return
    const boardsEvent = boards.filter(board => board.type === 'events' && board.events.includes(event.type))
    
    if (!boardsEvent.length) {
      dispatch(shiftEvent())
      return;
    }
    
    let count = 0
    
    const gameData = { teams: gameInfo.teams, fouls: gameInfo.fouls, goals: gameInfo.goals };
    const eventData = convertorEventToReplacer(event, gameData);
    
    const loadEvents = () => {
      const board = boardsEvent[ count ]
      if (!board) return
      
      const promise = new Promise((resolve) => {
        dispatch(setActiveEventId(event.id));
        const scheme: Scheme = { objects: board?.objects, version: board?.version }
        
        const objects = manager.canvas.getObjects()
        fadeIn.fadeOut(objects).then(() => {
          manager.loadLocal({ scheme }).then(() => {
            const loadedObject = manager.canvas.getObjects()
            for (const obj of loadedObject) {
              obj.set({ opacity: 0 })
            }
            
            replacer.common.replace({ startOpacity: 0, data: commonData, isFadeIn: true })
            replacer.event.replace({ startOpacity: 0, data: eventData, isFadeIn: true })
            
            const allObject = manager.canvas.getObjects().filter(object => object.opacity === 0)
            
            fadeIn.fadeIn(allObject).then(() => {
              setTimeout(() => {
                const allObject = manager.canvas.getObjects()
                fadeIn.fadeOut(allObject).then(() => {
                  manager.clear()
                  dispatch(setActiveEventId(null));
                  resolve(true)
                })
              }, board.duration * time.second)
            })
          })
        })
      })
      
      promise.then(() => {
        count++
        if (count <= boardsEvent.length - 1) {
          loadEvents()
        } else {
          dispatch(shiftEvent())
        }
      })
    }
    
    loadEvents()
    
    // eslint-disable-next-line
  }, [manager, events, dispatch, boards])
}

export default useLoadEvent
