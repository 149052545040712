import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function WifiIcon(props: IProps) {
  const {
    width = 33,
    height = 24,
    ...rest
  } = props;
  
  return (
    <svg width={width} height={height} viewBox='0 0 33 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5009 5.52285C21.2889 5.52306 25.8939 7.40098 29.3639 10.7685C29.6252 11.0285 30.0429 11.0252 30.3003 10.7611L32.7981 8.18793C32.9284 8.054 33.0011 7.87259 33 7.68384C32.9989 7.49508 32.9242 7.31455 32.7923 7.18218C23.6845 -1.72739 9.31586 -1.72739 0.208022 7.18218C0.076089 7.31445 0.00123164 7.49493 1.50622e-05 7.68368C-0.00120151 7.87244 0.071323 8.05391 0.20154 8.18793L2.7001 10.7611C2.9573 11.0256 3.37529 11.0289 3.63643 10.7685C7.10695 7.40076 11.7124 5.52283 16.5009 5.52285ZM16.5704 13.2911C19.2011 13.2909 21.7379 14.289 23.688 16.0914C23.9517 16.3472 24.3672 16.3417 24.6243 16.0789L27.1193 13.5058C27.2506 13.3708 27.3235 13.1877 27.3216 12.9974C27.3198 12.8072 27.2432 12.6256 27.1092 12.4934C21.171 6.85507 11.9749 6.85507 6.03668 12.4934C5.90254 12.6256 5.82601 12.8072 5.82425 12.9976C5.82249 13.1879 5.89565 13.371 6.02731 13.5058L8.52155 16.0789C8.77866 16.3417 9.19414 16.3472 9.45789 16.0914C11.4066 14.2902 13.9414 13.2922 16.5704 13.2911ZM21.6427 18.3135C21.6465 18.5043 21.573 18.6883 21.4396 18.8219L17.1238 23.2677C16.9973 23.3983 16.8248 23.4719 16.6448 23.4719C16.4649 23.4719 16.2924 23.3983 16.1659 23.2677L11.8494 18.8219C11.716 18.6882 11.6427 18.5042 11.6466 18.3134C11.6506 18.1226 11.7315 17.9419 11.8703 17.814C14.6265 15.4344 18.6632 15.4344 21.4194 17.814C21.5581 17.942 21.6389 18.1227 21.6427 18.3135Z'
        fill='currentColor'
      />
    </svg>
  );
}
