import { Replacer } from "./replacerMain";
import FadeIn from "../fadeIn";

export abstract class ReplacerAbstract {
  protected replacer: Replacer;
  protected fadeIn: FadeIn | undefined;
  
  constructor(replacer: Replacer, fadeIn?: FadeIn) {
    this.replacer = replacer
    this.fadeIn = fadeIn
  }
  
  abstract replace(props: { data?: any, startOpacity?: number, [ key: string ]: any }): void
}
