import { Player, Team } from "../../domain/interfaces/player";
import TeamLogo from "../../../ud-ui/components/team-logo";
import { TeamCardHeaderRow } from "../../../teams/ui/components/team-card/styles";
import UDText from "../../../ud-ui/components/text";
import UDButton from "../../../ud-ui/components/button";
import React, { useCallback } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Pages } from "../../../navigation/domain/enums/pages";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { kickTournamentUserFromTeam } from "../../store/actions";
import { toast } from "react-toastify";
import { useModal } from "../../../ud-ui/components/modal/useModal";

interface IProps {
  index: number
  team: Team
  player: Player
}

function TeamCard(props: IProps) {
  const { team, index, player } = props
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { open, close, Modal } = useModal({ defaultShow: false });
  
  const handleClickTeam = useCallback(() => {
    navigate(Pages.TEAMS.builders.edit(team.id), { state: { fromPlayer: player.id } })
  }, [navigate, player.id, team.id]);
  
  const handleKickFromTeam = useCallback(() => {
    dispatch(kickTournamentUserFromTeam({ userId: player.id, teamId: team.id })).then(() => {
      toast.success('Игрок исключен из команды')
    })
  }, [dispatch, player.id, team.id]);
  
  return (
    <>
      <TeamCardHeaderRow>
        <div
          className='team-card'
        >
          <div
            className='row'
            onClick={() => handleClickTeam}
          >
            {index !== undefined ? (
              <UDText type='subhead' className='team-column bold col-1'>
                {index + 1}
              </UDText>
            ) : (
              ''
            )}
            
            <div className='team-column col-4'>
              <TeamLogo
                className='team-logo mr-2'
                url={team.emblem?.externalUrl}
                size={52}
              />
              <UDText type='subhead' className='bold'>
                {team.name}
              </UDText>
            </div>
            
            {team.createdAt ? (
              <UDText type='subhead' className='team-column col-2'>
                {format(new Date(team.createdAt), 'dd.MM.yyyy')}
              </UDText>
            ) : (
              ''
            )}
            
            <div className='team-column col-5 d-flex justify-content-end'>
              <UDButton
                className='d-flex align-items-center mr-3'
                variant='primary'
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickTeam();
                }}
              >
                <span>Редактировать</span>
              </UDButton>
              
              <UDButton
                className='d-flex align-items-center mr-3'
                variant='secondary'
                onClick={(e) => {
                  e.stopPropagation();
                  open();
                }}
              >
                <span>Исключить</span>
              </UDButton>
            </div>
          </div>
        </div>
        
        <Modal>
          <UDText
            type='headline'
            className='color-SurfaceRomanSilver30 px-10'
            style={{ textAlign: 'center' }}
          >
            Вы действительно хотите исключить игрока
          </UDText>
          <UDText
            type='headline'
            className='color-SurfaceRomanSilver30 px-10'
            style={{ textAlign: 'center' }}
          >
            {player.name} {player.surname} {player.middleName ?? ''}
          </UDText>
          <UDText
            type='headline'
            className='color-SurfaceRomanSilver30 px-10 mb-15'
            style={{ textAlign: 'center' }}
          >
            из {team.name}?
          </UDText>
          <div className={'d-flex w-100'} style={{ gap: 10 }}>
            <UDButton
              className='d-flex align-items-center justify-content-center mb-3 w-50'
              variant='primary'
              onClick={(e) => {
                e.stopPropagation();
                close();
              }}
            >
              <span>Нет</span>
            </UDButton>
            <UDButton
              className='d-flex align-items-center justify-content-center mb-3 w-50'
              variant='primary'
              onClick={(e) => {
                e.stopPropagation();
                handleKickFromTeam();
              }}
            >
              <span>Да</span>
            </UDButton>
          </div>
        </Modal>
      </TeamCardHeaderRow>
    </>
  );
}

export default TeamCard;
