import { RootState } from "../../../../store/store";
import { createSelector } from "@reduxjs/toolkit";
import { Team, Time } from "../../domain/intefaces";

const state = (state: RootState) => state.masterBoard.tmBoard

export const selectTime = createSelector(
  [state],
  (state) => state.time,
);

export const selectTeams = createSelector(
  [state],
  (state) => [
    { ...state.teams[ 0 ], score: state.score[ 0 ] },
    { ...state.teams[ 1 ], score: state.score[ 1 ] },
  ] as [Team, Team],
);

export const selectPeriod = createSelector(
  [state],
  (state) => state.period,
);

export const selectTimer = createSelector(
  [state],
  (state) => ({
    time: state.time,
    isActive: state.isTimerStart,
    isReverse: state.isReverse,
    startTime: state.startTime,
  } as Time),
);

export const selectStatus = createSelector(
  [state],
  (state) => ({
    isLoading: state.isLoading,
    isLoaded: state.isLoaded,
  }),
);

export const selectTeamName = createSelector(
  [state],
  (state) => state.teams.map((team) => team.name) as [string, string],
);

export const selectScore = createSelector(
  [state],
  (state) => state.score,
);
