import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function ArrowUpIcon(props: IProps) {
  const {
    width = 16,
    height = 36,
    color = '#D9B244',
    ...rest
  } = props;
  
  return (
    <svg width={width} height={height} viewBox='0 0 16 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        d='M8.70711 0.292839C8.31658 -0.097683 7.68342 -0.097683 7.29289 0.292839L0.928932 6.6568C0.538408 7.04733 0.538408 7.68049 0.928932 8.07101C1.31946 8.46154 1.95262 8.46154 2.34315 8.07101L8 2.41416L13.6569 8.07101C14.0474 8.46154 14.6805 8.46154 15.0711 8.07101C15.4616 7.68049 15.4616 7.04733 15.0711 6.6568L8.70711 0.292839ZM9 35.0146L9 0.999947H7L7 35.0146H9Z'
        fill={color}
      />
    </svg>
  );
}
