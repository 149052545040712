import { useEffect } from "react";
import { setActiveBoard } from "../../../template-editor/store/templates/templates";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { setFouls, setGoals } from "../../store/gameInfo/gameInfo";
import { setPeriod, setState, setTimer, toggleTimer } from "../../store/time/time";
import { setPopupIsLoaded } from "../../store/overlay/overlay";

export interface PopupMessage {
  type: string
  
  [ key: string ]: any
}

export function useParsePopupMessage() {
  const dispatch = useDispatch<AppDispatch>()
  
  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    
    function receiveMessage(event: MessageEvent) {
      const domain = window.location.origin
      if (!event.origin.includes(domain)) return;
      
      try {
        const message: PopupMessage = JSON.parse(event.data)
        switch (message.type) {
          
          case 'changeBoard': {
            dispatch(setActiveBoard({ id: message.boardId }))
            break;
          }
          
          case 'setGoals': {
            dispatch(setGoals({ first: message?.first, second: message?.second }))
            break;
          }
          
          case 'setFouls': {
            dispatch(setFouls({ first: message?.first, second: message?.second }))
            break;
          }
          
          case 'setPeriod': {
            dispatch(setTimer({ milliseconds: 0 }))
            dispatch(setPeriod(message.period))
            break;
          }
          
          case 'toggleTimer': {
            dispatch(toggleTimer())
            break;
          }
          
          case 'setTimer': {
            dispatch(setTimer({ milliseconds: message.milliseconds }))
            break;
          }
          
          case 'syncState': {
            dispatch(setGoals(message.gameInfo.goals))
            dispatch(setFouls(message.gameInfo.fouls))
            dispatch(setState({ ...message.time, passedTime: message.timer }))
            console.log({ message })
            break;
          }
          
          case 'popupLoaded': {
            dispatch(setPopupIsLoaded(true))
          }
        }
      } catch (e) {
        console.error(e, { data: event.data })
      }
    }
  }, [dispatch])
}
