import PopupControl from "../components/FullSizeControlPanel/popupControl";
import { useEffect, useState } from "react";
import getAvailableFeature from "../../domain/repositories/availableFeature";
import useInitOverlay from "../hooks/initializers/useInitOverlay";
import useGameInfo from "../hooks/useGameInfo";
import { checkIsDev } from "../../../utils/checkIsDev";
import UDText from "../../../ud-ui/components/text";
import { FullSizeControlPanelWrapper, TimeAndBoards } from "./styles";
import Dashboard from "../components/FullSizeControlPanel/dashboard";
import TeamPanel from "../components/FullSizeControlPanel/team-panel";
import Period from "../components/FullSizeControlPanel/period";
import { useQueryParam } from "use-query-params";
import SyncStateButton from "../components/FullSizeControlPanel/syncStateButton";
import ConnectionIndicator from "../components/FullSizeControlPanel/connectionIndicator";

function FullSizeControlPanel() {
  useInitOverlay()
  const { organizer } = useGameInfo()
  
  const [featureIsAvailable, setFeatureIsAvailable] = useState<boolean>()
  const boardId = Number(useQueryParam('boardId')[ 0 ])
  
  useEffect(() => {
    const isDev = checkIsDev()
    if (isDev) {
      return setFeatureIsAvailable(true)
    }
    
    if (!organizer?.id) return
    getAvailableFeature(organizer?.id).then(features => {
      setFeatureIsAvailable(features.OBS_INTEGRATION)
    })
  }, [organizer?.id]);
  
  if (featureIsAvailable === false) {
    return (
      <UDText className={'flex-center w-100 h-100'} type={'headline'}>Нет доступа. Купите подписку</UDText>
    )
  }
  
  return (
    <FullSizeControlPanelWrapper>
      <div className={'flex-row gap-4'}>
        {!boardId && (<PopupControl/>)}
        <SyncStateButton/>
        <ConnectionIndicator/>
      </div>
      <TimeAndBoards className={'mt-4 gap-8'}>
        <Period className={'flex-wrap'} style={{ maxWidth: 270 }}/>
        <Dashboard boardId={isNaN(boardId) ? undefined : boardId} className={'flex-wrap'}/>
      </TimeAndBoards>
      <div className='flex-wrap mt-4 gap-8'>
        <TeamPanel className='flex-grow-1' teamIndex={0}/>
        <TeamPanel className='flex-grow-1' teamIndex={1}/>
      </div>
    </FullSizeControlPanelWrapper>
  );
}

export default FullSizeControlPanel;
