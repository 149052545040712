import { css } from "@emotion/react";
import styled from "@emotion/styled";
import size from "../../domain/constants/size";

export const TextGradient = (color1: string, color2: string, direction: string = 'left') => css`
  background: -webkit-linear-gradient(${direction}, ${color1}, ${color2});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  background: linear-gradient(127.46deg, #EE00FF 13.32%, #009DFF 102.09%);
  background-clip: text;
`

export const Button = styled.button`
  transition: background-color 0.2s ease-in-out, transform 50ms ease-in-out;

  &.round {
    border-radius: 8px;
  }

  &.circle {
    border-radius: 200px;
  }

  &:active {
    transform: translateY(1px);
  }

  &.light {
    color: ${({ theme }) => theme.colors.DarkBlue};
    background-color: white;

    &:hover {
      background-color: lightyellow;
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.AccentNapplesYellow};
    }
  }

  &.dark-yellow {
    color: ${({ theme }) => theme.colors.DarkBlue};
    background-color: ${({ theme }) => theme.colors.DarkYellow};

    &:hover {
      background-color: #e7c54c;
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.AccentNapplesYellow};
    }
  }

  &.blue {
    color: white;
    background-color: #1160FF;

    &:hover {
      background-color: #246bfa;
    }

    &:active {
      background-color: #064ad2;
    }
  }

  width: 360px;
  height: 60px;

  border: unset;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const OptionsWrapper = styled.div`
  position: absolute;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  padding: 11px 0;
  
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  
  @media (max-width: ${size.mobile.width}px) {
    padding: clamp(2px, 0.5vw, 11px) 0;
    border-radius: 4px;
  }
  
  @media screen and (orientation: landscape) and (max-height: ${size.mobile.height}px) and (max-width: ${size.tablet}px) {
    padding: clamp(2px, 0.5vw, 11px) 0;
    border-radius: 4px;
  }
`

export const Option = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  border: unset;
  
  width: 100%;
  height: 60px;
  
  gap: 50px;
  padding: 0 20px;
  
  background-color: white;
  stroke: white;
  transition: background-color 0.2s ease-in-out, stroke 0.2s ease-in-out;
  cursor: pointer;
  
  &:hover {
    background-color: #ffffd4;
    stroke: #ffffd4;
  }
  
  &:active {
    background-color: ${({ theme }) => theme.colors.AccentNapplesYellow};
    stroke: ${({ theme }) => theme.colors.AccentNapplesYellow};
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    
    &:hover {
      background-color: white;
      stroke: white;
    }
    
    &:active {
      background-color: white;
      stroke: white;
    }
  }
  
  @media (max-width: ${size.mobile.width}px) {
    width: 100%;
    height: clamp(40px, 6vw, 60px);
    gap: clamp(76px, 8vw, 50px);
    
    & > .text {
      white-space: nowrap;
      font-weight: 400;
      font-size: clamp(15px, 3vw, 20px);
    }
    
    & > .option-icon {
      width: clamp(21px, 4vw, 33px);
      min-width: clamp(21px, 4vw, 33px);
      height: unset;
    }
  }
  
  @media screen and (orientation: landscape) and (max-height: ${size.mobile.height}px) and (max-width: ${size.tablet}px) {
    width: 100%;
    height: clamp(40px, 2vw, 60px);
    gap: clamp(46px, 1vw, 50px);
    
    & > .text {
      white-space: nowrap;
      font-weight: 400 !important;
      font-size: clamp(15px, 1vw, 20px);
    }
    
    & > .option-icon {
      width: clamp(21px, 2vw, 33px);
      min-width: clamp(21px, 2vw, 33px);
      height: unset;
    }
  }
`

export const WaterMarkCss = css`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  
  transform: translate(15%, 35%);
  color: #F7F7F7;
  background: unset;
  -webkit-text-fill-color: unset;
`

export const SmoothChangeColorCss = css`
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out, stroke 0.2s ease-in-out;
`
