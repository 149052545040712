import { ClockInput } from './styles'
import { InputHTMLAttributes, useEffect, useState, useRef } from "react";
import clockToMs from "../../../../utils/clockToMs";
import msToClock from "../../../../utils/msToClock";
import { toast } from "react-toastify";

interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  milliseconds: number,
  onChange?: (value: number) => void
}

const regex = /^[0-9]{2}:[0-5][0-9]$/

function Clock(props: IProps) {
  const { milliseconds, onChange, ...rest } = props
  const [formatedTime, setFormatedTime] = useState(msToClock(milliseconds, "floor"))
  const [editingState, setEditingState] = useState(formatedTime)
  const [isFocused, setIsFocused] = useState(false)
  
  const inputRef = useRef<HTMLInputElement>(null); // реф для поля ввода
  
  useEffect(() => {
    setFormatedTime(msToClock(milliseconds, "floor"))
  }, [milliseconds]);
  
  useEffect(() => {
    setEditingState(formatedTime)
    // eslint-disable-next-line
  }, [isFocused]);
  
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/[^0-9]/g, ''); // удаляем все, что не цифры
    if (value.length >= 4) {
      // автоматически вставляем двоеточие после двух символов
      value = value.slice(0, 2) + ':' + value.slice(2, 4);
    }
    setEditingState(value);
  }
  
  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!regex.test(value)) {
      setFormatedTime(msToClock(milliseconds, "floor"));
      toast.warn('Неверный формат времени');
    } else {
      setFormatedTime(value);
      props.onChange?.(clockToMs(value));
    }
    setIsFocused(false);
  }
  
  const handleOnFocus = () => {
    setIsFocused(true);
  }
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { selectionStart } = inputRef.current!;
    handleOnChange(e);
    // Восстанавливаем позицию курсора после обновления состояния
    if (selectionStart !== null) {
      requestAnimationFrame(() => {
        if (inputRef.current) {
          inputRef.current.selectionStart = selectionStart;
          inputRef.current.selectionEnd = selectionStart;
        }
      });
    }
  }
  
  return (
    <ClockInput
      {...rest}
      ref={inputRef} // присваиваем реф input элементу
      type='text'
      value={isFocused ? editingState : formatedTime}
      onChange={handleInputChange} // используем handleInputChange для корректировки позиции курсора
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      style={{
        width: '312px',
        height: '100px',
        fontSize: 100,
        lineHeight: '100px',
        padding: 0,
        ...rest.style,
      }}
    />
  );
}

export default Clock;
