import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function CrossThinIcon(props: IProps) {
  const {
    width = 22,
    height = 22,
    ...rest
  } = props;
  
  return (
    <svg width={width} height={height} viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        d='M21 1L1 21M1 1L21 21'
        stroke='currentColor'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
