import styled from "@emotion/styled";

export const MainBlock = styled.button<{ color: string, hoverColor?: string, disabledColor?: string, centered?: false }>`
  border: none;
  background: none;
  cursor: pointer;
  
  font-family: inherit;
  font-size: 15px;
  font-weight: inherit;
  line-height: inherit;
  text-align: inherit;
  
  display: flex;
  flex-direction: row;
  gap: 10px;
  
  color: ${({ color }) => color};
  transition: color 0.2s ease-in-out;
  
  &:hover {
    color: ${({ hoverColor, theme }) => hoverColor ?? theme.colors.AccentNapplesYellow};
  }
  
  &:disabled {
    color: ${({ disabledColor }) => disabledColor ?? '#D0D0D0'};
    cursor: not-allowed;
  }
  
  ${(props) => props.centered !== false && `
    justify-content: center;
    align-items: center;
  `}
`
