import React from "react";
import Counter from "./counter";
import { Header } from "../typography";
import { DivProps } from "../../../../../typings";
import classNames from "classnames";
import getFontSize from "../../../domain/helpers/getFontSize";

interface Props extends DivProps {
  value: number
  setValue: (value: number) => void
  disabled?: boolean
}

function Period(props: Props) {
  const {
    value,
    setValue,
    disabled,
    ...rest
  } = props
  
  const fontSize = getFontSize({
    mobile: 60,
    tablet: 80,
    desktop: 100,
  })
  
  return (
    <div {...rest} className={classNames('flex-center flex-column gap-4 period', rest.className)}>
      <Header id='header'>тайм</Header>
      <Counter
        disabled={disabled}
        value={value}
        setValue={setValue}
        fontSize={fontSize}
      />
    </div>
  );
}

export default Period;
