/** @jsxImportSource @emotion/react */
import { LabelCss, MainBlock } from "./styles";
import Header from "../../../components/board/header";
import Score from "../../../components/board/score";
import Advertisement from "../../../components/board/advertisement";
import { Team } from "../../../../domain/intefaces";
import Period from "../../../components/board/period";
import Timer from "../../../components/board/timer";
import TMLabel from "../../../components/TMLabel";

interface IProps {
  teams: [Team, Team],
  period: number,
  time: number,
  roundTo: 'floor' | 'ceil'
}

function Tablet(props: IProps) {
  const {
    teams,
    period,
    time,
    roundTo,
  } = props
  
  return (
    <MainBlock>
      <Header value='ПРИВЕТСТВУЕМ УЧАСТНИКОВ ИГРЫ'/>
      <div id='body' className='flex-center flex-column'>
        <div id='upper' className='flex-row'>
          <Score id={'score'} score={teams[ 0 ].score} teamName={teams[ 0 ].name}/>
          <Period id='period' value={period}/>
          <Score id={'score'} score={teams[ 1 ].score} teamName={teams[ 1 ].name}/>
        </div>
        <div id='center' className='flex-column flex-center'>
          <Timer id='timer' milliseconds={time} roundTo={roundTo}/>
          <TMLabel
            id='label'
            css={LabelCss}
            fontSize={62}
          />
        </div>
      </div>
      <Advertisement/>
    </MainBlock>
  );
}

export default Tablet;
