import * as S from "./styles";
import UDText from "../../../../ud-ui/components/text";
import { theme } from "../../../../../styles/theme/theme";
import { DivProps } from "../../../../../typings";

interface Props extends DivProps {
  number: 0 | 1
}

export function TeamIndex(props: Props) {
  const {
    number,
    ...rest
  } = props;
  
  return (
    <S.TeamIndex {...rest}>
      <UDText type={'callout'} color={theme.colors.WhiteGray}>Команда №{props.number + 1}</UDText>
    </S.TeamIndex>
  );
}
