import styled from "@emotion/styled";
import EmptyButton from "../../../../ud-ui/components/emptyButton";

export const PanelItemWrapper = styled.div`
  padding: 14px 8px;
  border-radius: 8px;
  background: #0a0a0b;
`

export const Board = styled.div<{ active: boolean }>`
  padding: 4px 6px;
  border-radius: 8px;
  background: ${({ active }) => active ? '#D9B244' : '#686A79'};
  transition: background 0.2s ease-in-out;
  
  &:hover {
    background: ${({ active }) => active ? '#D9B244' : '#d7c175'};
  }
`

export const Boards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`

export const Dashboard = styled(PanelItemWrapper)`
  display: flex;
  flex-direction: column;
  
  min-width: 200px;
`

export const CountButton = styled(EmptyButton)<{ size?: number }>`
  background: white;
  border: 1px solid #686A79;
  border-radius: 4px;
  height: ${({ size }) => size ?? 20}px;
  width: ${({ size }) => size ?? 20}px;
  
  transition: border 0.2s ease-in-out;
  
  &:hover {
    border: 1px solid #D9B244;
  }
`

export const PlayerController = styled.div`
  cursor: pointer;
  height: 100%;
  width: 70px;
  background: #D9B244;
  border-radius: 8px;
  transition: background 0.2s ease-in-out;
  
  &:hover {
    background: #e5b71d;
  }
  
  &:active {
    transition: background 0.05s ease-in-out;
    background: #f2ca18;
  }
`

export const NumberWrapper = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 50%;
  width: 28px;
  height: 28px;
  overflow: hidden;
  white-space: nowrap;
`
