import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function ChainIcon(props: IProps) {
  const {
    width = 18,
    height = 16,
    ...rest
  } = props;
  
  return (
    <svg width={width} height={height} viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        d='M7.39092 8.70468C7.73643 9.10926 8.17724 9.44402 8.68345 9.68626C9.18966 9.9285 9.74944 10.0726 10.3248 10.1086C10.9002 10.1447 11.4777 10.072 12.0181 9.89544C12.5586 9.71886 13.0493 9.44253 13.4572 9.08521L15.8708 6.97116C16.6035 6.30664 17.009 5.41662 16.9998 4.49279C16.9907 3.56897 16.5676 2.68525 15.8218 2.03198C15.0759 1.37871 14.067 1.00816 13.0122 1.00013C11.9575 0.992105 10.9414 1.34725 10.1827 1.98906L8.79886 3.19407M10.6091 7.29532C10.2636 6.89074 9.82276 6.55598 9.31655 6.31374C8.81033 6.0715 8.25056 5.92745 7.6752 5.89136C7.09984 5.85526 6.52235 5.92798 5.98189 6.10456C5.44143 6.28114 4.95065 6.55747 4.54284 6.91479L2.12922 9.02884C1.39645 9.69336 0.990987 10.5834 1.00015 11.5072C1.00932 12.431 1.43238 13.3147 2.17822 13.968C2.92407 14.6213 3.93301 14.9918 4.98775 14.9999C6.04249 15.0079 7.05863 14.6528 7.81732 14.0109L9.19309 12.8059'
        stroke='currentColor'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
