import UDText from "../../../../ud-ui/components/text";
import { theme } from "../../../../../styles/theme/theme";
import { DivProps } from "../../../../../typings";

interface IProps extends DivProps {
  teamName: string
  score: number
}

function Score(props: IProps) {
  const {
    teamName,
    score,
    ...rest
  } = props
  
  return (
    <div {...rest} style={{ width: 444, maxWidth: 444, ...rest.style }}>
      <UDText
        id='team-name'
        type='subhead'
        color='white'
        className='fw-700'
        style={{
          fontSize: 80,
          lineHeight: '104px',
          textAlign: 'center',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {teamName}
      </UDText>
      
      <UDText
        id='value'
        type='subhead'
        color={theme.colors.DarkYellow}
        style={{
          fontFamily: 'Saira Stencil One',
          fontSize: 340,
          lineHeight: '260px',
          textAlign: 'center',
          fontVariantNumeric: 'tabular-nums',
        }}
      >
        {score}
      </UDText>
    </div>
  );
}

export default Score;
