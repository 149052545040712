import Static from "../../canvases/static";
import { fabric } from "fabric";
import FadeIn from "../fadeIn";

export class CanvasUtils {
  private manager: Static
  private fade: FadeIn
  
  constructor(manager: Static) {
    this.manager = manager
    this.fade = new FadeIn()
    this.fade.init(manager)
  }
  
  setOpacity(obj: fabric.Object[], opacity: number) {
    for (const o of obj) {
      o.set({ opacity: opacity })
    }
  }
  
  setOpacityAllObjs(opacity: number) {
    const objects = this.manager.canvas.getObjects()
    this.setOpacity(objects, opacity)
  }
}
