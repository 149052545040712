import { TFunction } from 'i18next';
import * as yup from 'yup';
import { AuthRequestAuthCodePayload } from '../interfaces/AuthRequestAuthCodePayload';

const validPhoneRegex = /^(\+7([0-9]{10}))|(\+375([0-9]{9,10}))|(\+374([0-9]{8}))$/;

export const authRequestCodeValidator = (t: TFunction): yup.SchemaOf<AuthRequestAuthCodePayload> => yup
  .object()
  .shape({
    phone: yup.string()
      .required(t('signIn.validationError.phoneRequired'))
      .matches(validPhoneRegex, t('signIn.validationError.phoneInvalid')),
  });
