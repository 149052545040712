import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Formik, FormikValues } from 'formik';
import { createFilter } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import UDText from '../../../../ud-ui/components/text';
import UDButton from '../../../../ud-ui/components/button';
import UDFormSelect from '../../../../ud-form/components/select';
import { BorderedCreatableSelect } from '../bordered-select';
import { Team } from '../../../domain/interfaces/Team';
import {
  CreatableSelectOption,
  UDCreatableSelectProps,
} from '../../../../ud-form/components/select/component';
import TeamLogo from '../../../../ud-ui/components/team-logo';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../store/store';
import { announceNewTeam, createChampionshipTeam } from '../../../store/championshipTeams/actions';
import { useOrganizer } from '../../../../organizer/ui/hooks/useOrganizer';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { FormikHelpers } from "formik/dist/types";

const TeamSelectOption = styled.div`
  display: flex;
  align-items: center;

  .team-select-logo {
    margin-right: 8px;
  }
`;

const teamToTeamOption = (team: Team): CreatableSelectOption<number, Team> => ({
  label: (
    <TeamSelectOption>
      <TeamLogo className="team-select-logo" url={team.logo?.url} size={32} />
      {team.name}
    </TeamSelectOption>
  ),
  value: team.id,
  data: team,
});

const AddTeamApplicationFormWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;

const AddTeamApplicationFormContainer = styled.div`
  padding: 16px 20px 24px 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 1px rgba(26, 26, 41, 0.08),
    0 8px 16px rgba(26, 26, 41, 0.08);
  margin-bottom: 42px;
`;

const AddTeamApplicationFormFields = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  > div {
    margin: 0 5px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

const TeamSelect = (props: UDCreatableSelectProps) => {
  const filterConfig = {
    stringify: (option: FilterOptionOption<CreatableSelectOption<any, Team>>) =>
      option.data.data!.name,
  };

  return (
    <BorderedCreatableSelect
      {...props}
      isSearchable={true}
      filterOption={createFilter(filterConfig)}
      formatCreateLabel={(inputValue) => `Добавить команду: "${inputValue}"`}
    />
  );
};

type AddTeamApplicationFormProps = {
  teamsToAdd: Team[];
};

const AddTeamApplicationForm = ({
  teamsToAdd,
}: AddTeamApplicationFormProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { info } = useOrganizer();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: { team?: CreatableSelectOption<any, Team> }, { resetForm }: FormikHelpers<FormikValues>) => {
      const { team } = values;
      if (!team) {
        return;
      }

      if (team.__isNew__) {
        if (!info) {
          return;
        }

        dispatch(
          createChampionshipTeam({
            tournamentId: info.id,
            name: String(team.value),
          })
        )
          .then((result) => {
            const createdTeam = unwrapResult(result);

            dispatch(
              announceNewTeam({
                id: createdTeam.id,
                name: createdTeam.name,
                logo: createdTeam.logo,
                createdAt: createdTeam.createdAt,
                players: [],
              })
            );

            return createdTeam;
          })
          .then((createdTeam) => {
            resetForm();
            navigate(`${createdTeam.id}`);
          });
      } else {
        dispatch(
          announceNewTeam({
            id: team.data!.id,
            name: team.data!.name,
            logo: team.data!.logo,
            players: team.data!.players,
          })
        );

        resetForm();
        navigate(`${team.data!.id}`);
      }
    },
    [dispatch, info, navigate]
  );

  const teamOptions = teamsToAdd.map(teamToTeamOption);

  return (
    <AddTeamApplicationFormWrapper>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <AddTeamApplicationFormContainer>
              <UDText type="subhead" className="bold">
                Добавить новую команду
              </UDText>

              <AddTeamApplicationFormFields>
                <UDFormSelect
                  containerProps={{
                    className: 'w-90',
                  }}
                  name="team"
                  placeholder="Введите название"
                  selectComponent={TeamSelect}
                  options={teamOptions}
                />
                <div>
                  <UDButton type="submit" variant="primary">
                    Добавить
                  </UDButton>
                </div>
              </AddTeamApplicationFormFields>
            </AddTeamApplicationFormContainer>
          </form>
        )}
      </Formik>
    </AddTeamApplicationFormWrapper>
  );
};

export default AddTeamApplicationForm;
