import msToClock from "../../../../utils/msToClock";
import * as T from "../typography";
import UDText from "../../../../ud-ui/components/text";
import { theme } from "../../../../../styles/theme/theme";
import { DivProps } from "../../../../../typings";
import classNames from "classnames";

interface IProps extends DivProps {
  milliseconds: number
  roundTo: 'floor' | 'ceil'
}

function Timer(props: IProps) {
  const {
    milliseconds,
    roundTo,
    ...rest
  } = props
  
  return (
    <div {...rest} className={classNames('flex-center flex-column', rest.className)}>
      <T.Header style={{ lineHeight: '54px' }}>время</T.Header>
      <UDText
        id='value'
        type='subhead'
        style={{
          fontFamily: 'Saira Stencil One',
          fontSize: 100,
          lineHeight: '100px',
          color: theme.colors.DarkYellow,
          fontVariantNumeric: 'tabular-nums',
        }}
      >
        {msToClock(milliseconds, roundTo)}
      </UDText>
    </div>
  );
}

export default Timer;
