import UDLogo from "../../../../../ud-ui/components/logo";
import TMLabel from "../../../components/TMLabel";

function Logo() {
  const toContact = 'https://imgameapp.ru'
  
  return (
    <div className='d-flex'>
      <UDLogo
        target='_blank'
        className='fw-800'
        fontSize={26}
        style={{ display: "flex" }}
        to={toContact}
        label={<TMLabel fontSize={16} lineHeight={20}/>}
      />
    </div>
  );
}

export default Logo;
