import React, { ReactElement, useCallback } from 'react';
import UDInput, { UDInputProps } from './component';
import classNames from 'classnames';
import UDPasswordInput from './component/password';
import { UDFormComponentProps } from '../types';
import UDFormFieldWrapper from '../../wrapper';
import { FieldInputProps } from 'formik';

type Props = UDFormComponentProps &
  Omit<UDInputProps, 'hasError' | 'valid' | 'ref'> & {
  inputComponent?: (props: any) => ReactElement<any, any> | JSX.Element | null;
  ref?: React.ForwardedRef<HTMLInputElement>
};

const UDFormInput = (
  props: Props,
  ref: React.ForwardedRef<HTMLInputElement>,
) => {
  const {
    name,
    containerProps,
    label,
    required,
    labelProps,
    inputComponent,
    onChange,
    onBlur,
    ...otherInputProps
  } = props;
  
  const Component = inputComponent
    ? inputComponent
    : otherInputProps.type === 'password'
      ? UDPasswordInput
      : UDInput;
  
  const handleChange = useCallback((field: FieldInputProps<any>) => (e: React.ChangeEvent<any>) => {
    field.onChange(e);
    
    if (onChange) {
      onChange(e);
    }
  }, [onChange]);
  
  const handleBlur = useCallback((field: FieldInputProps<any>) => (e: React.FocusEvent<any>) => {
    field.onBlur(e);
    
    if (onBlur) {
      onBlur(e);
    }
  }, [onBlur]);
  
  return (
    <UDFormFieldWrapper
      name={name}
      containerProps={containerProps}
      label={label}
      labelProps={labelProps}
      required={required}
    >
      {({ field, meta }) => (
        <Component
          ref={ref}
          {...otherInputProps}
          wrapperProps={{
            ...otherInputProps.wrapperProps,
            className: classNames(
              'w-100',
              otherInputProps.wrapperProps?.className,
            ),
          }}
          {...field}
          onChange={handleChange(field)}
          onBlur={handleBlur(field)}
          hasError={meta.touched && !!meta.error}
          valid={!meta.error && meta.touched && meta.value}
        />
      )}
    </UDFormFieldWrapper>
  );
};

export default React.forwardRef(UDFormInput);
