import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { useEffect } from "react";
import { PopupMessage } from "../../../stream/ui/hooks/useParsePopupMessage";
import { setPeriod, setScore, setTeamName, setTime } from "../../store/tmBoard/tmBoard";

type SyncState = {
  type: "syncState",
  time: number,
  teams: [
    {
      name: string,
      score: number
    },
    {
      name: string,
      score: number
    }
  ],
  period: number
}

function useParsePopup() {
  const dispatch = useDispatch<AppDispatch>()
  
  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    
    function receiveMessage(event: MessageEvent) {
      const domain = window.location.origin
      if (!event.origin.includes(domain)) return;
      if (!event?.data) return
      if (typeof event.data !== "string") return;
      
      try {
        const message: PopupMessage = JSON.parse(event.data)
        switch (message.type) {
          case 'syncState': {
            const syncStateData = message as SyncState
            dispatch(setPeriod({ period: syncStateData.period }))
            dispatch(setTime({ milliseconds: syncStateData.time }))
            dispatch(setTeamName({ index: 0, name: syncStateData.teams[ 0 ].name }))
            dispatch(setTeamName({ index: 1, name: syncStateData.teams[ 1 ].name }))
            dispatch(setScore({ index: 0, score: syncStateData.teams[ 0 ].score }))
            dispatch(setScore({ index: 1, score: syncStateData.teams[ 1 ].score }))
            break;
          }
        }
      } catch (e) {
        console.error(e, { data: event.data })
      }
    }
  }, [dispatch])
}

export default useParsePopup
