import useConnectionIndicator from "../../hooks/useConnectionIndicator";
import JoystickIcon from "../../../../ud-ui/components/icon/joystick-icon";
import Indicator from "../components/indicator";
import React from "react";

function ConnectionIndicator() {
  const { isJoystickActive } = useConnectionIndicator()
  
  return (
    <Indicator
      text={isJoystickActive ? 'Соединение с джойстиком установленно!' : 'Нет соединения с джойстиком!'}
      icon={<JoystickIcon/>}
      isActive={isJoystickActive}
      style={{ borderRadius: 8, padding: 8 }}
    />
  );
}

export default ConnectionIndicator;
