/** @jsxImportSource @emotion/react */
import { DivProps } from "../../../../../typings";
import * as S from './styles'
import { MainBlock } from './styles'

interface Props extends DivProps {
  color: string
  size?: number
  blur?: number
  opacity?: number
  glowingProps?: DivProps
}

function BackgroundGlowing(props: Props) {
  const {
    blur,
    color,
    opacity,
    size,
    glowingProps,
    ...rest
  } = props
  
  return (
    <MainBlock {...rest} size={size} style={{ position: 'relative', ...props.style }}>
      <S.BackgroundGlowing
        className={'background-glowing'}
        blur={blur}
        color={color}
        opacity={opacity}
        {...glowingProps}
      />
      <div style={{ zIndex: 1, position: 'relative' }}>{props.children}</div>
    </MainBlock>
  );
}

export default BackgroundGlowing;
