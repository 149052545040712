import { MainBlock } from "./styles";
import LeftSide from "./left-side/left-side";
import RightSide from "./right-side/right-side";
import TMLabel from "../../components/TMLabel";
import BackgroundGlowing from "../../components/main/background-glowing";
import { GlowingCss } from "./right-side/styles";

function MasterBoardPage() {
  
  return (
    <MainBlock>
      <BackgroundGlowing className='tm-logo' {...backgroundProps}>
        <TMLabel fontSize={320} style={{ height: 416 }} className={'flex-center'}/>
      </BackgroundGlowing>
      <LeftSide className='left-side'/>
      <RightSide className='right-side'/>
    </MainBlock>
  );
}

export default MasterBoardPage;

const backgroundProps = {
  style: { zIndex: -1 },
  color: '#511D7E80',
  size: 384,
  blur: 100,
  glowingProps: {
    css: GlowingCss('#511D7E80', '#511D7E'),
  },
}
