import * as S from './styles'
import { TeamNameWrapper } from './styles'
import { TeamIndex } from "./team-index";
import { DivProps } from "../../../../../typings";

interface Props extends DivProps {
  index: 0 | 1
  name: string
  setName: (name: string) => void
}

function TeamName(props: Props) {
  const {
    index,
    name,
    ...rest
  } = props
  
  return (
    <TeamNameWrapper {...rest}>
      <TeamIndex id={'index'} number={index}/>
      <S.TeamName id='name'>
        <S.TeamNameInput
          onChange={(e) => props.setName(e.target.value)}
          defaultValue={name}
          type={'text'}
          placeholder={'Название команды'}
          style={{ fontSize: 32 }}
        />
      </S.TeamName>
    </TeamNameWrapper>
  );
}

export default TeamName;
