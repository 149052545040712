import { useDispatch, useSelector } from "react-redux";
import useBoards from "../../../../template-editor/ui/hooks/useBoards";
import { DivProps } from "../../../../../typings";
import * as S from "./styles";
import { Board, Boards } from "./styles";
import { useCallback } from "react";
import { setActiveBoard } from "../../../../template-editor/store/templates/templates";
import { selectPopup } from "../../../store/overlay/selectors";
import UDText from "../../../../ud-ui/components/text";
import PopupControl from "./popupControl";

interface IProps extends DivProps {
  boardId?: number
}

function Dashboard(props: IProps) {
  const dispatch = useDispatch()
  const { boardId, className, ...rest } = props
  const { boards, activeBoard } = useBoards()
  const popup = useSelector(selectPopup)
  
  const handleChangeBoard = useCallback((id: number | undefined) => {
    if (activeBoard?.id === id) {
      dispatch(setActiveBoard({ id: undefined }))
      popup?.postMessage(JSON.stringify({ type: 'changeBoard', boardId: undefined }))
    } else {
      dispatch(setActiveBoard({ id }))
      popup?.postMessage(JSON.stringify({ type: 'changeBoard', boardId: id }))
    }
  }, [activeBoard?.id, dispatch, popup]);
  
  if (boardId) {
    return (
      <S.Dashboard className={'flex-center h-100 ' + className} {...rest}>
        <PopupControl boardId={boardId} isHidePanel={true} isLocked={true}/>
      </S.Dashboard>
    )
  }
  
  return (
    <S.Dashboard className={'flex-center ' + className} {...rest}>
      <UDText type={'headline'} style={{ textAlign: 'center' }} color={'#686A79'}>Список табло</UDText>
      <Boards className={'mt-2'}>
        {boards.filter(board => board.type === 'static' || board.type === 'carousel' || board.type === 'tv')
          .map(board => {
            return (
              <Board
                active={board.id === activeBoard?.id}
                onClick={() => handleChangeBoard(board.id)}
              >
                <UDText color={'white'} type={'subhead'}>{board.name}</UDText>
              </Board>
            )
          })}
        
        <Board
          active={activeBoard === undefined}
          onClick={() => handleChangeBoard(undefined)}
        >
          <UDText color={'white'} type={'subhead'}>Выкл.</UDText>
        </Board>
      </Boards>
    </S.Dashboard>
  );
}

export default Dashboard;
