import { TeamGroup } from "../../../../tourneys/domain/interfaces/TeamGroup";
import UDButton from "../../../../ud-ui/components/button";
import { useEffect } from "react";

interface IProps {
  onClick: (id: number) => void
  groups: TeamGroup[]
}

function ChooseGroupForm(props: IProps) {
  const { groups, onClick } = props
  
  useEffect(() => {
    if (groups.length === 1) {
      onClick(groups[ 0 ].id)
    }
  }, [groups, onClick]);
  
  return (
    <div className={'w-100 flex-center gap-5 flex-wrap'} style={{ minHeight: 100, maxWidth: 400 }}>
      {groups.map(group =>
        <UDButton onClick={onClick.bind(null, group.id)} variant={'primary'} key={group.id}>{group.name}</UDButton>)
      }
    </div>
  );
}

export default ChooseGroupForm;
