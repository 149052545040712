import * as T from '../typography';

interface Props {
  value: string
}

function Header(props: Props) {
  
  return (
    <T.Header className='fw-800' style={{ lineHeight: '50px', textAlign: 'center' }}>
      {props.value}
    </T.Header>
  );
}

export default Header;
