export const formatPhone = (value = ''): string => {
  const normalizedPhone = value.replace(/[^0-9]/g, '');
  
  const validPhoneCodes = ['7', '375', '374'];
  
  const code = validPhoneCodes.find((code) => normalizedPhone.startsWith(code));
  if (!code) return normalizedPhone;
  
  let regexPhone: RegExp | undefined;
  
  switch (code) {
    case '7': {
      // Russia and Kazakhstan
      regexPhone = /^(\d?)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/;
      break;
    }
    case '375': {
      // Belarus
      // +375 (33) 302-66-60
      regexPhone = /^(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})$/;
      break;
    }
    case '374': {
      // Armenia
      // +374 (51) 123-456
      regexPhone = /^(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,3})$/;
      break;
    }
  }
  
  if (!regexPhone) return normalizedPhone;
  
  return normalizedPhone.replace(regexPhone, (_, ...parts) => {
    const results = [];
    
    if (parts[ 0 ]) {
      results.push('+', parts[ 0 ]);
    }
    
    if (parts[ 1 ]) {
      results.push(' (', parts[ 1 ]);
    }
    
    if (parts[ 2 ]) {
      results.push(') ', parts[ 2 ]);
    }
    
    if (parts[ 3 ]) {
      results.push('-', parts[ 3 ]);
    }
    
    if (parts[ 4 ]) {
      results.push('-', parts[ 4 ]);
    }
    
    return results.join('');
  });
};
