import { Object } from "fabric/fabric-impl";
import { ReplacerAbstract } from "./replacerAbstract";

class ReplaceCarousel extends ReplacerAbstract {
  readonly prefix = 'carousel-'
  
  replace(props: IProps) {
    this.replaceText(props)
    this.replaceImage(props)
  }
  
  replaceText(props: IProps) {
    const { data, startOpacity, teamIndex } = props
    const {
      name,
      number,
      position,
    } = data
    
    const prefix = this.prefix
    
    this.replacer.replaceTextByIndicator({
      indicator: prefix + 'name',
      text: name ?? '',
      options: { opacity: startOpacity, teamIndex },
    })
    this.replacer.replaceTextByIndicator({
      indicator: prefix + 'number',
      text: number?.toString() ?? '',
      options: { opacity: startOpacity, teamIndex },
    })
    this.replacer.replaceTextByIndicator({
      indicator: prefix + 'position',
      text: position ?? '',
      options: { opacity: startOpacity, teamIndex },
    })
  }
  
  replaceImage(props: IProps) {
    const { data, startOpacity, teamIndex, isFadeIn } = props
    const fadeIn = this.fadeIn?.fadeIn.bind(this.fadeIn)
    const smoothAppearance = (object: Object) => {isFadeIn && fadeIn && fadeIn([object], undefined)}
    const {
      avatar,
    } = data
    
    const prefix = this.prefix
    
    this.replacer.replaceImageByIndicator({
        indicator: prefix + 'avatar',
        url: avatar ?? '',
        options: {
          opacity: startOpacity,
          onLoaded: smoothAppearance,
          teamIndex,
          removePlaceholder: false,
          hidePlaceholder: true,
          additionalData: { delete: true },
        },
      },
    )
  }
}


export interface IData {
  name?: string
  position?: string
  avatar?: string
  number?: number
}

interface IProps {
  data: IData
  teamIndex: number
  startOpacity: number
  isFadeIn?: boolean
}

export default ReplaceCarousel
