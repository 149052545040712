import { useMatch, useNavigate } from "react-router-dom";
import { Pages } from "../../../navigation/domain/enums/pages";
import React, { useCallback } from "react";
import UDButton from "../button";
import UDColorableIcon from "../icon/colorable-icon";
import classNames from "classnames";
import { UDTabProps } from "./interfaces";
import { Divider } from "../divider";
import UDText from "../text";

export const UDTab = ({ tab }: UDTabProps) => {
  const navigate = useNavigate();
  const isActiveTourney = useMatch(`${Pages.TOURNEYS.EDIT}/${tab.hash}/*`);
  const isActiveTeam = useMatch(`${Pages.TEAMS.EDIT}/${tab.hash}/*`)
  const isActivePlayer = useMatch(`${Pages.PLAYERS.VIEW}/${tab.hash}/*`)
  const isActive = isActiveTeam || isActiveTourney || isActivePlayer;
  
  const handleTabClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      const tabPath = e.currentTarget.dataset.hash;
      if (tabPath) {
        tab.onClick?.()
        navigate(tabPath, { replace: true, state: tab.state });
      }
    }, [navigate, tab]);
  
  return (
    <div className='control d-flex align-items-center'>
      <UDButton
        key={tab.hash}
        disabled={tab.disabled}
        variant={isActive ? 'primary' : 'light'}
        data-hash={tab.hash}
        onClick={handleTabClick}
        type='button'
        className='d-flex align-items-center'
      >
        {tab.label}
        <UDColorableIcon
          name='arrow-right'
          componentProps={{
            className: classNames('arrow ml-2', { active: isActive }),
          }}
        />
      </UDButton>
      {tab.subLabel && (
        <>
          <Divider type={'horizontal'} color={'#F5D956'} height={'1px'} width={'17px'}/>
          <UDButton
            key={tab.hash}
            disabled={tab.disabled}
            variant={'secondary'}
            data-hash={tab.hash}
            type='button'
            className='d-flex align-items-center'
          >
            <UDText type={'subhead'} maxWidth={145}>{tab.subLabel}</UDText>
            <UDColorableIcon
              name='arrow-right'
              componentProps={{
                className: classNames('arrow ml-2', { active: isActive }),
              }}
            />
          </UDButton>
        </>
      )}
    </div>
  );
};
