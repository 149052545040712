import * as S from './styles';
import React, { HTMLAttributes } from "react";
import UDText from "../../../ud-ui/components/text";
import classNames from "classnames";

type Variants =
  | 'light'
  | 'dark-yellow'
  | 'blue'

type Shapes =
  | 'round'
  | 'circle'

interface IProps extends HTMLAttributes<HTMLButtonElement> {
  variant?: Variants
  shape?: Shapes
}

function Button(props: IProps, ref: React.ForwardedRef<HTMLButtonElement>) {
  const { variant = 'light', shape = 'round', ...rest } = props
  
  return (
    <S.Button {...rest} className={classNames(variant, shape, rest.className)} ref={ref}>
      <UDText
        className='fw-600 flex-center gap-5'
        style={{ textAlign: 'center', color: 'inherit', fontFamily: 'SF Pro Text' }}
        type={'title-3'}
      >
        {props.children}
      </UDText>
    </S.Button>
  );
}

export default React.forwardRef(Button);
