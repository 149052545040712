import { DadataAreaType } from "../../domain";

interface IProps {
  suggestion: any
}

function getForeignFiasLevel(props: IProps) {
  const { suggestion } = props
  if (suggestion.fias_level === DadataAreaType.FOREIGN) {
    if (suggestion.house !== null) {
      return DadataAreaType.HOUSE
    }
    
    if (suggestion.street !== null) {
      return DadataAreaType.STREET
    }
    
    if (suggestion.city !== null) {
      return DadataAreaType.CITY
    }
    
    return DadataAreaType.COUNTRY
  } else {
    return suggestion.fias_level
  }
}

export default getForeignFiasLevel
