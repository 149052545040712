import useGameInfo from "../../../../../stream/ui/hooks/useGameInfo";
import Static from "../../canvases/static";
import useReplacer from "../useReplacer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../store/store";
import { useTranslation } from "react-i18next";
import useFade from "../useFade";
import { setActiveBoard } from "../../../../../template-editor/store/templates/templates";
import { MutableRefObject, useCallback, useMemo } from "react";
import { selectActiveBoard } from "../../../../../template-editor/store/templates/selectors";
import useOnLoadedCommon from "./useOnLoadedCommon";
import getTeamPositions from "./helpers/getTeamPositions";
import { Member } from "../../../../../stream/domain/interfaces/gameInfo/gameInfoMapped";
import capitalized from "../../../../../utils/capitalized";

interface IProps {
  manager: Static
  timeout: MutableRefObject<NodeJS.Timer | null>
}

interface ITeamPositions {
  [ p: string ]: Member[]
}

let positionIndex = 0
let playerIndex = 0
let maxPlayerIndex = 0

function useOnLoadedCarousel(props: IProps) {
  const { manager, timeout } = props
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const gameInfo = useGameInfo()
  const replacer = useReplacer(manager)
  const fade = useFade(manager)
  const activeBoard = useSelector(selectActiveBoard)
  const { onLoaded: onLoadedCommon } = useOnLoadedCommon({ manager })
  
  const handleResetActiveBoard = useCallback(() => {
    dispatch(setActiveBoard({ id: undefined }))
  }, [dispatch]);
  
  const firstTeamPosition: ITeamPositions = useMemo(() => {
    return getTeamPositions({ teamMembers: gameInfo.teams?.first.members ?? [] })
  }, [gameInfo.teams?.first.members]);
  const secondTeamPosition: ITeamPositions = useMemo(() => {
    return getTeamPositions({ teamMembers: gameInfo.teams?.second.members ?? [] })
  }, [gameInfo.teams?.second.members]);
  
  const replacePlayer = useCallback((teamPositions: ITeamPositions, teamIndex: number) => {
    const position = positions[ positionIndex ]
    const player = (teamPositions[ position ]?.[ playerIndex ] ?? null) as Member | null
    const positionName = player?.position && t(`player.position.${player?.position}`)
    replacer.carousel.replace({
      isFadeIn: true,
      teamIndex,
      startOpacity: 0,
      data: {
        avatar: player?.photo,
        name: `${player?.name ?? ''} ${player?.surname ?? ''}`,
        number: player?.number,
        position: positionName && capitalized(positionName),
      },
    })
    
    return player
  }, [replacer.carousel, t]);
  
  const nextPosition = useCallback(() => {
    positionIndex += 1
    
    playerIndex = 0
    const position = positions[ positionIndex ]
    maxPlayerIndex = Math.max(
      firstTeamPosition[ position ]?.length ?? 0,
      secondTeamPosition[ position ]?.length ?? 0,
    )
  }, [firstTeamPosition, secondTeamPosition]);
  
  function nextPlayer() {
    const allObjects = manager?.canvas?.getObjects() ?? []
    const carouselObjects = allObjects?.filter(object => (object?.data?.indicator)?.includes('carousel') || object?.data?.delete)
    if (carouselObjects?.length === 0) {
      return
    }
    
    playerIndex += 1
    if (playerIndex >= maxPlayerIndex) {
      nextPosition()
      
      if (positionIndex >= positions.length) {
        fade.fadeOut(allObjects).then(handleResetActiveBoard)
        return;
      }
    }
    
    fade.fadeOut(carouselObjects, { deleteAfter: false }).then(() => {
      for (const obj of carouselObjects) {
        if (obj?.data?.delete) {
          manager?.canvas?.remove(obj)
        }
      }
      loadCurrentPlayer()
    })
  }
  
  const loadCurrentPlayer = useCallback(
    () => {
      const first = replacePlayer(firstTeamPosition, 0)
      const second = replacePlayer(secondTeamPosition, 1)
      const isSkip = !first && !second
      
      const allObject = manager?.canvas?.getObjects().filter(object => object.opacity === 0) ?? []
      for (const obj of allObject) {
        fade.fadeIn([obj])
      }
      
      if (isSkip) {
        nextPlayer()
      } else {
        new Promise(resolve => {
          timeout.current = setTimeout(resolve, (activeBoard?.duration ?? 5) * 1000)
        }).then(nextPlayer)
      }
    },
    [activeBoard?.duration, fade, firstTeamPosition, manager?.canvas, nextPlayer, replacePlayer, secondTeamPosition, timeout],
  );
  
  const onLoaded = useCallback(
    () => {
      if (!manager) {return;}
      if (!gameInfo) {return}
      if (!activeBoard) return;
      
      onLoadedCommon()
      
      positionIndex = 0
      playerIndex = 0
      maxPlayerIndex = Math.max(
        firstTeamPosition[ positions[ 0 ] ]?.length ?? 0,
        secondTeamPosition[ positions[ 0 ] ]?.length ?? 0,
      )
      
      loadCurrentPlayer()
    },
    [activeBoard, firstTeamPosition, gameInfo, loadCurrentPlayer, manager, onLoadedCommon, secondTeamPosition],
  );
  
  return { onLoaded }
}

export default useOnLoadedCarousel

const positions = ['goalkeeper', 'defender', 'midfielder', 'forward'];
