import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { useParams } from "react-router-dom";
import { useParsePopupMessage } from "../useParsePopupMessage";
import { useEffect, useMemo } from "react";
import { useQueryParam } from "use-query-params";
import useGameInfo from "../useGameInfo";
import useTemplates from "../../../../template-editor/ui/hooks/useTemplates";
import { useAuth } from "../../../../auth/ui/hooks/useAuth";
import { setActiveBoard, setActiveTemplate } from "../../../../template-editor/store/templates/templates";
import {
  setBoardLocked,
  setHideControlPanel,
  setPassedEventsId as setPassedEventsIdOverlay,
} from "../../../store/overlay/overlay";
import { TokenStorage } from "../../../../core/tokenStorage";
import supabase from "../../../../core/supabase/supabase";
import { fetchPassedEvents, syncGameInfo } from "../../../store/gameInfo/actions";
import { syncTime } from "../../../store/time/actions";
import { setPassedEventsId as setPassedEventsIdGameInfo } from "../../../store/gameInfo/gameInfo";
import { fetchUserTemplates } from "../../../../template-editor/store/templates/actions";
import { getCurrentBoard } from "../../../domain/utils/getCurrentBoard";

function useInitDataOverlay() {
  const dispatch = useDispatch<AppDispatch>()
  const { matchId: id } = useParams()
  
  const matchId = useMemo(() => id ? parseInt(id) : 0, [id]);
  const encodeToken = useQueryParam('token')[ 0 ] as string | undefined
  const boardId = useQueryParam('boardId')[ 0 ] as string | undefined
  const isLocked = useQueryParam('locked')[ 0 ] as string | undefined
  const isHidePanel = useQueryParam('hidePanel')[ 0 ] as string | undefined
  const { organizer, championship } = useGameInfo()
  const { isLoaded } = useTemplates()
  
  const isDev = process.env.NODE_ENV !== 'production'
  const { user } = useAuth()
  const isAdmin = useMemo(() => {
    if (isDev) return true
    if (!user) return false
    return user.id === 20
  }, [isDev, user])
  
  useEffect(() => {
    dispatch(setActiveBoard({ id: boardId ? parseInt(boardId) : undefined }));
    dispatch(setBoardLocked((isLocked) === 'true'))
    dispatch(setHideControlPanel(isHidePanel === 'true'))
  }, [boardId, dispatch, isHidePanel, isLocked])
  
  useEffect(() => {
    if (!encodeToken) return
    const token = window.atob(encodeToken)
    const tokenStorage = new TokenStorage()
    tokenStorage.saveToken(token)
  }, [encodeToken]);
  
  useEffect(() => {
    supabase.overlayLogs.init({ matchId })
    dispatch(syncGameInfo({ matchId }))
    dispatch(syncTime({ matchId }))
    dispatch(fetchPassedEvents({ matchId }))
    supabase.matchChronology.getRow(matchId).then((data) => {
      const passedEvents = data?.chronology.map(event => event.id) ?? []
      dispatch(setPassedEventsIdGameInfo(passedEvents))
      dispatch(setPassedEventsIdOverlay(passedEvents))
    })
  }, [dispatch, matchId])
  
  useEffect(() => {
    if (!organizer) return
    if (isLoaded) return;
    supabase.overlayLogs.init({ tournamentId: organizer.id })
    dispatch(fetchUserTemplates({ ownerId: organizer.id, visibility: isAdmin ? ['superAdmin', 'all'] : ['all'] }))
      .then(() => {
        window?.opener?.postMessage(JSON.stringify({ type: 'popupLoaded' }), '*')
      })
      .catch((data) => {
        supabase.overlayLogs.createError({
          comment: 'Не удалось получить шаблоны пользователя',
          message: data.toString(),
        })
      })
  }, [dispatch, isAdmin, isLoaded, organizer]);
  
  useEffect(() => {
    if (!championship?.overlayId) return
    dispatch(setActiveTemplate({ id: championship.overlayId }))
    supabase.overlayLogs.init({
      championshipId: championship.id,
      templateId: championship.overlayId,
    })
  }, [championship?.id, championship?.overlayId, dispatch]);
  
  useEffect(() => {
    if (!isLoaded) return
    if (boardId) return;
    getCurrentBoard(matchId).then(currentBoard => {
      dispatch(setActiveBoard({ id: currentBoard }))
    })
  }, [dispatch, matchId, isLoaded, boardId]);
}

export default useInitDataOverlay
