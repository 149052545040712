import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import savePassedTime from "./handlers/savePassedTime";
import checkInitTime from "./handlers/checkInitTime";
import { createFetchTmBoardReducer } from "./reducers";

interface Team {
  name: string
}

export interface TmBoardSliceState {
  isLoading: boolean
  isLoaded: boolean
  
  teams: [Team, Team]
  score: [number, number]
  
  period: number
  // in milliseconds
  time: number
  startTime: number | null
  isTimerStart: boolean
  isReverse: boolean
  
  type: 'offline' | 'online';
}

const
  initialState: TmBoardSliceState = {
    isLoaded: false,
    isLoading: false,
    
    teams:
      localStorage.getItem('teams') ?
        JSON.parse(localStorage.getItem('teams') as string) :
        [{ name: 'Команда 1' }, { name: 'Команда 2' }],
    
    score:
      localStorage.getItem('score') ?
        JSON.parse(localStorage.getItem('score') as string)
        : [0, 0],
    
    period:
      localStorage.getItem('period') ?
        JSON.parse(localStorage.getItem('period') as string) :
        1,
    time: checkInitTime(
      Number(localStorage.getItem('time')),
      localStorage.getItem('startTime') ? Number(localStorage.getItem('startTime') as string) : null,
      localStorage.getItem('isReverse') === 'true',
    ),
    startTime: null,
    
    isTimerStart: false,
    isReverse: localStorage.getItem('isReverse') === 'true',
    
    type: 'offline',
  };

export const tmBoardSlice = createSlice({
  name: 'tmBoard',
  initialState,
  extraReducers: (builder) => {
    createFetchTmBoardReducer(builder)
  },
  reducers: {
    setTeamName: (state, action: PayloadAction<{ index: 0 | 1, name: string }>) => {
      state.teams[ action.payload.index ].name = action.payload.name
      localStorage.setItem('teams', JSON.stringify(state.teams))
    },
    setPeriod: (state, action: PayloadAction<{ period: number }>) => {
      state.period = Math.max(action.payload.period, 1)
      localStorage.setItem('period', JSON.stringify(state.period))
      
      if (state.isTimerStart) {
        state.startTime = Date.now()
        localStorage.setItem('startTime', state.startTime.toString())
      } else {
        state.startTime = null
        localStorage.removeItem('startTime')
      }
      
      state.time = 0
      localStorage.setItem('time', state.time.toString())
    },
    setScore: (state, action: PayloadAction<{ index: 0 | 1, score: number }>) => {
      state.score[ action.payload.index ] = Math.max(action.payload.score, 0)
      localStorage.setItem('score', JSON.stringify(state.score))
    },
    setTime: (state, action: PayloadAction<{ milliseconds: number }>) => {
      state.time = Math.max(action.payload.milliseconds, 0)
      localStorage.setItem('time', state.time.toString())
      
      if (state.isTimerStart) {
        state.startTime = Date.now()
        localStorage.setItem('startTime', state.startTime.toString())
      } else {
        state.startTime = null
        localStorage.removeItem('startTime')
      }
      
      if (state.time <= 999) {
        state.isReverse = false
      }
    },
    setIsTimerStart: (state, action: PayloadAction<{ isTimerStart: boolean }>) => {
      savePassedTime(state)
      state.isTimerStart = action.payload.isTimerStart
      
      if (state.isTimerStart) {
        state.startTime = Date.now()
        localStorage.setItem('startTime', state.startTime.toString())
      } else {
        state.startTime = null
        localStorage.removeItem('startTime')
      }
    },
    toggleTimer: (state) => {
      savePassedTime(state)
      state.isTimerStart = !state.isTimerStart
      
      if (state.isTimerStart) {
        state.startTime = Date.now()
        localStorage.setItem('startTime', state.startTime.toString())
      } else {
        state.startTime = null
        localStorage.removeItem('startTime')
      }
    },
    setIsReverse: (state, action: PayloadAction<{ isReverse: boolean }>) => {
      savePassedTime(state)
      state.isReverse = action.payload.isReverse
      localStorage.setItem('isReverse', state.isReverse.toString())
    },
    toggleReverse: (state) => {
      savePassedTime(state)
      
      if (state.time <= 999) {
        state.isReverse = false
      } else {
        state.isReverse = !state.isReverse
      }
      
      localStorage.setItem('isReverse', state.isReverse.toString())
    },
    setType: (state, action: PayloadAction<{ type: 'offline' | 'online' }>) => {
      state.type = action.payload.type
    },
    setData: (state, action: PayloadAction<Partial<TmBoardSliceState>>) => {
      if (!action.payload) return
      Object.entries(action.payload).forEach(([key, value]) => {
        if (value !== undefined) {
          (state as any)[ key ] = value
        }
      })
    },
  },
});

export const {
  setTeamName,
  setPeriod,
  setScore,
  setTime,
  setIsTimerStart,
  toggleTimer,
  setIsReverse,
  toggleReverse,
  setType,
  setData,
} = tmBoardSlice.actions

export default tmBoardSlice.reducer
