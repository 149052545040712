import styled from "@emotion/styled";
import { css } from "@emotion/react";
import size from "../../../../domain/constants/size";

export const MainBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.DarkBlue};
  
  padding-block: 10px;
  min-height: min-content;
  
  overflow: auto;
  position: relative;
  
  width: 100vw;
  height: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  @media (max-width: ${size.tablet}px) {
    & #open-board-button {
      width: 342px !important;
    }
  }
`

export const WaterMarkCss = css`
  color: #F7F7F71A;
  background: unset;
  -webkit-text-fill-color: unset;
`
