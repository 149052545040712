import styled from "@emotion/styled";

export const MainBlock = styled.div<{ size?: number }>`
  min-width: ${({ size }) => size ? `${size}px` : '100%'};
  min-height: ${({ size }) => size ? `${size}px` : '100%'};
`

export const BackgroundGlowing = styled.div<{ blur?: number, opacity?: number, color: string }>`
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 50%;
  filter: ${({ blur }) => `blur(${blur}px)`};
  background: ${({ color }) => color};
  opacity: ${({ opacity }) => opacity};
`;
