import { useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";
import { useQueryParam } from "use-query-params";
import UDButton from "../../../../ud-ui/components/button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { setPopup } from "../../../store/overlay/overlay";
import { selectPopup, selectPopupIsLoaded } from "../../../store/overlay/selectors";
import useGameInfo from "../../hooks/useGameInfo";
import { selectTime } from "../../../store/time/selectors";
import useTimer from "../../hooks/useTimer";

interface PopupControlProps {
  isLocked?: boolean
  boardId?: number
  isHidePanel?: boolean
}

type Message = {
  type: string
  [ p: string ]: any
};

function PopupControl(props: PopupControlProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { isLocked, boardId, isHidePanel } = props
  const { matchId: id } = useParams()
  const matchId = useMemo(() => id ? parseInt(id) : 0, [id]);
  const encodeToken = useQueryParam('token')[ 0 ] as string | undefined
  
  const popup = useSelector(selectPopup)
  const popupIsLoaded = useSelector(selectPopupIsLoaded)
  const gameInfo = useGameInfo()
  const time = useSelector(selectTime)
  const { timer } = useTimer()
  
  const handleMessage = useCallback((message: Message) => {
    popup?.postMessage(JSON.stringify(message), '*')
  }, [popup]);
  
  const handleOpenOverlay = useCallback(() => {
    const windowProps = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, width=1200, height=800`;
    
    const isLockedParam = isLocked !== undefined ? `&locked=${isLocked}` : ''
    const boardIdParam = boardId !== undefined ? `&boardId=${boardId}` : ''
    const isHidePanelParam = isHidePanel !== undefined ? `&hidePanel=${isHidePanel}` : ''
    const queryParams = `${isLockedParam}${boardIdParam}${isHidePanelParam}`
    const url = `${window.location.origin}/cover/${matchId}/?token=${encodeToken}${queryParams}`
    const popup = window.open(url, "Overlay window", windowProps);
    dispatch(setPopup(popup))
    
  }, [boardId, dispatch, encodeToken, isHidePanel, isLocked, matchId]);
  
  const handleSync = useCallback(() => {
    if (popupIsLoaded && popup) {
      handleMessage({ type: 'syncState', gameInfo, time, timer })
    }
  }, [popupIsLoaded, popup, handleMessage, gameInfo, time, timer]);
  
  useEffect(() => {
    handleSync()
  }, [handleMessage, popup, popupIsLoaded]);
  
  return (
    <div className={'flex-column gap-4 flex-wrap'}>
      <UDButton onClick={handleOpenOverlay} variant={'primary'}>Открыть окно</UDButton>
      <UDButton onClick={handleSync} disabled={popup === null || !popupIsLoaded} variant={'secondary'}>
        Синхронизировать окна
      </UDButton>
    </div>
  );
}

export default PopupControl;
