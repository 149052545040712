import React, { ReactElement, useCallback } from 'react';
import { FieldProps, useFormikContext } from 'formik';
import UDSelect, { UDSelectProps } from './component';
import { UDFormComponentProps } from '../types';
import UDFormFieldWrapper from '../../wrapper';
import UDInput from "../input/component";
import styled from "@emotion/styled";
import classNames from "classnames";

type UDFormSelectProps<isMulti extends boolean> = UDFormComponentProps & UDSelectProps<isMulti> & {
  selectComponent?: (props: any) => ReactElement<any, any> | null;
  isAvailable?: boolean,
};

type Returning = <isMulti extends boolean = false>(
  props: UDFormSelectProps<isMulti> & { highlight?: boolean },
) => JSX.Element;

const UdFormTemplateSelect: Returning = (props) => {
  const {
    name,
    containerProps,
    required,
    label,
    labelProps,
    selectComponent,
    isAvailable,
    highlight,
    ...otherSelectProps
  } = props;
  
  const { setFieldValue } = useFormikContext();
  const onChange = useCallback(
    (value: any, isMulti: any) => {
      setFieldValue(name, value);
      
      if (props.onChange) {
        props.onChange(value, isMulti);
      }
    },
    [name, props, setFieldValue],
  );
  
  const Component = selectComponent || UDSelect;
  
  return (
    <UDFormFieldWrapper
      name={name}
      containerProps={containerProps}
      label={label}
      labelProps={labelProps}
      required={required}
    >
      {({ field }: FieldProps<any>) => {
        if (isAvailable === false) {
          const text = <Link title='This is my tooltip' href={''} className={'mr-3'}>Оформить подписку</Link>
          const tooltip = "Для бесплатной версии доступен только один шаблон.";
          return <UDInput
            value={"ImGame"}
            style={{ userSelect: "none", pointerEvents: 'none' }}
            iconProps={{ name: 'info', position: "icon-right", component: text, tooltip }}
          />
        } else if (isAvailable === undefined) {
          return <UDInput
            value={'Загрузка'}
            readOnly
            style={{ userSelect: "none", pointerEvents: 'none' }}
          />
        }
        return (
          <Wrapper className={classNames({ 'highlight': highlight })}>
            <Component {...otherSelectProps} {...field} onChange={onChange}/>
          </Wrapper>
        );
      }}
    </UDFormFieldWrapper>
  );
};

export default UdFormTemplateSelect;

const Link = styled.a`
  font-family: SF Pro Display, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  text-align: left;
  color: #007AFF;
  
  &:visited {
    color: #007AFF;
  }
`

const Wrapper = styled.div`
  background: transparent;
  border-radius: 8px;

  &.highlight {
    animation: highlightAnimation 1s ease-in-out 3;
  }


  @keyframes highlightAnimation {
    0% {
      box-shadow: unset;
    }
    50% {
      box-shadow: 0 0 20px 10px #F5D956;
    }
    100% {
      box-shadow: unset;
    }
`
