/** @jsxImportSource @emotion/react */
import UDText from "../../../../../ud-ui/components/text";
import { CopyIconBox, UrlInputWrapper } from "./styles";
import { theme } from "../../../../../../styles/theme/theme";
import EmptyButton from "../../../../../ud-ui/components/emptyButton";
import CopyIcon from "../../../../../ud-ui/components/icon/copy-icon";
import { increaseClickArea } from "../../../../../template-editor/ui/pages/styles";
import { toast } from "react-toastify";
import { DivProps } from "../../../../../../typings";

interface IProps extends DivProps {
  value: string
}

function UrlInput(props: IProps) {
  const { value, ...rest } = props
  
  const handleCopy = () => {
    navigator.clipboard.writeText(value).then(() => {
      toast.success('Ссылка скопирована')
    })
  }
  
  return (
    <UrlInputWrapper {...rest}>
      <UDText
        color={theme.colors.DarkYellow}
        style={{ fontSize: 18 }}
        type={'subhead'}
      >
        {value}
      </UDText>
      <CopyIconBox className='url-icon-box'>
        <EmptyButton
          onClick={handleCopy}
          color={theme.colors.DarkYellow}
          hoverColor={theme.colors.AccentNapplesYellow}
          icon={<CopyIcon className='url-copy-icon' width={20} height={20} strokeWidth={1.2}/>}
          css={increaseClickArea({ height: 60, width: 60 })}
        />
      </CopyIconBox>
    </UrlInputWrapper>
  );
}

export default UrlInput;
