import { useParsePopupMessage } from "../useParsePopupMessage";
import useInitDataOverlay from "./useInitDataOverlay";
import useInitSubscribeOverlay from "./useInitSubscribeOverlay";

function useInitOverlay() {
  useParsePopupMessage()
  useInitDataOverlay()
  useInitSubscribeOverlay()
}

export default useInitOverlay;
