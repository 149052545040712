import React, { useCallback } from 'react';
import { FieldProps, useFormikContext } from 'formik';
import { UDDadataAreaSelectProps, UDDadataAreaSelect } from './component';
import { UDFormComponentProps } from '../types';
import UDFormFieldWrapper from '../../wrapper';
import { IDadataArea } from "../../../../shared/features/dadata-address-autocomplete";

type UDFormAddressSelectProps = UDFormComponentProps & UDDadataAreaSelectProps;

type Returning = (props: UDFormAddressSelectProps) => JSX.Element;

export const UDFormAddressSelect: Returning = (props) => {
  const {
    name,
    containerProps,
    required,
    label,
    labelProps,
    ...otherSelectProps
  } = props;

  const { setFieldValue } = useFormikContext();
  const onChange = useCallback(
    (value: IDadataArea | undefined) => {
      setFieldValue(name, value);

      if (props.onChange) {
        props.onChange(value);
      }
    },
    [name, props, setFieldValue],
  );
  return (
    <UDFormFieldWrapper
      name={name}
      containerProps={containerProps}
      label={label}
      labelProps={labelProps}
      required={required}>
      {({ field }: FieldProps<any>) => (
        <UDDadataAreaSelect {...otherSelectProps} {...field} onChange={onChange} />
      )}
    </UDFormFieldWrapper>
  );
};
