import { useCallback, useEffect } from "react";
import supabase from "../../../core/supabase/supabase";
import { useParams } from "react-router-dom";
import * as syncAction from "../../store/tmBoard/tmBoard";
import { setIsTimerStart, setTime } from "../../store/tmBoard/tmBoard";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import checkInitTime from "../../store/tmBoard/handlers/checkInitTime";
import { TMBoard } from "../../../core/domain/interfaces/supabase/tm-boards/entity";
import { fetchTmBoard } from "../../store/tmBoard/actions";

function useParseSupabase() {
  const dispatch = useDispatch<AppDispatch>()
  const id = useParams()?.id
  
  const setData = useCallback((board: TMBoard) => {
    dispatch(syncAction.setData({
      period: board.period,
      score: board.score,
      teams: board.teams,
      isReverse: board.isReverse,
    }))
    
    const time = checkInitTime(board.time, board.startTime, board.isReverse)
    dispatch(setTime({ milliseconds: time }))
    dispatch(setIsTimerStart({ isTimerStart: Boolean(board.startTime) }))
  }, [dispatch]);
  
  useEffect(() => {
    if (!id) return
    dispatch(fetchTmBoard({ id }))
  }, [dispatch, id])
  
  useEffect(() => {
    if (!id) return
    
    supabase.tmBoards.observeChanges(id)
    return () => {supabase.tmBoards.stopObserveChanges()}
  }, [id])
  
  useEffect(() => {
    supabase.tmBoards.data.subscribe((board) => {
      if (!board) return
      setData(board)
    })
  }, [dispatch, setData]);
}

export default useParseSupabase
