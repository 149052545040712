import { useEffect, useState } from "react";
import supabase from "../../../core/supabase/supabase";
import { ConnectionType } from "../../../core/supabase/tables/match-chronology";

function useConnectionIndicator() {
  const [isJoystickActive, setIsJoystickActive] = useState(true)
  const [ethernetConnection, setEthernetConnection] = useState(true)
  const [connectionStatus, setConnectionStatus] = useState(supabase.matchChronology.connectionStatus.value)
  const [prevConnectionStatus, setPrevConnectionStatus] = useState<ConnectionType>()
  
  useEffect(() => {
    supabase.matchChronology.connectionStatus.subscribe((data, prevValue) => {
      setConnectionStatus(data)
      setPrevConnectionStatus(prevValue)
    })
    
    const activeEthernet = () => {
      setEthernetConnection(true)
      console.debug({ event: 'internet connection recovered' })
    }
    const disableEthernet = () => {
      setEthernetConnection(false)
      console.error({ event: 'internet connection lost' })
    }
    
    window.addEventListener('online', activeEthernet)
    window.addEventListener('offline', disableEthernet)
    return () => {
      window.removeEventListener('online', activeEthernet)
      window.removeEventListener('offline', disableEthernet)
    }
  }, [])
  
  useEffect(() => {
    if (connectionStatus === 'SUBSCRIBED' && ethernetConnection) {
      setIsJoystickActive(true)
    } else {
      setIsJoystickActive(false)
    }
  }, [connectionStatus, ethernetConnection]);
  
  return { isJoystickActive }
}

export default useConnectionIndicator;
