import size from "../constants/size";

interface IProps {
  desktop: number
  tablet: number
  mobile: number
}

function getFontSize(props: IProps) {
  const isTablet = window.matchMedia(`(max-width: ${size.tablet}px)`).matches;
  const isMobile = window.matchMedia(`screen and (orientation: landscape) and (max-height: ${size.mobile.height}px) and (max-width: ${size.tablet}px)`).matches;
  
  switch (true) {
    case isMobile:
      return props.mobile
    case isTablet:
      return props.tablet
    default:
      return props.desktop
  }
}

export default getFontSize
