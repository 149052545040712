import { Member } from "../../../../../../stream/domain/interfaces/gameInfo/gameInfoMapped";
import _ from "lodash";

interface IProps {
  teamMembers: Member[]
}

function getTeamPositions(props: IProps) {
  return _(props.teamMembers)
    .filter({ isInvolved: true })
    .groupBy('position')
    .value()
}

export default getTeamPositions
