import React, { useEffect, useRef } from 'react';

interface IUseTimer {
  time: number;
  setTime: (milliseconds: number) => void;
  isActive: boolean;
  isReverse?: boolean
  startTime?: number | null
}

function useTimer(props: IUseTimer) {
  const { isActive, time, setTime, isReverse, startTime } = props;
  
  const [timer, setTimer] = React.useState(time);
  const interval = useRef<NodeJS.Timeout | undefined>(undefined)
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined)
  
  function changeTimer(milliseconds: number) {
    setTime(milliseconds);
  }
  
  function addToTimer(milliseconds: number) {
    setTime(timer + milliseconds);
  }
  
  useEffect(() => {
    setTimer(time);
  }, [time, startTime])
  
  useEffect(() => {
    function updateTimer() {
      setTimer(prevState => prevState + (isReverse ? -1000 : 1000));
    }
    
    function startTimer() {
      const left = time % 1000
      timeout.current = setTimeout(() => {
        updateTimer();
        interval.current = setInterval(updateTimer, 1000);
      }, isReverse ? left : 1000 - left);
    }
    
    function stopTimer() {
      clearInterval(interval.current)
      clearTimeout(timeout.current)
    }
    
    if (isActive) {
      startTimer()
    } else {
      stopTimer()
    }
    
    return () => {stopTimer()};
  }, [isActive, isReverse, time, startTime])
  
  return { timer, setTimer: changeTimer, addToTimer, isActive }
}

export default useTimer
