import Static from "../canvases/static";
import { useEffect, useMemo } from "react";
import Fade from "../addons/fadeIn";

export default function useFade(manager: Static | undefined) {
  const fade = useMemo(() => new Fade(), [])
  useEffect(() => {
    if (!manager) return
    fade.init(manager)
  }, [manager, fade])
  return fade
}
