import React, { useEffect, useRef, useState } from "react";
import useFabric from "../../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import useReplacer from "../../../../ud-ui/components/fabric-canvas/hooks/useReplacer";
import useTemplates from "../../../../template-editor/ui/hooks/useTemplates";
import { millisToMinutesAndSeconds } from "../../../domain/utils/convertTime";
import useBoards from "../../../../template-editor/ui/hooks/useBoards";
import useTimer from "../../hooks/useTimer";
import { CanvasWrapper } from "./styles";
import useGameInfo from "../../hooks/useGameInfo";
import useTime from "../../hooks/useTime";
import useOnLoadedCommon from "../../../../ud-ui/components/fabric-canvas/hooks/onLoadedHooks/useOnLoadedCommon";
import useOnLoadedCarousel from "../../../../ud-ui/components/fabric-canvas/hooks/onLoadedHooks/useOnLoadedCarousel";
import useOnLoadedTV from "../../../../ud-ui/components/fabric-canvas/hooks/onLoadedHooks/useOnLoadedTV";
import useFade from "../../../../ud-ui/components/fabric-canvas/hooks/useFade";
import useResizeCanvas from "../../../../ud-ui/components/fabric-canvas/hooks/useResizeCanvas";

export function CanvasStaticBoards() {
  const { canvas, manager } = useFabric('static', { id: "boards" })
  const replacer = useReplacer(manager)
  const { activeTemplate } = useTemplates()
  const { activeScheme, activeBoard } = useBoards()
  const { timer } = useTimer()
  const { period } = useTime()
  const gameInfo = useGameInfo()
  const fade = useFade(manager)
  
  const [isInit, setIsInit] = useState(false)
  
  const timeout = useRef<ReturnType<typeof setInterval> | null>(null);
  
  useResizeCanvas({ manager, isManagerInit: isInit })
  const { onLoaded: onLoadedTV } = useOnLoadedTV({ manager })
  const { onLoaded: onLoadedCommon } = useOnLoadedCommon({ manager })
  const { onLoaded: onLoadedCarousel } = useOnLoadedCarousel({ manager, timeout })
  
  useEffect(() => {
    if (!manager) return
    if (!activeTemplate) return
    manager?.setResolution(activeTemplate.resolution)
    manager?.setDimensions(activeTemplate.resolution)
    manager.normalizedViewport()
    setIsInit(true)
  }, [activeTemplate, fade, manager])
  
  useEffect(() => {
    if (!manager) return
    timeout.current && clearInterval(timeout.current)
    const allObject = manager.canvas.getObjects()
    
    fade.fadeOut(allObject).then(() => {
      if (!activeBoard) {
        manager.clear()
        return
      }
      
      if (!activeScheme) return;
      
      switch (activeBoard.type) {
        case 'tv':
          manager.loadLocal({ scheme: activeScheme }).then(() => {
            onLoadedCommon();
            onLoadedTV();
          })
          break;
        case 'static':
          manager.loadLocal({ scheme: activeScheme }).then(onLoadedCommon)
          break;
        case 'carousel':
          manager.loadLocal({ scheme: activeScheme }).then(onLoadedCarousel)
          break;
      }
    })
    
    // eslint-disable-next-line
  }, [activeBoard, manager, replacer])
  
  useEffect(() => {
    if (!manager) return
    replacer.updateText({ indicator: 'teamScore0', text: gameInfo.goals.first.toString() })
    replacer.updateText({ indicator: 'teamScore1', text: gameInfo.goals.second.toString() })
    replacer.updateText({ indicator: 'teamFouls0', text: gameInfo.fouls.first.toString() })
    replacer.updateText({ indicator: 'teamFouls1', text: gameInfo.fouls.second.toString() })
    
    replacer.updateText({ indicator: 'teamScore', text: gameInfo.goals.first.toString(), teamIndex: 0 })
    replacer.updateText({ indicator: 'teamScore', text: gameInfo.goals.second.toString(), teamIndex: 1 })
    replacer.updateText({ indicator: 'teamFouls', text: gameInfo.fouls.first.toString(), teamIndex: 0 })
    replacer.updateText({ indicator: 'teamFouls', text: gameInfo.fouls.second.toString(), teamIndex: 1 })
  }, [gameInfo.fouls, gameInfo.goals, manager, replacer])
  
  useEffect(() => {
    if (!manager) return
    replacer.updateText({ indicator: 'matchTime', text: millisToMinutesAndSeconds(timer) })
    replacer.updateText({ indicator: 'period', text: period.toString() })
  }, [replacer, timer, period, manager])
  
  return (
    <CanvasWrapper>
      {canvas}
    </CanvasWrapper>
  );
}
