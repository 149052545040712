import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const MainBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.DarkBlue};
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-block: 60px;
  
  width: 100%;
  height: 100%;
  
  overflow: hidden;
  
  & #upper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    & #period {
      position: absolute;
      bottom: -18px;
    }
    
    & #score {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
      & #team-name {
        width: 256px !important;
        font-size: 46px !important;
        line-height: 60px !important;
      }
      
      & #value {
        margin-top: 40px !important;
        font-size: 240px !important;
        line-height: 170px !important;
      }
    }
  }
  
  & #center {
    position: relative;
    top: -20px;
    
    & #timer {
      margin-top: 61px;
    }
    
    & #label {
      margin-top: 119px;
    }
  }
`
export const LabelCss = css`
  -webkit-text-fill-color: unset;
  background: unset;
  color: #F7F7F71A;
`
