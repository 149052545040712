import { TmBoardSliceState } from "../tmBoard";

function savePassedTime(state: TmBoardSliceState) {
  if (!state.isTimerStart) { return; }
  const modifier = state.isReverse ? -1 : 1
  const passedTime = Date.now() - (state.startTime as number)
  state.time = Math.max(state.time + passedTime * modifier, 0)
  localStorage.setItem('time', state.time.toString())
  state.startTime = Date.now()
  localStorage.setItem('startTime', state.startTime.toString())
}

export default savePassedTime
