import React, { useCallback } from 'react';
import { FieldProps, useFormikContext } from 'formik';
import { SelectOption, UDCreatableSelect, UDCreatableSelectProps } from './component';
import { UDFormComponentProps } from '../types';
import UDFormFieldWrapper from '../../wrapper';
import { ActionMeta, OnChangeValue } from "react-select";

type UDFormCreatableSelectProps<isMulti extends boolean> = UDFormComponentProps &
  UDCreatableSelectProps<isMulti>;

type Returning = <isMulti extends boolean = false>(
  props: UDFormCreatableSelectProps<isMulti>,
) => JSX.Element;

const UDFormCreatableSelect: Returning = (props) => {
  const {
    name,
    containerProps,
    required,
    label,
    labelProps,
    ...otherSelectProps
  } = props;

  const { setFieldValue } = useFormikContext();
  const onChange = useCallback(
    (value: any, isMulti: any) => {
      setFieldValue(name, value);

      if (props.onChange) {
        props.onChange(value, isMulti);
      }
    },
    [name, setFieldValue],
  );
  return (
    <UDFormFieldWrapper
      name={name}
      containerProps={containerProps}
      label={label}
      labelProps={labelProps}
      required={required}>
      {({ field }: FieldProps<any>) => (
        <UDCreatableSelect {...otherSelectProps} {...field} onChange={onChange} />
      )}
    </UDFormFieldWrapper>
  );
};

export default UDFormCreatableSelect;
