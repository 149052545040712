import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Field, FieldProps, useFormikContext } from 'formik';
import UDFormLabel from '../label';
import { UDFormComponentProps } from '../types';
import UDDropZone, { UDDropZoneProps } from './component/UDDropZone';
import { handleInputInterceptor } from '../../utils/helpers';
import BackgroundLogo from "./component/BackgroundLogo";
import UDText from "../../../ud-ui/components/text";

export type UDFormDropZoneProps = {
  onChangeInterceptor?: (value: any) => any;
  isCover: boolean;
  selectionBackground?: boolean
} & UDFormComponentProps &
  UDDropZoneProps;

type Image = {
  url: string;
  size: number;
  name: string;
};

const UDFormDropZone = (props: UDFormDropZoneProps) => {
  const {
    name,
    containerProps,
    label,
    labelProps,
    formProps,
    onChangeInterceptor = (value: Blob) => value,
    value,
    isCover,
    selectionBackground,
    ...otherDropZoneProps
  } = props;
  const { setFieldValue } = useFormikContext();
  
  let [img, setImg] = useState<any | null>(null);
  
  if (value.length > 0 && img === null) {
    img = value[0];
  }
  
  const accept = 'image/png,image/jpeg,image/jpg';
  const onChange = useCallback(
    (value: any) => {
      handleInputInterceptor(onChangeInterceptor, value).then((value) => {
        setFieldValue(name, value);
      });
    },
    [name, setFieldValue, onChangeInterceptor]
  );
  const onGetImg = (img: Image) => {
    setImg(img);
  };
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps<any>) => (
        <div {...containerProps}>
          {label && (
            <UDFormLabel
              {...labelProps}
              error={meta.error}
              className={classNames('mb-2', labelProps?.className)}
            >
              {label}
            </UDFormLabel>
          )}
          <div {...formProps}>
            <UDDropZone
              accept={accept}
              {...otherDropZoneProps}
              {...field}
              onChange={onChange}
              onGetImg={onGetImg}
            />
            <span className="css-1jigk text footnote mt-2">
              Формат: png, jpg
            </span>
            {!selectionBackground ?
              (img && img.url !== null ? (
                <div className="mt-7 d-flex" style={{ position: 'relative' }}>
                  <div>
                    <img
                      className="br-8"
                      style={{
                        width: '100px',
                        height: '100px',
                        objectFit: 'contain',
                      }}
                      key={img.url || img.externalUrl}
                      src={img.url || img.externalUrl}
                      alt="Логотип команды"
                    />
                    {isCover ? (
                      <div>
                      <span
                        className="p-1 text caption-2 link active bgc-SurfaceSpaceCadet50 opacity-9 br-8"
                        style={{
                          top: '8px',
                          left: '8px',
                          position: 'absolute',
                        }}
                      >
                        Обложка
                      </span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  {img.name ? (
                    <div className="d-flex flex-column ml-5">
                      <UDText type="subhead">{img.name}</UDText>
                      <span className="css-1jigk text footnote">
                      {formatBytes(img.size)}
                    </span>
                      <span
                        style={{ textAlign: 'end' }}
                        className="css-1jigk text footnote"
                      >
                      100%
                    </span>
                      <progress
                        style={{ width: 'auto' }}
                        max="100"
                        value="100"
                      ></progress>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                <div></div>
              )) : (
                <div className="mt-7 d-flex" style={{ position: 'relative' }}>
                  <BackgroundLogo img={img?.url ?? img}/>
                </div>
              )}
          </div>
        </div>
      )}
    </Field>
  );
};

function formatBytes(bytes: number, decimals = 1) {
  if (bytes === 0) {
    return '0';
  } else {
    var k = 1000;
    var dm = decimals < 0 ? 0 : decimals;
    var sizes = ['bytes', 'Kb', 'Mb', 'Gb'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[ i ];
  }
}

export default UDFormDropZone;
