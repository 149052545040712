import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const MainBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.DarkBlue};
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-block: 24px;
  
  width: 100%;
  height: 100%;
  
  overflow: hidden;
  
  & > #header {
    font-size: 24px !important;
    line-height: 28px !important;
  }
  
  & #period {
    #header {
      font-size: 20px !important;
      line-height: 26px !important;
    }
    
    #value {
      margin-top: 8px !important;
      font-size: 60px !important;
      line-height: 60px !important;
    }
  }
  
  & #score {
    width: unset !important;
    max-width: unset !important;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;;
    
    & #team-name {
      width: 259px !important;
      font-size: 32px !important;
      line-height: 38px !important;
    }
    
    & #value {
      margin-top: 19px !important;
      font-size: 160px !important;
      line-height: 120px !important;
    }
  }
  
  & #timer {
    margin-top: 26px;
    
    #header {
      font-size: 20px !important;
      line-height: 26px !important;
    }
    
    #value {
      font-size: 60px !important;
      line-height: 60px !important;
    }
  }
  
  & #advertisement {
    gap: 10px;
    
    & #logo p {
      font-size: 26px !important;
      line-height: 31px !important;
    }
    
    & #divider {
      &:after {
        width: 2px !important;
        height: 26px !important;
      }
    }
    
    & #url {
      font-size: 12px !important;
      line-height: 15px !important;
    }
    
    & #description {
      font-size: 12px !important;
      line-height: 15px !important;
    }
  }
`
export const LabelCss = css`
  -webkit-text-fill-color: unset;
  background: unset;
  color: #F7F7F71A;
`
