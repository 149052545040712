import styled from "@emotion/styled";

export const MainBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.DarkBlue};
  
  width: 100%;
  height: 100vh;
  
  display: flex;
  align-items: center;
  flex-direction: column;
  
  padding-block: 60px;
  
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`
