/** @jsxImportSource @emotion/react */
import useModal from "../../../../../../ud-ui/components/new-modal/useModal";
import UDText from "../../../../../../ud-ui/components/text";
import { theme } from "../../../../../../../styles/theme/theme";
import UrlInput from "../urlInput";
import Button from "../../../../components/button";
import TMLabel from "../../../../components/TMLabel";
import { WaterMarkCss } from "../../../../components/styles";
import { Pages } from "../../../../../../navigation/domain/enums/pages";
import { OfflineModalCss } from "../styles";
import CrossThinIcon from "../../../../../../ud-ui/components/icon/cross-thin-icon";

const domain = window.location.origin
const offlineBoardUrl = domain + Pages.MASTER_BOARDS.builders.controlPanel('')

function useOfflineModal() {
  const { defaultOpen, close, Modal: OfflineModal } = useModal()
  
  const open = () => {
    defaultOpen(
      {
        containerProps: {
          css: OfflineModalCss,
          style: {
            padding: '42px 60px',
            width: '80%',
            maxWidth: 938,
            height: 479,
            overflow: "hidden",
            zIndex: 0,
          },
        },
      },
      <CrossThinIcon color={theme.colors.Gray} className='modal-close-icon'/>,
    )
  }
  
  const handleOpenOfflinePanel = () => {
    window.open(offlineBoardUrl, '_blank')
  }
  
  const Modal = () => (
    <OfflineModal>
      <UDText type={'title-large'} className={'fw-800 header'} color={theme.colors.DarkBlue}>
        Все готово чтобы начать!
      </UDText>
      <UDText
        className='description'
        color={theme.colors.Blue}
        type={'subhead'}
        style={{ fontSize: 22, marginTop: 37 }}
      >
        Ссылка для открытия
        <span className={'text fw-700'} style={{ fontSize: 22, marginTop: 37, color: 'inherit' }}>
          {' панели управления табло'}
        </span>
      </UDText>
      <div className={'mt-3 flex-row justify-content-between gap-6 control'}>
        <UrlInput className='url-open-panel' value={offlineBoardUrl}/>
        <Button className='open-panel-button' onClick={handleOpenOfflinePanel} variant={'dark-yellow'}>Открыть</Button>
      </div>
      
      <UDText
        className='tip'
        type={'subhead'}
        style={{ fontSize: 18, marginTop: 34, width: '80%', lineHeight: 'unset', fontWeight: 300 }}
        color={'#8E8E8E'}
      >
        По этой ссылке откроется панель управления табло. Само табло можно будет открыть из панели.
      </UDText>
      <TMLabel className='watermark' fontSize={320} lineHeight={320} css={WaterMarkCss}/>
    </OfflineModal>
  )
  
  return { open, close, Modal }
}

export default useOfflineModal
