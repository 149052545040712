import axios, { AxiosInstance } from 'axios';
import { IDadataRepository } from '../../domain/repositories/dadata.repository';
import { DadataAreaType, IDadataArea } from '../../domain/entities/dadata-area.entity';
import { mapDadataAreaList } from '../mappers/dadata-area.mapper';

export class DadataRepository implements IDadataRepository {
  private client: AxiosInstance;
  
  constructor(token: string) {
    this.client = axios.create({
      headers: {
        'Authorization': `Token ${token}`,
      },
    });
  }
  
  async getSuggestedAreas(
    name: string,
    options?: {
      types?: DadataAreaType[],
      onlyCity?: boolean
    },
  ): Promise<IDadataArea[]> {
    const { types, onlyCity } = options || {};
    const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
    const data: { [ key: string ]: any } = {
      query: name,
      "locations": [
        { "country": "Россия" },
        { "country": "Казахстан" },
        { "country": "Беларусь" },
        { "country": "Узбекистан" },
        { "country": "Армения" },
      ],
      "from_bound": onlyCity ? { "value": "city" } : undefined,
      "to_bound": onlyCity ? { "value": "city" } : undefined,
    };
    
    const response = await this.client.post(url, data);
    const { suggestions } = response.data;
    
    return mapDadataAreaList(suggestions, types);
  }
}
