import UDButton from "../../../../ud-ui/components/button";
import { useCallback, useEffect, useState } from "react";
import { syncGameInfo } from "../../../store/gameInfo/actions";
import { syncTime } from "../../../store/time/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import useGameInfo from "../../hooks/useGameInfo";
import { selectPopup, selectPopupIsLoaded } from "../../../store/overlay/selectors";
import { selectTime } from "../../../store/time/selectors";
import useTimer from "../../hooks/useTimer";

function SyncStateButton() {
  const dispatch = useDispatch<AppDispatch>()
  const { match, isSynchronization } = useGameInfo()
  const [prevStateSynchonization, setPrevStateSynchonization] = useState(false)
  
  const popup = useSelector(selectPopup)
  const popupIsLoaded = useSelector(selectPopupIsLoaded)
  const gameInfo = useGameInfo()
  const time = useSelector(selectTime)
  const { timer } = useTimer()
  
  const handleMessage = useCallback((message: object) => {
    popup?.postMessage(JSON.stringify(message), '*')
  }, [popup]);
  
  const handleClickSync = useCallback(() => {
    if (isSynchronization) return
    if (!match) return
    const matchId = match.id
    dispatch(syncGameInfo({ matchId }))
    dispatch(syncTime({ matchId }))
  }, [dispatch, isSynchronization, match]);
  
  useEffect(() => {
    if (prevStateSynchonization) {
      if (popupIsLoaded && popup) {
        handleMessage({ type: 'syncState', time, timer, gameInfo })
      }
    }
    
    setPrevStateSynchonization(isSynchronization)
  }, [isSynchronization]);
  
  return (
    <UDButton disabled={isSynchronization} onClick={handleClickSync} loading={isSynchronization} variant={'dark'}>
      Синхронизировать состояние
    </UDButton>
  );
}

export default SyncStateButton;
