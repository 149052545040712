import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { deleteTournamentPlayerApplication, getTeamPlayers, upsertTournamentPlayerApplication } from './actions';
import { TournamentUser } from '../../domain/interfaces/TournamentUser';
import { toast } from "react-toastify";

type PlayersSliceState = {
  isGettingPlayers: boolean;
  isLoading: boolean;
  info: TournamentUser[];
};

const initialState: PlayersSliceState = {
  isGettingPlayers: false,
  isLoading: false,
  info: [],
};

type OrganizerReducerBuilder = ActionReducerMapBuilder<PlayersSliceState>;

function createGetTournamentPlayers(builder: OrganizerReducerBuilder) {
  builder.addCase(getTeamPlayers.pending, (state) => {
    state.isGettingPlayers = true;
  });
  
  builder.addCase(
    getTeamPlayers.fulfilled,
    (state, { payload }) => {
      state.info = payload.sort((a, b) => b.id - a.id);
      state.isGettingPlayers = false;
    },
  );
  
  builder.addCase(getTeamPlayers.rejected, (state) => {
    state.isGettingPlayers = false;
  });
}

function createUpsertTournamentPlayerApplication(
  builder: OrganizerReducerBuilder,
) {
  builder.addCase(upsertTournamentPlayerApplication.pending, (state) => {
    state.isLoading = true;
  });
  
  builder.addCase(
    upsertTournamentPlayerApplication.fulfilled,
    (state, { payload }) => {
      if (state.info) {
        const editingPlayer = state.info.find(
          (player) => player.id === payload.id,
        );
        let players;
        
        if (editingPlayer) {
          const editingPlayerIndex = state.info.indexOf(editingPlayer);
          state.info.splice(editingPlayerIndex, 1, payload);
          players = [...state.info].sort((a, b) => b.id - a.id);
        } else {
          players = [...state.info, payload].sort((a, b) => b.id - a.id);
        }
        
        if (players?.length) {
          state.info = [...players];
        }
      }
      state.isLoading = false;
    },
  );
  
  builder.addCase(upsertTournamentPlayerApplication.rejected, (state) => {
    state.isLoading = false;
  });
}

function createDeleteTournamentPlayerApplication(
  builder: OrganizerReducerBuilder,
) {
  builder.addCase(deleteTournamentPlayerApplication.pending, (state) => {
    state.isLoading = true;
  });
  
  builder.addCase(
    deleteTournamentPlayerApplication.fulfilled,
    (state, { payload }) => {
      if (state.info) {
        const deletingPlayer = state.info.find(
          (player) => player.id === payload.userId,
        );
        let players;
        
        if (deletingPlayer) {
          const deletingPlayerIndex = state.info.indexOf(deletingPlayer);
          state.info.splice(deletingPlayerIndex, 1);
          players = [...state.info].sort((a, b) => b.id - a.id);
        } else {
          players = [...state.info].sort((a, b) => b.id - a.id);
        }
        
        if (players?.length) {
          state.info = [...players];
        } else {
          state.info = [];
        }
      }
      state.isLoading = false;
    },
  );
  
  builder.addCase(deleteTournamentPlayerApplication.rejected, (state) => {
    state.isLoading = false;
    toast.error('Не удалось выполнить операцию')
  });
}

const slice = createSlice({
  name: 'players',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createGetTournamentPlayers(builder);
    createUpsertTournamentPlayerApplication(builder);
    createDeleteTournamentPlayerApplication(builder);
  },
});

const playersReducer = slice.reducer;
export default playersReducer;
