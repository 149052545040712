import { Theme } from '@emotion/react';

export const theme: Theme = {
  colors: {
    SurfaceIndependence40: '#2D2D47',
    SurfaceCultured10: '#F4F4F5',
    SurfacePlatinum20: '#EAEAEC',
    SurfaceRomanSilver30: '#8F8E99',
    SurfaceRaisinBlack50: '#1A1A29',
    SurfaceRichBlack60: '#0A0A0B',
    AccentNapplesYellow: '#F5D956',
    AccentMediumChampagne: '#FAECAB',
    CaseError: '#FF7061',
    CaseCorrect: '#57CD9C',
    SurfaceWhite: '#FFFFFF',
    AccentJonquil: '#F2CA18',
    AccentCornsilk: '#FFF8D9',
    SurfaceSpaceCadet50: '#23233B',
    BorderGray50: '#F7F8FA',
    DisabledInputColor: '#b5b5b7',
    WhiteGray: '#9093AA',
    GrayF2: '#F2F2F2',
    Gray96: '#969696',
    Black24: '#242424',
    DarkBlue: '#1A1A29',
    Blue: '#212738',
    DarkYellow: '#D9B244',
    Gray: '#686A79',
  },
  fonts: {
    'caption-1': {
      'font-size': '12px',
      'font-weight': 'normal',
      'font-family': 'SF Pro Display, sans-serif',
    },
  },
};
