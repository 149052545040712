import { PostgrestSingleResponse, RealtimeChannel, SupabaseClient } from "@supabase/supabase-js";
import { TmBoardDTO } from "../../domain/interfaces/supabase/tm-boards/dto";
import tmBoardDataMapper from "../../domain/mappers/tm-board-data-mapper";
import { CreateTmBoard, GetTmBoard, UpsertTmBoard } from "../../domain/interfaces/supabase/tm-boards/actions";
import supabase from "../supabase";
import { ObservableField } from "../ObservableField";
import { ConnectionType } from "./match-chronology";
import { TMBoard } from "../../domain/interfaces/supabase/tm-boards/entity";

const table = 'tm_boards'

export default class TmBoards {
  chanelEvents?: RealtimeChannel
  connectionStatus: ObservableField<ConnectionType> = new ObservableField<ConnectionType>("CLOSED")
  private lastDateUpdate: number = Date.now();
  data: ObservableField<TMBoard | undefined> = new ObservableField<TMBoard | undefined>(undefined)
  
  constructor(private supabase: SupabaseClient) {}
  
  public async createBoard({ id }: CreateTmBoard) {
    const { data, error } = await this.supabase
      .from(table)
      .insert({
        id,
        time: 0,
      })
      .select()
      .single() as PostgrestSingleResponse<TmBoardDTO>;
    
    if (error) {
      throw error
    }
    
    return tmBoardDataMapper(data);
  }
  
  public async getBoard({ id }: GetTmBoard) {
    const { data, error } = await this.supabase
      .from(table)
      .select()
      .eq('id', id)
      .single() as PostgrestSingleResponse<TmBoardDTO>;
    
    if (error) {
      console.error(error)
      throw error
    }
    
    return tmBoardDataMapper(data);
  }
  
  public async getOrCreateBoard({ id }: GetTmBoard) {
    try {
      return await this.getBoard({ id })
    } catch (e: any) {
      if (e.code === 'PGRST116') {
        return await this.createBoard({ id })
      } else {
        throw e
      }
    }
  }
  
  public async upsertBoard(data: UpsertTmBoard) {
    const { startTime, isReverse, ...rest } = data
    
    const transformData = {
      ...rest,
      score: JSON.stringify(data.score),
      teams: JSON.stringify(data.teams),
      start_time: startTime,
      is_reverse: isReverse,
    }
    
    const { error } = await this.supabase
      .from(table)
      .upsert(transformData);
    
    if (error) {
      console.error(error)
      return false
    }
    
    return true;
  }
  
  public handleRecordUpdated(data: { new: TmBoardDTO, commit_timestamp: string }) {
    const date = new Date(data.commit_timestamp)
    if (this.lastDateUpdate === date.getTime()) return
    this.data.value = tmBoardDataMapper(data.new)
  }
  
  public async observeChanges(id: string) {
    this.chanelEvents = this.supabase
      .channel(`room${id}`)
      // @ts-ignore
      .on('postgres_changes', {
        event: 'UPDATE',
        schema: 'public',
        table,
        filter: `id=eq.${id}`,
      }, this.handleRecordUpdated.bind(this))
      .subscribe((status, err) => {
        console.debug({ status })
        
        if (err) {
          supabase.overlayLogs.createError({ comment: 'Не удалось подключиться к supabase', message: err.toString() })
          console.error({ err })
        }
        
        this.connectionStatus.value = status
      }, 10000)
  }
  
  public async stopObserveChanges() {
    if (this.chanelEvents) {
      return await this.chanelEvents.unsubscribe()
    }
  }
}
