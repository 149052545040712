import { useCallback, useMemo } from "react";
import { changeGameDetailsVideoUrl } from "../../../../store/actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../store/store";
import { GameDetails } from "../../../../domain/interfaces/GameDetails";
import UrlInput from "../../../../../ud-ui/components/url-input/UrlInput";

interface IProps {
  isLoading: boolean;
  game: GameDetails | null;
}

function MainStreamURL(props: IProps) {
  const dispatch = useDispatch<AppDispatch>()
  
  const { isLoading, game } = props
  const videoUrl = useMemo(() => game?.videoUrls.length ? game.videoUrls[ 0 ] : '', [game]);
  const handleVideoUrlChanged = useCallback((url: string) => {
    dispatch(changeGameDetailsVideoUrl(url));
  }, [dispatch]);
  
  return (
    <UrlInput
      isLoading={isLoading}
      value={videoUrl}
      onChange={handleVideoUrlChanged}
      name='videoUrl'
      placeholder={'Ссылка на трансляцию'}
      style={{ marginBlock: 16 }}
    />
  );
}

export default MainStreamURL;
