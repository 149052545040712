/** @jsxImportSource @emotion/react */
import UDText, { Props as UDTextProps } from "../../../ud-ui/components/text";
import classNames from "classnames";
import { SmoothChangeColorCss } from "./styles";

type Props = Omit<UDTextProps, 'type'>

export function Header(props: Props) {
  return (
    <UDText
      id='header'
      type='subhead'
      {...props}
      className={classNames('fw-900', props.className)}
      style={{
        fontSize: 42,
        lineHeight: '42px',
        color: 'white',
        ...props.style,
      }}
    >
      {props.children}
    </UDText>
  );
}

export function Reset(props: Props & { disabled?: boolean }) {
  const { disabled } = props
  
  return (
    <UDText
      id='reset'
      type='subhead'
      {...props}
      css={SmoothChangeColorCss}
      className={classNames('fw-700', props.className)}
      style={{
        fontSize: 18,
        lineHeight: '18px',
        color: disabled ? '#686A7966' : '#C80000',
        cursor: disabled ? 'not-allowed' : 'pointer',
        userSelect: 'none',
        ...props.style,
      }}
    >
      {props.children}
    </UDText>
  )
}
