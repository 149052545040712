import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const RotateBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  
  background-color: ${({ theme }) => theme.colors.DarkBlue};
`

export const WaterMarkCss = css`
  color: #F7F7F71A;
  background: unset;
  -webkit-text-fill-color: unset;
`
