import TeamName from "../../../components/control-panel/team-name";
import Period from "../../../components/control-panel/period";
import Score from "../../../components/control-panel/score";
import Timer from "../../../components/control-panel/timer";
import { Divider } from "../../../../../ud-ui/components/divider";
import Button from "../../../components/button";
import MonitorWithStandIcon from "../../../../../ud-ui/components/icon/monitor-with-stand-icon";
import { MainBlock } from "./styles";
import { Team, Time } from "../../../../domain/intefaces";

interface IProps {
  teams: [Team, Team]
  handleChangeTeamName: (name: string, index: 0 | 1) => void
  handleChangePeriod: (period: number) => void
  handleChangeScore: (index: 0 | 1, score: number) => void
  handleChangeTimer: (milliseconds: number) => void
  handleToggleTimer: () => void
  handleToggleReverse: () => void
  handleOpenBoard: () => void
  time: number
  period: number
  timer: Time
}

function Desktop(props: IProps) {
  const {
    time,
    timer,
    teams,
    period,
    handleOpenBoard,
    handleChangeScore,
    handleChangeTimer,
    handleToggleTimer,
    handleChangePeriod,
    handleToggleReverse,
    handleChangeTeamName,
  } = props
  
  return (
    <MainBlock>
      <div className='flex-center gap-7 mt-auto'>
        <TeamName
          index={0}
          {...teams[ 0 ]}
          setName={(name) => handleChangeTeamName(name, 0)}
        />
        <Period
          disabled={timer.isActive}
          value={period}
          setValue={handleChangePeriod}
        />
        <TeamName
          index={1}
          {...teams[ 1 ]}
          setName={(name) => handleChangeTeamName(name, 1)}
        />
      </div>
      <div className='flex-center gap-16' style={{ marginTop: 42 }}>
        <Score setValue={(value) => handleChangeScore(0, value)} value={teams[ 0 ].score}/>
        <Timer
          milliseconds={time}
          setValue={handleChangeTimer}
          isReverse={timer.isReverse}
          isTimerStart={timer.isActive}
          handlerToggleReverse={handleToggleReverse}
          handlerToggleTimer={handleToggleTimer}
        />
        <Score setValue={(value) => handleChangeScore(1, value)} value={teams[ 1 ].score}/>
      </div>
      <Divider
        style={{ marginTop: 55 }}
        type='horizontal'
        color='#7B7B7B'
        height='1px'
        width='100vw'
      />
      <div className='flex-center mt-6 mb-auto' style={{ gap: 136 }}>
        <Button onClick={handleOpenBoard} variant='blue' shape='circle'>
          <MonitorWithStandIcon/>
          Посмотреть табло
        </Button>
      </div>
    </MainBlock>
  );
}

export default Desktop;
