import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";
import SizeInputs from "../elements/size/sizeInputs";
import widthValidator from "../../../../domain/validators/width.validator";
import heightValidator from "../../../../domain/validators/height.validator";
import { copyTemplate } from "../../../../store/templates/actions";
import useTemplates from "../../useTemplates";
import { resetPressedState } from "../../../../store/templates/templates";
import templateNameOnEditValidator from "../../../../domain/validators/templateNameOnEditValidator";
import { useOrganizer } from "../../../../../organizer/ui/hooks/useOrganizer";

function useDuplicateModal() {
  const dispatch = useDispatch()
  const { info } = useOrganizer()
  const { activeTemplate, templates, isProcessed, isFinished, currentProcess } = useTemplates()
  
  const validator = useCallback((values: { [ p: string ]: string }) => {
    const errors: { [ p: string ]: string | undefined } = {}
    
    const nameError = templateNameOnEditValidator(
      values.name?.trim(),
      templates?.map(category => category.name),
      activeTemplate?.name,
    )
    if (nameError) {errors.name = nameError}
    const widthError = widthValidator(values.width)
    if (widthError) {errors.width = widthError}
    const heightError = heightValidator(values.height)
    if (heightError) {errors.height = heightError}
    
    return errors;
  }, [templates, activeTemplate]);
  
  const onSubmit = useCallback((values: { [ p: string ]: string }) => {
    if (!activeTemplate) return
    if (!info) return
    const { width, height, name } = values
    const resolution = { width: +width, height: +height };
    dispatch(copyTemplate({
      templateId: activeTemplate.id,
      ownerId: info.id,
      resolution,
      name,
      process: 'duplicate template',
    }))
  }, [activeTemplate, dispatch, info]);
  
  const size = useMemo(() => <SizeInputs readonly={isProcessed}/>, [isProcessed])
  
  const data: IUNModalProps = useMemo(
    () => {
      return {
        onSubmit,
        headerText: 'Дублирование шаблона',
        validator,
        initialValue: {
          name: activeTemplate?.name ? activeTemplate?.name + ' (дубликат)' : '',
          width: activeTemplate?.resolution.width?.toString() ?? '',
          height: activeTemplate?.resolution.height?.toString() ?? '',
        },
        buttonText: 'Дублировать',
        input: {
          placeholder: 'Название шаблона',
        },
        additionalComponents: [size],
        isProcessed,
      }
    },
    [onSubmit, validator, activeTemplate?.name, activeTemplate?.resolution.width, activeTemplate?.resolution.height, size, isProcessed],
  );
  
  const { modal, close, open } = useUniversalModal(data)
  
  useEffect(() => {
    if (!isFinished) { return; }
    if (currentProcess !== 'duplicate template') { return; }
    close()
    dispatch(resetPressedState())
  }, [isFinished, close, dispatch, currentProcess]);
  
  return { modal, close, open }
}

export default useDuplicateModal;
