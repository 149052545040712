import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  announcedChampionshipTeamsDataSelector,
  updatingChampionshipTeamApplicationSelector,
} from '../../../store/championshipTeams/selectors';
import { ApplicationStatus } from '../../../../applications/domain/enums/ApplicationStatus';
import {
  ChampionshipApplicationFiltersOption,
  ChampionshipTeamsFilterValues,
} from '../championship-application-filters/championship-application-filters';
import CircleAttentionIcon from '../../../../ud-ui/components/icon/circle-attention-icon';
import CircleCheckIcon from '../../../../ud-ui/components/icon/circle-check-icon';
import CircleCloseIcon from '../../../../ud-ui/components/icon/circle-close-icon';
import { BodyBlock, HeadBlock } from "./styles";
import UDText from "../../../../ud-ui/components/text";
import CreateTeamPlayerForm from './create-team-player-form';
import PaperClipIcon from "../../../../ud-ui/components/icon/paper-clip-icon";
import EmptyButton from "../../../../ud-ui/components/emptyButton";
import TeamPlayerCard from "./team-player-card";
import TeamLogo from "../../../../ud-ui/components/team-logo";
import ChampionshipApplicationFiltersNew
  from "../championship-application-filters/championship-application-filters-new";

const statusItems: ChampionshipApplicationFiltersOption<ApplicationStatus>[] = [
  {
    status: ApplicationStatus.PENDING,
    icon: CircleAttentionIcon,
    color: 'black',
  },
  {
    status: ApplicationStatus.ACCEPTED,
    icon: CircleCheckIcon,
    color: '#58B879',
  },
  {
    status: ApplicationStatus.DECLINED,
    icon: CircleCloseIcon,
    color: '#FF7061',
  },
];

type ChampionshipTeamPlayersProps = {
  teamId: number;
  championshipId: number;
};

const ChampionshipTeamPlayers = ({ teamId, championshipId }: ChampionshipTeamPlayersProps) => {
  const announcedTeams = useSelector(announcedChampionshipTeamsDataSelector);
  const updatingApplicationId = useSelector(
    updatingChampionshipTeamApplicationSelector,
  );
  const announcedTeam = announcedTeams ? announcedTeams[ teamId ] : null;
  
  const [status, selectStatus] = useState<ApplicationStatus>(
    ApplicationStatus.PENDING,
  );
  const [name, setName] = useState<string>('');
  
  const playersList = useMemo(() => {
    if (!announcedTeam) {
      return [];
    }
    
    return Object.values(announcedTeam.applications || [])
      .filter((app) => status === app.status)
      .filter((app) => {
        const query = name.toLowerCase();
        const firstName = app.player.firstName.toLowerCase();
        const lastName = app.player.lastName.toLowerCase();
        
        return firstName.includes(query) || lastName.includes(query);
      });
  }, [announcedTeam, status, name]);
  
  const countByStatus = useMemo(() => {
    const defaultValue = {
      [ ApplicationStatus.PENDING ]: 0,
      [ ApplicationStatus.ACCEPTED ]: 0,
      [ ApplicationStatus.DECLINED ]: 0,
    };
    
    if (!announcedTeam) {
      return defaultValue;
    }
    
    return Object.values(announcedTeam.applications || []).reduce<
      Record<string, number>
    >((result, item) => {
      const count = result[ item.status ] || 0;
      return {
        ...result,
        [ item.status ]: count + 1,
      };
    }, defaultValue);
  }, [announcedTeam]);
  
  const handleFilterChange = useCallback(
    (values: ChampionshipTeamsFilterValues<ApplicationStatus>) => {
      setName(values.name);
      selectStatus(values.status);
    },
    [],
  );
  
  if (!announcedTeam) {
    return null;
  }
  
  const playerIds = announcedTeam.applications.map((app) => app.player.id);
  
  return (
    <div className='container mt-4'>
      <HeadBlock>
        <div className={'d-flex justify-content-between'}>
          <div className={'d-flex align-items-center'} style={{ gap: 17 }}>
            <TeamLogo size={60} url={announcedTeam.logo?.url}/>
            <UDText type={'subhead'}>{announcedTeam.name}</UDText>
          </div>
          <EmptyButton
            title={'В разработке'}
            disabled={true}
            icon={<PaperClipIcon/>}
            color={'#007AFF'}
            style={{ gap: 6 }}
          >
            <UDText type={'subhead'} style={{ color: 'inherit' }}>Скопировать ссылку для заявки</UDText>
          </EmptyButton>
        </div>
        <CreateTeamPlayerForm
          teamId={teamId}
          championshipId={championshipId}
          existPlayerIds={playerIds}
        />
      </HeadBlock>
      <BodyBlock>
        <div style={{ paddingInline: 20 }}>
          <ChampionshipApplicationFiltersNew<ApplicationStatus>
            selectedStatus={status}
            name={name}
            options={statusItems}
            inputPlaceholder='ФИО игрока'
            countByStatus={countByStatus}
            onChange={handleFilterChange}
          />
        </div>
        <div
          style={{
            marginTop: 10,
            paddingInline: 20,
          }}
        >
          {playersList.map((app, index) => (
            <TeamPlayerCard
              key={app.player.id}
              index={index + 1}
              teamId={app.teamId}
              player={app}
              isUpdating={updatingApplicationId === app.id}
            />
          ))}
        </div>
      </BodyBlock>
    </div>
  );
};

export default ChampionshipTeamPlayers;
