import { DivProps } from "../../../../../typings";
import useGameInfo from "../../hooks/useGameInfo";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { setGoals } from "../../../store/gameInfo/gameInfo";
import { selectPopup } from "../../../store/overlay/selectors";
import Counter from "./counter";

interface IProps extends DivProps {
  teamIndex: number
}

function Score(props: IProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { teamIndex, ...rest } = props
  
  const { goals } = useGameInfo()
  const popup = useSelector(selectPopup)
  const prefix = teamIndex === 0 ? 'first' : 'second'
  
  const teamScore = useMemo(() => {
    return goals?.[ prefix ] ?? 0
  }, [goals, prefix]);
  
  const handleChangeScore = useCallback((newValue: number) => {
    dispatch(setGoals({ [ prefix ]: newValue }))
    popup?.postMessage(JSON.stringify({ type: 'setGoals', [ prefix ]: newValue }))
  }, [dispatch, popup, prefix]);
  
  return (
    <Counter
      setValue={handleChangeScore}
      value={teamScore}
      title={'Счёт'}
      titleWidth={40}
      {...rest}
    />
  );
}

export default Score;
