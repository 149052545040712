/** @jsxImportSource @emotion/react */
import { EventType } from "../../../../../../stream/domain/interfaces/events";
import { MainBlock } from "./styles";
import React, { useState } from "react";
import UDFormInput from "../../../../../../ud-form/components/input";
import UDFormSelect from "../../../../../../ud-form/components/select";
import Row from "./row";
import { CustomSelect } from "./customSelect";
import UDText from "../../../../../../ud-ui/components/text";
import { BoardTypeEnum } from "../../../../../../core/domain/interfaces/supabase/overlay/auxiliary-types";

interface IProps {
  type: BoardTypeEnum;
}

function Type(props: IProps) {
  const [type, setType] = useState(props.type);
  
  return (
    <MainBlock>
      <Row headerText={'Тип табло'}>
        <UDFormSelect
          containerProps={{ style: { width: '100%' } }}
          selectComponent={CustomSelect}
          name={'type'}
          onChange={event => {setType(event?.value)}}
          options={typeOptions}
        />
      </Row>
      <Row headerText={'Событие'}>
        <UDFormSelect
          containerProps={{ style: { width: '100%' } }}
          selectComponent={CustomSelect}
          placeholder={"Выберите события"}
          isDisabled={type !== 'events'}
          name={'events'}
          isMulti
          options={eventsOptions}
        />
      </Row>
      <Row headerText={'Время'}>
        <UDFormInput
          containerProps={{ style: { width: '80px' } }}
          style={{ background: '#F2F2F2', display: 'flex', textAlign: 'center' }}
          disabled={type !== 'events' && type !== 'carousel'}
          name={'duration'}
          type={'number'}
          placeholder={'Длительность в секундах'}
        />
        <UDText type={"subhead"} style={{ color: '#969696' }}>секунд</UDText>
      </Row>
    </MainBlock>
  );
}

export default Type;

export const typeOptions: { value: BoardTypeEnum, label: string }[] = [
  { label: 'Статичный', value: 'static' },
  { label: 'События', value: 'events' },
  { label: 'Карусель', value: 'carousel' },
  { label: 'Телевизор', value: 'tv' },
]

export const eventsOptions: { label: string, value: EventType }[] = [
  { label: 'Гол', value: 'GOAL' },
  { label: 'Угловой', value: 'CORNER' },
  { label: 'Пас и гол', value: 'PASS_AND_GOAL' },
  { label: 'Гол в свои ворота', value: 'OWN_GOAL' },
  { label: 'Пенальти', value: 'PENALTY_GOAL' },
  { label: 'Промах пенальти', value: 'PENALTY_MISSED' },
  { label: 'Красная карточка', value: 'RED_CARD' },
  { label: 'Желтая карточка', value: 'YELLOW_CARD' },
  { label: 'Фол', value: 'FOUL' },
  { label: 'Тайм-аут', value: 'TIME_OUT' },
  { label: 'Замена', value: 'REPLACEMENT' },
  { label: 'Защита ворот', value: 'SAVE' },
]
