import React, { useMemo } from 'react';
import { useLocation, useMatch, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UDMainLayout from '../../../ud-ui/layout/main';
import { selectOrganizerTeamById } from 'modules/organizer/store/selectors';
import UDBreadcrumbs from 'modules/ud-ui/components/breadcrumbs';
import { Pages } from 'modules/navigation/domain/enums/pages';
import TeamEditFormTabs from '../components/edit-team-form/tabs';
import TeamComponentsEditForm from '../components/edit-team-form';

const TeamsEditPage = () => {
  const { id } = useParams();
  const teamId = id ? parseInt(id) : 0;
  const team = useSelector(selectOrganizerTeamById(teamId));
  const teamPlayersPage = useMatch(`${Pages.TEAMS.EDIT}/players`);
  const teamPlayerCreatePage = useMatch(`${Pages.TEAMS.EDIT}/players/create`);
  
  const playerId = Number(useLocation().state?.fromPlayer);
  const fromPlayer = team?.players.find((player) => player.userId === playerId);
  
  const breadcrumbsItems = useMemo(() => {
    const fullName = fromPlayer?.lastName + ' ' + fromPlayer?.firstName + ' ' + (fromPlayer?.middleName ?? '');
    
    const items: { label: string; to: string; state?: any }[] = fromPlayer ? [
      { label: 'Игроки', to: Pages.PLAYERS.INDEX },
      { label: fullName ?? 'Неизвестный игрок', to: Pages.PLAYERS.builders.view(fromPlayer.userId) },
      { label: 'Команды', to: Pages.PLAYERS.builders.view(fromPlayer.userId, 'teams') },
    ] : [{ label: 'Команды', to: Pages.TEAMS.INDEX }];
    
    if (!team) {
      return items;
    }
    
    items.push({
      label: team.name,
      to: Pages.TEAMS.builders.edit(team.id),
      state: playerId && { fromPlayer: playerId },
    });
    
    if (teamPlayersPage || teamPlayerCreatePage) {
      items.push({
        label: 'Игроки',
        to: Pages.TEAMS.builders.edit(team.id, 'players'),
        state: playerId && { fromPlayer: playerId },
      });
      
      if (teamPlayerCreatePage) {
        items.push({
          label: 'Создание игрока',
          to: Pages.TEAMS.builders.edit(team.id, 'players/create'),
          state: playerId && { fromPlayer: playerId },
        })
      }
    }
    
    return items;
  }, [fromPlayer, playerId, team, teamPlayerCreatePage, teamPlayersPage]);
  
  const breadcrumbs = team ? (
    <UDBreadcrumbs className='my-10' breadcrumbs={breadcrumbsItems}/>
  ) : null;
  
  return (
    <UDMainLayout headerContent={breadcrumbs}>
      <div className='container h-100 pt-6'>
        {team && (
          <>
            <div className='pb-6'>
              <TeamEditFormTabs state={{ fromPlayer: playerId }}/>
            </div>
            <div className=''>
              <TeamComponentsEditForm team={team}/>
            </div>
          </>
        )}
      </div>
    </UDMainLayout>
  );
};

export default TeamsEditPage;
