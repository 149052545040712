import styled from "@emotion/styled";

export const FullSizeControlPanelWrapper = styled.div`
  width: 100%;
  height: 100%;

  overflow: auto;

  display: flex;
  flex-direction: column;

  padding: 20px;

  background: #1A1A29;
`

export const TimeAndBoards = styled.div`
  display: flex;
  flex-direction: row;
  
  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
`
