import { DadataAreaType, IDadataArea } from '../../domain';
import { Address } from 'modules/core/domain/interfaces/address';
import getForeignFiasLevel from "../helper/getForeignFiasLevel";

const TYPES_MAP: Record<string, DadataAreaType> = {
  '1': DadataAreaType.REGION,
  '4': DadataAreaType.CITY,
  '6': DadataAreaType.COMMUNITY,
  '7': DadataAreaType.STREET,
  '8': DadataAreaType.HOUSE,
};

function getTypeByFiasLevel(fiasLevel: string): DadataAreaType {
  return TYPES_MAP[ fiasLevel ] || DadataAreaType.CITY;
}

export function mapDadataArea(params: any): IDadataArea {
  const { data, value } = params;
  const house = data.house && data.house_type ? `${data.house_type} ${data.house}` : data.house;
  
  return {
    value,
    type: getTypeByFiasLevel(getForeignFiasLevel({ suggestion: data })),
    city: data.city_with_type,
    region: data.region_with_type,
    country: data.country,
    street: data.street_with_type,
    house,
    lat: data.geo_lat ? parseFloat(data.geo_lat) : undefined,
    lon: data.geo_lon ? parseFloat(data.geo_lon) : undefined,
  };
}

export function mapDadataAreaList(items: Array<any>, types?: DadataAreaType[]): IDadataArea[] {
  const validTypes = types || [
    DadataAreaType.REGION,
    DadataAreaType.CITY,
    DadataAreaType.COMMUNITY,
    DadataAreaType.STREET,
    DadataAreaType.HOUSE,
  ]
  
  return items
    .filter((item) => validTypes.includes(getForeignFiasLevel({ suggestion: item.data })))
    .map(mapDadataArea);
}

export function mapAddressToDadataArea(address: Address): IDadataArea {
  const { id, city, region, country, addressLine1, addressLine2, coords } = address;
  const street = addressLine1;
  const house = addressLine2;
  const value = [region, city, street, house].filter(Boolean).join(', ');
  
  let type: DadataAreaType = DadataAreaType.REGION;
  if (street) {
    type = DadataAreaType.STREET;
  } else if (city) {
    type = DadataAreaType.CITY;
  }
  
  return {
    id,
    type,
    house,
    street,
    city,
    region,
    country,
    lat: coords?.lat,
    lon: coords?.lon,
    value,
  };
}

export function mapDadataAreaToAddress(area: IDadataArea): Address {
  const { id, region, city, street, house, lat, lon } = area;
  const country = area.country || 'Россия';
  const coords = (lat && lon) ? { lat, lon } : undefined;
  
  return {
    id,
    country,
    region: region === city ? country : region,
    city,
    addressLine1: street,
    addressLine2: house,
    coords,
  };
}
