/** @jsxImportSource @emotion/react */
import useParsePopup from "../../hooks/useParsePopup";
import useTmBoard from "../../hooks/useTmBoard";
import size from "../../../domain/constants/size";
import Desktop from "./desktop/desktop";
import Tablet from "./tablet/tablet";
import Mobile from "./mobile/mobile";
import { useEffect, useState } from "react";
import useParseSupabase from "../../hooks/useParseSupabase";
import useTimer from "../../../../../shared/hooks/useTimer";
import { useParams } from "react-router-dom";

function BoardPage() {
  useParsePopup()
  useParseSupabase()
  
  const {
    timer,
    teams,
    period,
  } = useTmBoard()
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)
  
  const id = useParams()?.id
  
  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  
  const orientation = window.screen.orientation.type.includes('portrait') ? 'portrait' : 'landscape'
  const isMobileInPortrait = orientation === 'portrait' && window.innerWidth < size.mobile.width
  const isMobileInLandscape = orientation === 'landscape' && window.innerHeight < size.mobile.height && window.innerWidth < size.tablet
  
  const { timer: time } = useTimer({ ...timer, setTime: () => {} })
  
  switch (true) {
    case isMobileInPortrait || isMobileInLandscape:
      return (
        <Mobile
          roundTo={'floor'}
          time={Boolean(id) ? time : timer.time}
          teams={teams}
          period={period}
        />
      )
    case innerWidth <= size.tablet:
      return (
        <Tablet
          roundTo={'floor'}
          time={Boolean(id) ? time : timer.time}
          teams={teams}
          period={period}
        />
      )
    default:
      return (
        <Desktop
          roundTo={'floor'}
          time={Boolean(id) ? time : timer.time}
          period={period}
          teams={teams}
        />
      )
  }
}

export default BoardPage;
