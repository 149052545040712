import Static from "../canvases/static";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectIsHideControlPanel } from "../../../../stream/store/overlay/selectors";

interface IProps {
  manager: Static;
  isManagerInit: boolean;
}

const controlPanelWidth = 300

function useResizeCanvas(props: IProps) {
  const { manager, isManagerInit } = props
  const isHideControlPanel = useSelector(selectIsHideControlPanel)
  
  useEffect(() => {
    if (!manager) return
    if (!isManagerInit) return
    
    function canvasResize() {
      if (!manager) return
      const sizeOffset = isHideControlPanel ? { width: 0, height: 0 } : { width: controlPanelWidth, height: 0 }
      manager.resizeByScreen({ sizeOffset })
    }
    
    canvasResize()
    window.addEventListener("resize", canvasResize);
    
    return () => {
      window.removeEventListener("resize", canvasResize);
    }
  }, [isHideControlPanel, isManagerInit, manager]);
}

export default useResizeCanvas
