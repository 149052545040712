import React, { ReactNode, RefObject, useEffect, useState } from "react";
import { OptionsWrapper } from "../styles";
import { Divider } from "../../../../ud-ui/components/divider";
import { DivProps } from "../../../../../typings";

type Directions =
  | "left"
  | "right"
  | "top"
  | "bottom"

interface IProps extends DivProps {
  parentRef?: RefObject<HTMLElement>
  direction: Directions
  options: ReactNode[]
  active?: boolean
}

function getPosition(direction: Directions, parentRef?: RefObject<HTMLElement>) {
  if (!parentRef || parentRef?.current === null) return { top: undefined, left: undefined }
  
  const
    parentTop = parentRef.current.offsetTop,
    parentLeft = parentRef.current.offsetLeft,
    parentWidth = parentRef.current.offsetWidth,
    parentHeight = parentRef.current.offsetHeight
  
  switch (direction) {
    case "left":
      return {
        top: parentTop,
        left: parentLeft + parentWidth + 2,
      }
    case "right":
      return {
        top: parentTop,
        left: parentLeft - parentWidth - 2,
      }
    case "top":
      return {
        top: parentTop - 2,
        left: parentLeft,
      }
    case "bottom":
      return {
        top: parentTop + parentHeight + 2,
        left: parentLeft,
      }
  }
}

function getWidth(parentRef?: RefObject<HTMLElement>): number | undefined {
  return parentRef?.current?.offsetWidth;
}

function ModalOptions(props: IProps, ref: React.ForwardedRef<HTMLDivElement>) {
  const { parentRef, direction, options, active, ...rest } = props
  const [position, setPosition] = useState(getPosition(direction, parentRef))
  const [width, setWidth] = useState(getWidth(parentRef))
  
  useEffect(() => {
    const reposition = () => {
      if (parentRef?.current) {
        setWidth(getWidth(parentRef))
        setPosition(getPosition(direction, parentRef))
      }
    };
    
    reposition()
    window.addEventListener('resize', reposition)
    
    return () => {
      window.removeEventListener('resize', reposition)
    }
  }, [direction, parentRef, active])
  
  return (
    <OptionsWrapper
      ref={ref}
      {...rest}
      style={{
        display: active ? 'flex' : 'none',
        width,
        transform: direction === 'top' ? 'translate(0, -100%)' : undefined,
        ...position,
        ...rest.style,
      }}
    >
      {options.map((option, index) => (
        <>
          {index !== 0 &&
            <Divider
              key='divider'
              type={'horizontal'}
              color={'#E6E6E6'}
              height={'0.5px'}
              width={width ? `${width * 0.95}px` : undefined}
            />}
          {option}
        </>
      ))}
    </OptionsWrapper>
  );
}


export default React.forwardRef<HTMLDivElement, IProps>(ModalOptions);
