import React from 'react';
import classNames from 'classnames';
import { NavLink, NavLinkProps, useMatch } from 'react-router-dom';
import { UDTextType } from '../text';

type Props = {
  type?: UDTextType;
  disabled?: boolean;
  checkPath?: string;
} & NavLinkProps;

const UDNavLink = (props: Props) => {
  const { type = 'callout', disabled, className, checkPath, ...otherProps } = props;
  
  const { to } = otherProps;
  const url = checkPath ?? (typeof to === 'string' ? to : to.pathname);
  const isActive = useMatch(url ?? '');
  
  return (
    <NavLink
      {...otherProps}
      className={() =>
        classNames('link', 'text', type, className, {
          disabled,
          active: isActive,
        })
      }
    />
  );
};

export default UDNavLink;
