import Static from "../../canvases/static";
import { useCallback, useEffect } from "react";
import { selectActiveBoard } from "../../../../../template-editor/store/templates/selectors";
import { useSelector } from "react-redux";

interface IProps {
  manager: Static;
}

const errorPercent = 10

function useOnLoadedTV(props: IProps) {
  const { manager } = props
  const activeBoard = useSelector(selectActiveBoard)
  
  useEffect(() => {
    if (!manager) return
    const body = document.getElementsByTagName('body')[ 0 ]
    
    if (activeBoard?.type !== 'tv') {
      body?.setAttribute('style', 'background: unset; overflow: hidden')
      return
    }
  }, [activeBoard?.type, manager]);
  
  const onLoaded = useCallback(() => {
    if (!manager) return
    const body = document.getElementsByTagName('body')[ 0 ]
    const allObjects = manager?.canvas?.getObjects()
    const windowSize = manager.resolution.value.width * manager.resolution.value.height
    
    const isWithinErrorPercent = (objectSize: number, windowSize: number, errorPercent: number) => {
      const lowerBound = windowSize * (1 - errorPercent / 100);
      return objectSize >= lowerBound;
    };
    
    const backgroundObject = allObjects?.find(obj => {
      const objectSize = obj.getScaledWidth() * obj.getScaledHeight();
      return isWithinErrorPercent(objectSize, windowSize, errorPercent);
    });
    
    if (backgroundObject) {
      body?.setAttribute('style', `background: ${backgroundObject.fill}; overflow: hidden`)
    } else {
      body?.setAttribute('style', 'background: unset; overflow: hidden')
    }
  }, [manager]);
  
  return { onLoaded }
}

export default useOnLoadedTV
