import styled from "@emotion/styled";
import size from "../../../../domain/constants/size";

export const MainBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.DarkBlue};
  
  width: 100%;
  height: 100%;
  
  display: flex;
  //justify-content: center;
  align-items: center;
  flex-direction: column;
  
  //position: relative;
  overflow: auto;
  overflow-x: hidden;
  
  padding: 10px;
  
  @media screen and
  (orientation: landscape) and
  (max-height: ${size.mobile.height}px) and
  (max-width: ${size.tablet}px) {
    & #counter-button {
      width: 50px !important;
      height: 50px !important;
    }
    
    & #header {
      font-size: 20px !important;
      line-height: 26px !important;
    }
    
    & #reset {
      font-size: 14px !important;
      line-height: 16px !important;
    }
    
    & #upper-block {
      & #team-name {
        & #index {
          display: none !important;
        }
        
        & #name {
          width: 270px !important;
          height: 82px !important;
          
          font-size: 20px !important;
        }
      }
      
      & #period {
        & #header {
          display: none;
        }
      }
    }
    
    & #timer {
      position: relative;
      
      & #reset {
        margin-top: 2px !important;
      }
      
      & #clock {
        margin-top: 18px !important;
        
        width: 164px !important;
        height: 50px !important;
        
        font-size: 40px !important;
        line-height: 40px !important;
      }
      
      & #start-button {
        margin-top: 10px !important;
        
        width: 164px !important;
        height: 50px !important;
        
        font-size: 28px !important;
      }
      
      & .reverse-button {
        margin-top: 16px !important;
        padding-left: calc(14px + 10px) !important;
        
        width: 164px !important;
        height: 34px !important;
        
        & p {
          font-size: 10px !important;
        }
        
        &:after {
          left: 10px;
          width: 14px !important;
          height: 14px !important;
        }
      }
    }
    
    & #score {
      justify-content: start !important;
      margin-top: 10px;
    }
    
    & #open-board-button {
      & #icon {
        display: none;
      }
      
      width: 166px !important;
      height: 40px !important;
      position: absolute;
      right: 20px;
      bottom: 26px;
      
      & .text {
        font-size: 15px !important;
        line-height: 20px !important;
        font-weight: 400 !important;
      }
    }
  }
`
