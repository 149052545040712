import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const MainBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.DarkBlue};
  
  position: relative;
  overflow: hidden;
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  width: 100%;
  height: 100%;
  
  padding: 60px 7vw;
  
  & #header {
    font-size: 2.5vw !important;
    line-height: 2.98611111vw !important;
    margin-block: auto;
    font-weight: 800 !important;
  }
  
  & #score {
    flex-grow: 1;
    width: 100% !important;
    max-width: 100% !important;
    overflow: hidden;
    
    & #team-name {
      font-size: 5.6vw !important;
      line-height: 7vw !important;
    }
    
    & #value {
      margin-top: 4.2vw !important;
      font-size: 25vw !important;
      line-height: 19vw !important;
    }
  }
  
  & #timer {
    margin-top: 2.2vw !important;
  }
  
  & #period, #timer {
    & #header {
      font-size: 2.2vw !important;
      line-height: 3vw !important;
      font-weight: 900 !important;
    }
    
    & #value {
      font-size: 5vw !important;
      line-height: 5vw !important;
    }
  }
  
  & #label {
    margin-top: 1.388888888888889vw !important;
    font-size: 3.3vw !important;
  }
  
  & #advertisement {
    gap: 1.111111vw !important;
    margin-block: auto;
    
    & #logo {
      font-size: 2.916666665vw !important;
      line-height: 3.47222222vw !important;
      
      & #logo-im, #logo-game {
        font-size: inherit !important;
        line-height: inherit !important;
      }
    }
    
    & #url {
      font-size: 1.66666666vw !important;
      line-height: 1.66666666vw !important;
    }
    
    & #description {
      font-size: 1.25vw !important;
      line-height: 1.25vw !important;
    }
    
    & #divider {
      &:after {
        height: 3.894vw !important;
        width: 0.41666vw !important;
      }
    }
  }
`
export const LabelCss = css`
  -webkit-text-fill-color: unset;
  background: unset;
  color: #F7F7F71A;
`
