import React, { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { capitalize } from 'shared/utils';
import { PlayerPosition } from 'modules/teams/domain/enums/PlayerPosition';
import UDInput from 'modules/ud-form/components/input/component';
import UDFormLabel from 'modules/ud-form/components/label';
import { PlayersFilterValues } from '../../../../domain';
import { FilterLink } from './players-filter.styles';
import { TFunction } from "i18next";

const getLocalizedPositions = (t: TFunction) => {
  return Object
    .values(PlayerPosition)
    .map((position) => ({
      key: position,
      label: t(`player.position.${position}`),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

type PlayersFilterProps = {
  className?: string;
  onChange?: (values: PlayersFilterValues) => void;
};

export const PlayersFilter = ({ className, onChange }: PlayersFilterProps) => {
  const { t } = useTranslation();
  const [values, setValues] = useState<PlayersFilterValues>({
    name: '',
    positions: [],
  });

  const localizedPositions = getLocalizedPositions(t);

  const handleNameChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues((prevState) => {
      const newValues = {
      ...prevState,
        name: event.target.value,
      };

      if (onChange) {
        onChange(newValues);
      }

      return newValues;
    });
  }, [onChange]);

  const handleLinkClicked = useCallback((position: PlayerPosition) => () => {
    setValues(prevState => {
      const prevPositions = prevState.positions;
      let newValues;

      if (prevPositions.includes(position)) {
        newValues = {
          ...prevState,
          positions: prevPositions.filter((p) => p !== position),
        };
      } else {
        newValues = {
          ...prevState,
          positions: [...prevPositions, position],
        };
      }

      if (onChange) {
        onChange(newValues);
      }

      return newValues;
    });
  }, [onChange]);

  return (
    <div className={className}>
      <UDFormLabel className="mb-2">
        Выбрать игрока
      </UDFormLabel>
      <UDInput
        value={values.name}
        placeholder="Поиск"
        iconProps={{
          position: 'icon-right',
          name: 'search',
        }}
        onChange={handleNameChanged}
      />
      <div className="mt-6">
        {localizedPositions.map(({ key, label }) => (
          <FilterLink
            key={key}
            className={classNames('text', { active: values.positions.includes(key) })}
            onClick={handleLinkClicked(key)}
          >
            {capitalize(label)}
          </FilterLink>
        ))}
      </div>
    </div>
  );
};
