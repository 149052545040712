import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import useGameInfo from "../useGameInfo";
import { setActiveBoard } from "../../../../template-editor/store/templates/templates";
import { useParams } from "react-router-dom";
import supabase from "../../../../core/supabase/supabase";
import { selectIsBoardLocked } from "../../../store/overlay/selectors";
import { parseEvent, setFouls, setGoals } from "../../../store/gameInfo/gameInfo";
import { addEvents } from "../../../store/overlay/overlay";
import { setPeriod, setTimer } from "../../../store/time/time";
import { Event } from "../../../domain/interfaces/events";
import { selectPeriod } from "../../../store/time/selectors";
import { getFouls } from "../../../domain/utils/getFouls";
import { Score } from "../../../../core/supabase/tables/match-chronology";

function useInitSubscribeOverlay() {
  const dispatch = useDispatch<AppDispatch>()
  const { matchId: id } = useParams()
  
  const matchId = useMemo(() => id ? parseInt(id) : 0, [id]);
  const { organizer, championship } = useGameInfo()
  const isBoardLocked = useSelector(selectIsBoardLocked)
  const statePeriod = useSelector(selectPeriod)
  
  useEffect(() => {
    supabase.matchChronology.observeChanges(matchId)
    
    return () => {
      supabase.matchChronology.stopObserveChanges()
    }
  }, [dispatch, matchId])
  
  useEffect(() => {
    const scoreCallback = (score: Score | null) => {
      if (!score) {
        supabase.overlayLogs.createWarning({
          message: 'No score received',
        })
        return
      }
      dispatch(setGoals({ first: score[ 0 ].score, second: score[ 1 ].score }))
    }
    
    supabase.matchChronology.score.subscribe(scoreCallback)
    
    
    return () => {
      supabase.matchChronology.score.unsubscribe(scoreCallback)
    }
  }, [organizer?.id, championship?.id, matchId, championship?.overlayId, dispatch]);
  
  useEffect(() => {
    const activeBoardCallback = (id: number | null) => {
      if (isBoardLocked) return
      dispatch(setActiveBoard({ id: id ?? undefined }));
    };
    
    const eventsCallback = (newEvents: Event[]) => {
      dispatch(parseEvent({ events: newEvents }))
      if (isBoardLocked) return
      dispatch(addEvents(newEvents));
    };
    
    supabase.matchChronology.activeBoardId.subscribe(activeBoardCallback)
    supabase.matchChronology.events.subscribe(eventsCallback)
    
    return () => {
      supabase.matchChronology.activeBoardId.unsubscribe(activeBoardCallback)
      supabase.matchChronology.events.unsubscribe(eventsCallback)
    }
  }, [dispatch, isBoardLocked]);
  
  useEffect(() => {
    
    const timeCallback = ({ seconds, period }: { seconds: number; period: number }) => {
      dispatch(setTimer({ milliseconds: seconds * 1000 }));
      dispatch(setPeriod(period));
      if (statePeriod !== period) {
        getFouls(matchId).then((fouls) => {
          dispatch(setFouls(fouls))
        })
      }
    };
    
    supabase.matchChronology.currentTime.subscribe(timeCallback)
    
    return () => {
      supabase.matchChronology.currentTime.unsubscribe(timeCallback)
    }
  }, [dispatch, matchId, statePeriod])
}

export default useInitSubscribeOverlay;
