import { DivProps } from "../../../../../typings";
import classNames from "classnames";
import { Divider } from "../../../../ud-ui/components/divider";
import UDText from "../../../../ud-ui/components/text";
import { theme } from "../../../../../styles/theme/theme";

function Advertisement(props: DivProps) {
  
  return (
    <div id='advertisement' {...props} className={classNames('flex-center gap-4', props.className)}>
      <div id='logo' className='flex-row'>
        <UDText
          id='logo-im'
          type='subhead'
          className='fw-800'
          style={{ fontSize: 60, color: theme.colors.DarkYellow }}
        >
          Im
        </UDText>
        <UDText
          id='logo-game'
          type='subhead'
          className='fw-800'
          style={{ fontSize: 60, color: 'white' }}
        >
          Game
        </UDText>
      </div>
      
      <Divider
        id='divider'
        type='vertical'
        color='white'
        width={'6px'}
        height={'60px'}
      />
      
      <div className='flex-column justify-content-center'>
        <UDText
          id='url'
          type='subhead'
          className='fw-700'
          style={{
            fontSize: 32,
            lineHeight: '32px',
            color: 'white',
          }}
        >
          www.imgameapp.ru
        </UDText>
        
        <UDText
          id='description'
          type='subhead'
          className='fw-500 mt-1'
          style={{
            fontSize: 24,
            lineHeight: '24px',
            color: 'white',
          }}
        >
          цифровое спортивное табло
        </UDText>
      </div>
    </div>
  );
}

export default Advertisement;
