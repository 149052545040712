import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function NoWifiIcon(props: IProps) {
  const {
    width = 33,
    height = 35,
    ...rest
  } = props;
  
  return (
    <svg width={width} height={height} viewBox='0 0 33 35' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5009 13.5228C21.2889 13.5231 25.8939 15.401 29.3639 18.7685C29.6252 19.0285 30.0429 19.0252 30.3003 18.7611L32.7981 16.1879C32.9284 16.054 33.0011 15.8726 33 15.6838C32.9989 15.4951 32.9242 15.3145 32.7923 15.1822C23.6845 6.27261 9.31586 6.27261 0.208022 15.1822C0.076089 15.3144 0.00123164 15.4949 1.50622e-05 15.6837C-0.00120151 15.8724 0.071323 16.0539 0.20154 16.1879L2.7001 18.7611C2.9573 19.0256 3.37529 19.0289 3.63643 18.7685C7.10695 15.4008 11.7124 13.5228 16.5009 13.5228ZM16.5704 21.2911C19.2011 21.2909 21.7379 22.289 23.688 24.0914C23.9517 24.3472 24.3672 24.3417 24.6243 24.0789L27.1193 21.5058C27.2506 21.3708 27.3235 21.1877 27.3216 20.9974C27.3198 20.8072 27.2432 20.6256 27.1092 20.4934C21.171 14.8551 11.9749 14.8551 6.03668 20.4934C5.90254 20.6256 5.82601 20.8072 5.82425 20.9976C5.82249 21.1879 5.89565 21.371 6.02731 21.5058L8.52155 24.0789C8.77866 24.3417 9.19414 24.3472 9.45789 24.0914C11.4066 22.2902 13.9414 21.2922 16.5704 21.2911ZM21.6427 26.3135C21.6465 26.5043 21.573 26.6883 21.4396 26.8219L17.1238 31.2677C16.9973 31.3983 16.8248 31.4719 16.6448 31.4719C16.4649 31.4719 16.2924 31.3983 16.1659 31.2677L11.8494 26.8219C11.716 26.6882 11.6427 26.5042 11.6466 26.3134C11.6506 26.1226 11.7315 25.9419 11.8703 25.814C14.6265 23.4344 18.6632 23.4344 21.4194 25.814C21.5581 25.942 21.6389 26.1227 21.6427 26.3135Z'
        fill='currentColor'
      />
      <path d='M28.5 4L5.5 30.5' stroke={'inherit'} strokeWidth='8' strokeLinecap='round'/>
      <path d='M28.5 4L5.5 30.5' stroke='currentColor' strokeWidth='3' strokeLinecap='round'/>
    </svg>
  );
}
