import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import UDTabs from 'modules/ud-ui/components/tabs';
import { Tab } from "../../../ud-ui/components/tabs/interfaces";
import UDText from "../../../ud-ui/components/text";
import { defaultTab, PlayerEditFormPart } from "../../domain/constants/player-card";

type CreateEditFormParams = {
  tab: Tab;
  count?: number;
  isActive?: boolean;
  state?: any
};

const createEditFormTab = (params: CreateEditFormParams): Tab => {
  const { tab, count, isActive, state } = params;
  
  if (count !== undefined) {
    const badgeBgColor = isActive
      ? 'bgc-SurfaceWhite'
      : 'bgc-AccentNapplesYellow';
    const className = classNames([
      'color-SurfaceRaisinBlack50',
      badgeBgColor,
      'p-1',
      'br-4',
      'ml-2',
      'line-height-1',
      'caption-1',
    ]);
    
    return {
      hash: tab.hash,
      label: (
        <>
          {tab.label}{' '}
          <UDText type='subhead' className={className}>
            {count}
          </UDText>
        </>
      ),
    };
  }
  
  return { ...tab, state };
};

type PlayerEditFormTabsProps = {
  state?: any;
};

const PlayerEditFormTabs = ({ state }: PlayerEditFormTabsProps) => {
  const location = useLocation();
  const activeTab = (location.pathname || '').split('/').at(-1);
  
  const tabs = useMemo(() => {
    return Object.values(PlayerEditFormPart).map((tab) =>
      createEditFormTab({
        tab: tab,
        isActive: tab.hash === activeTab,
        state: state,
      }),
    );
  }, [activeTab, state]);
  
  return <UDTabs tabs={tabs} defaultTab={defaultTab}/>;
};

export default PlayerEditFormTabs;
