/** @jsxImportSource @emotion/react */
import TeamName from "../../../components/control-panel/team-name";
import { MainBlock, WaterMarkCss } from "./styles";
import { Team, Time } from "../../../../domain/intefaces";
import Period from "../../../components/control-panel/period";
import Score from "../../../components/control-panel/score";
import TMLabel from "../../../components/TMLabel";
import Timer from "../../../components/control-panel/timer";
import { Divider } from "../../../../../ud-ui/components/divider";
import Button from "../../../components/button";
import MonitorWithStandIcon from "../../../../../ud-ui/components/icon/monitor-with-stand-icon";

interface IProps {
  teams: [Team, Team]
  handleChangeTeamName: (name: string, index: 0 | 1) => void
  handleChangePeriod: (period: number) => void
  handleChangeScore: (index: 0 | 1, score: number) => void
  handleChangeTimer: (milliseconds: number) => void
  handleToggleTimer: () => void
  handleToggleReverse: () => void
  handleOpenBoard: () => void
  time: number
  period: number
  timer: Time
}

function Tablet(props: IProps) {
  const {
    time,
    timer,
    teams,
    period,
    handleOpenBoard,
    handleChangeScore,
    handleChangeTimer,
    handleToggleTimer,
    handleChangePeriod,
    handleToggleReverse,
    handleChangeTeamName,
  } = props
  
  
  return (
    <MainBlock>
      <div className='flex-row gap-5'>
        <TeamName index={0} name={teams[ 0 ].name} setName={(name: string) => {handleChangeTeamName(name, 0)}}/>
        <TeamName index={1} name={teams[ 1 ].name} setName={(name: string) => {handleChangeTeamName(name, 1)}}/>
      </div>
      <Period disabled={timer.isActive} value={period} setValue={handleChangePeriod} className='mt-6'/>
      <div className='flex-row gap-15 flex-center'>
        <Score value={teams[ 0 ].score} setValue={(score: number) => handleChangeScore(0, score)}/>
        <TMLabel fontSize={62} lineHeight={80} css={WaterMarkCss}/>
        <Score value={teams[ 1 ].score} setValue={(score: number) => handleChangeScore(1, score)}/>
      </div>
      <Timer
        style={{ position: "relative", top: -60 }}
        milliseconds={time}
        setValue={handleChangeTimer}
        isReverse={timer.isReverse}
        isTimerStart={timer.isActive}
        handlerToggleReverse={handleToggleReverse}
        handlerToggleTimer={handleToggleTimer}
      />
      <div style={{ position: "relative", bottom: 0, paddingBottom: 25 }}>
        <Divider
          type='horizontal'
          color='#7B7B7B'
          height='1px'
          width='100vw'
        />
        <div className='flex-center mt-6' style={{ gap: 136 }}>
          <Button id='open-board-button' onClick={handleOpenBoard} variant='blue' shape='circle'>
            <MonitorWithStandIcon id='monitor-icon'/>
            Посмотреть табло
          </Button>
        </div>
      </div>
    </MainBlock>
  );
}

export default Tablet;
