import { DivProps } from "../../../../../typings";
import { useSelector } from "react-redux";
import { selectTeams } from "../../../store/gameInfo/selectors";
import PlayerItem from "./playerItem";
import UDText from "../../../../ud-ui/components/text";
import { useMemo } from "react";

interface IProps extends DivProps {
  teamIndex: number
}

function PlayersList(props: IProps) {
  const { teamIndex, className, ...rest } = props
  const teams = useSelector(selectTeams)
  const team = teamIndex === 0 ? teams?.first : teams?.second
  
  const players = useMemo(() => {
    return team?.members ?? []
  }, [team])
  
  const inGame = useMemo(() => {
    return players.filter(player => player.isInvolved)
  }, [players]);
  
  const other = useMemo(() => {
    return players.filter(player => !player.isInvolved)
  }, [players]);
  
  return (
    <div className={'flex-column gap-2 ' + className} {...rest}>
      <div className={'flex-row gap-2'}>
        <UDText style={{ width: 32, textAlign: 'center' }} type={'tip'} color={'#686A79'}>Аватар</UDText>
        <UDText style={{ width: 28, textAlign: 'center' }} type={'tip'} color={'#686A79'}>Номер</UDText>
        <UDText style={{ flexGrow: 1, textAlign: 'center' }} type={'tip'} color={'#686A79'}>Фио</UDText>
        <UDText style={{ width: 28, textAlign: 'center' }} type={'tip'} color={'#686A79'}>Гол</UDText>
        <UDText style={{ width: 28, textAlign: 'center' }} type={'tip'} color={'#686A79'}>ЖК</UDText>
        <UDText style={{ width: 28, textAlign: 'center' }} type={'tip'} color={'#686A79'}>КК</UDText>
      </div>
      {inGame.length > 0 && (
        <>
          <UDText color={'#686A79'} style={{ textAlign: 'center', width: '100%' }} type={'tip'}>В игре</UDText>
          {inGame.map(player => {
            return <PlayerItem key={player.id} player={player}/>
          })}
        </>
      )}
      {other.length > 0 && (
        <>
          <UDText color={'#686A79'} style={{ textAlign: 'center', width: '100%' }} type={'tip'}>Не заявлены</UDText>
          {other.map(player => {
            return <PlayerItem key={player.id} player={player}/>
          })}
        </>
      )}
    </div>
  );
}

export default PlayersList;
