import { TmBoardDTO } from "../interfaces/supabase/tm-boards/dto";
import { TMBoard } from "../interfaces/supabase/tm-boards/entity";

function tmBoardDataMapper(data: TmBoardDTO): TMBoard {
  return {
    id: data.id,
    period: data.period,
    score: JSON.parse(data.score),
    teams: JSON.parse(data.teams),
    time: data.time,
    startTime: data.start_time,
    isReverse: data.is_reverse,
  }
}

export default tmBoardDataMapper
