import { Member } from "../../../domain/interfaces/gameInfo/gameInfoMapped";
import { TeamPlayerAvatar } from "../../../../teams/ui/components/team-card/styles";
import UDText from "../../../../ud-ui/components/text";
import { NumberWrapper } from "./styles";

interface IProps {
  player: Member
}

const defaultAvatar =
  'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png';


function PlayerItem(props: IProps) {
  const { player } = props
  
  return (
    <div className={'flex-row align-items-center gap-2'}>
      <TeamPlayerAvatar url={player.photo ?? defaultAvatar} size={32}/>
      <NumberWrapper backgroundColor={'#2D2D47'}>
        <UDText color={'white'} type={'subhead'}>{player.number}</UDText>
      </NumberWrapper>
      <div className={'flex-row gap-1 flex-grow-1'}>
        <UDText color={'white'} type={'subhead'}>{player.surname}</UDText>
        <UDText color={'white'} type={'subhead'}>{player.name}</UDText>
        <UDText color={'white'} type={'subhead'}>{player.middleName}</UDText>
      </div>
      <NumberWrapper backgroundColor={'#9093AA'}>
        <UDText color={'white'} type={'subhead'}>{player.qtyGoalsMatch}</UDText>
      </NumberWrapper>
      <NumberWrapper backgroundColor={'#D9B244'}>
        <UDText color={'white'} type={'subhead'}>{player.qtyYellowCardsMatch}</UDText>
      </NumberWrapper>
      <NumberWrapper backgroundColor={'#AB138A'}>
        <UDText color={'white'} type={'subhead'}>{player.qtyRedCardsMatch}</UDText>
      </NumberWrapper>
    </div>
  );
}

export default PlayerItem;
