/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import HeaderItem from "../header/elements/headerItem";
import { CustomField, CustomTextarea, disableColor, tooltipCss } from "./styles";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { organizersStoreSelector, selectOrganizerById } from "../../../store/selectors";
import { AppDispatch } from "../../../../../store/store";
import UDText from "../../../../ud-ui/components/text";
import CopyIcon from "../../../../ud-ui/components/icon/copy-icon";
import Tooltip from "../../../../ud-form/components/tooltip";
import PlansBlock from "./components/PlansBlock";
import { toast } from "react-toastify";
import { fetchOrganizers } from "../../../store/actions";
import EmptyButton from "../../../../ud-ui/components/emptyButton";

function OrganizerInfo() {
  const dispatch = useDispatch<AppDispatch>()
  const { id } = useParams();
  const organizer = useSelector(selectOrganizerById(Number(id)))
  const { isLoaded } = useSelector(organizersStoreSelector)
  
  useEffect(() => {
    if (!isLoaded) {
      dispatch(fetchOrganizers())
    }
  }, [dispatch, isLoaded]);
  
  const { t } = useTranslation();
  
  const onClickCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(_ => {
      toast.success('Сохранено в буфер обмена!');
    });
  }
  
  return (
    <div className='row g-2'>
      <div className='col-6'>
        <HeaderItem
          header={{ text: "Название организатора", type: 'body' }}
          className={'mb-4'}
        >
          <CustomField>
            <UDText type={'subhead'}>{organizer?.name}</UDText>
            {organizer?.name && (
              <Tooltip css={tooltipCss} text={'Скопировать'}>
                <EmptyButton
                  onClick={() => onClickCopy(organizer?.name ?? '')}
                  icon={<CopyIcon/>}
                />
              </Tooltip>
            )}
          </CustomField>
        </HeaderItem>
        <HeaderItem
          header={{ text: "Тип спорта", type: 'body' }}
          className={'mb-4'}
        >
          <CustomField>
            <UDText type={'subhead'}>{t(`sportType.${organizer?.sportType}`)}</UDText>
          </CustomField>
        </HeaderItem>
        <HeaderItem
          header={{ text: 'Email', type: 'body' }}
          className={'mb-4'}
        >
          <CustomField>
            <UDText type={'subhead'}>{organizer?.email}</UDText>
            {organizer?.email && (
              <Tooltip css={tooltipCss} text={'Скопировать'}>
                <EmptyButton
                  onClick={() => onClickCopy(organizer?.email ?? '')}
                  icon={<CopyIcon/>}
                />
              </Tooltip>
            )}
          </CustomField>
        </HeaderItem>
        <HeaderItem
          header={{ text: "Телефон", type: 'body' }}
          className={'mb-4'}
        >
          <CustomField>
            <UDText type={'subhead'}>{organizer?.phone}</UDText>
            {organizer?.phone && (
              <Tooltip css={tooltipCss} text={'Скопировать'}>
                <EmptyButton
                  onClick={() => onClickCopy(organizer?.phone ?? '')}
                  icon={<CopyIcon/>}
                />
              </Tooltip>
            )}
          </CustomField>
        </HeaderItem>
        <HeaderItem
          header={{ text: "Город", type: 'body' }}
          className={'mb-4'}
        >
          <CustomField>
            <UDText type={'subhead'}>{organizer?.address.city}</UDText>
          </CustomField>
        </HeaderItem>
        <HeaderItem
          header={{ text: "Описание", type: 'body' }}
          className={'mb-4'}
        >
          <CustomTextarea
            css={disableColor}
            readOnly
            rows={4}
            value={organizer?.description}
          />
        </HeaderItem>
      </div>
      <div className='col-6'>
        <HeaderItem header={{ text: 'Информация о подписке организатора', type: 'body' }}>
          <PlansBlock tournamentId={Number(id)} ownerId={organizer?.adminUser?.id}/>
        </HeaderItem>
      </div>
    </div>
  );
}

export default OrganizerInfo;
