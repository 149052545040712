function msToClock(milliseconds: number, roundTo: 'floor' | 'ceil') {
  const minutes = Math.max(Math.floor(milliseconds / 60000), 0);
  const seconds = (milliseconds % 60000) / 1000
  const roundSeconds = Math.max(roundTo === 'floor' ? Math.floor(seconds) : Math.ceil(seconds), 0);
  
  return (
    minutes.toString().padStart(2, '0') +
    ':' +
    roundSeconds.toString().padStart(2, '0')
  );
}

export default msToClock
