import Static from "../../canvases/static";
import { useCallback, useMemo } from "react";
import FadeIn from "../../addons/fadeIn";
import useReplacer from "../useReplacer";
import useDataForCommon from "../../../../../stream/ui/hooks/useDataForCommon";

interface IProps {
  manager: Static
}

function useOnLoadedCommon(props: IProps) {
  const { manager } = props
  
  const replacer = useReplacer(manager)
  const commonData = useDataForCommon()
  
  const fade = useMemo(() => {
    const newFade = new FadeIn()
    manager && newFade.init(manager)
    return newFade
  }, [manager]);
  
  const onLoaded = useCallback(() => {
    replacer.utils.setOpacityAllObjs(0)
    replacer.common.replace({ startOpacity: 0, data: commonData, isFadeIn: true })
    replacer.event.replace({ startOpacity: 0, isFadeIn: true })
    const allObject = manager.canvas.getObjects().filter(object => object.opacity === 0)
    for (const obj of allObject) {
      fade.fadeIn([obj])
    }
  }, [commonData, fade, manager, replacer.common, replacer.event, replacer.utils]);
  
  return { onLoaded }
}

export default useOnLoadedCommon
