import { useSelector } from "react-redux";
import {
  selectPeriod,
  selectScore,
  selectStatus,
  selectTeamName,
  selectTeams,
  selectTimer,
} from "../../store/tmBoard/selector";
import { Team, Time } from "../../domain/intefaces";
import { LoadingStatus, Status } from "../../../../shared/types/status";

interface OfflineState {
  timer: Time
  teams: [Team, Team]
  period: number
  status: Status<LoadingStatus>
  teamNames: [string, string],
  score: [number, number]
}

function useTmBoard(): OfflineState {
  const timer = useSelector(selectTimer)
  const teams = useSelector(selectTeams)
  const period = useSelector(selectPeriod)
  const status = useSelector(selectStatus)
  const teamNames = useSelector(selectTeamName)
  const score = useSelector(selectScore)
  
  return { timer, teams, period, status, teamNames, score }
}

export default useTmBoard
