import Static from "../canvases/static";
import { Object, StaticCanvas } from "fabric/fabric-impl";
import { time } from "../../../../stream/domain/constants/time";

interface IFadeOutOptions {
  duration?: number
  deleteAfter?: boolean
}

interface IFadeInOptions {
  duration?: number
}

class FadeIn {
  private manager!: Static
  private canvas!: StaticCanvas;
  private isInit = false
  
  init(manager: Static) {
    this.manager = manager
    this.canvas = manager.canvas
    this.isInit = true
  }
  
  checkInit() {
    if (!this.isInit) {
      console.error({ event: 'Using the Fade module before initialization' });
      return false;
    } else {
      return true
    }
  }
  
  fadeOut(
    objects: Object[],
    options?: IFadeOutOptions,
  ): Promise<boolean> {
    if (!this.checkInit()) { return Promise.resolve(false); }
    const { duration = time.second / 2, deleteAfter = true } = options ?? {}
    
    return new Promise((resolve) => {
      if (objects.length <= 0) { return resolve(false); }
      const promiseAll: Promise<void>[] = []
      for (const object of objects) {
        promiseAll.push(new Promise<void>((resolve) => {
          object.animate(
            'opacity',
            0,
            {
              duration,
              onChange: this.canvas.renderAll.bind(this.canvas),
              onComplete: () => {
                deleteAfter && this.canvas.remove(object)
                resolve()
              },
            },
          )
        }))
      }
      
      Promise.all(promiseAll).then(() => {
        resolve(true)
      })
    })
  }
  
  fadeIn(objects: Object[], options?: IFadeInOptions) {
    if (!this.checkInit()) { return Promise.resolve(false); }
    const { duration = time.second / 2 } = options ?? {}
    
    return new Promise((resolve) => {
      if (objects.length <= 0) { return resolve(false); }
      const promiseAll: Promise<void>[] = []
      for (const object of objects) {
        promiseAll.push(new Promise(resolve => {
            object.animate(
              'opacity',
              1,
              {
                from: 0,
                duration,
                onChange: this.canvas.renderAll.bind(this.canvas),
                onComplete: resolve,
              },
            )
          }),
        )
      }
      
      Promise.all(promiseAll).then(() => {
        resolve(true)
      })
    })
  }
}

export default FadeIn
