import { useDispatch, useSelector } from "react-redux";
import useTime from "../../hooks/useTime";
import useTimer from "../../hooks/useTimer";
import { setPeriod, toggleTimer } from "../../../store/time/time";
import PauseIcon from "../../../../ud-ui/components/icon/pause-icon";
import PlayIcon from "../../../../ud-ui/components/icon/play-icon";
import ValueBlock from "../components/valueBlock";
import { millisToMinutesAndSeconds } from "../../../domain/utils/convertTime";
import React from "react";
import { CountButton, PanelItemWrapper, PlayerController } from "./styles";
import { DivProps } from "../../../../../typings";
import Counter from "./counter";
import { selectPopup } from "../../../store/overlay/selectors";
import UDText from "../../../../ud-ui/components/text";

function Period(props: DivProps) {
  const { className = '', style, ...rest } = props
  const dispatch = useDispatch();
  const { period } = useTime()
  const { timer, setTimer, addToTimer, isActive } = useTimer();
  const popup = useSelector(selectPopup)
  
  const onClickToggler = () => {
    dispatch(toggleTimer());
    popup?.postMessage(JSON.stringify({ type: 'toggleTimer', isActive: !isActive }))
  };
  
  const onChangePeriod = (num: number) => {
    setTimer(0)
    dispatch(setPeriod(num));
    popup?.postMessage(JSON.stringify({ type: 'setPeriod', period: num }))
  };
  
  const handleAddToTimer = (num: number) => {
    addToTimer(num)
    popup?.postMessage(JSON.stringify({ type: 'setTimer', milliseconds: timer + num }))
  }
  
  return (
    <PanelItemWrapper
      className={'flex-center flex-column ' + className}
      style={{ gap: 6, maxHeight: 122, ...style }}
      {...rest}
    >
      <UDText style={{ flexGrow: 1 }} type={'headline'} color={'#686A79'}>Тайм</UDText>
      <div
        className={'d-flex align-items-center'}
        style={{ width: '100%', height: 30, justifyContent: 'space-between', gap: 10 }}
      >
        <PlayerController
          className={'flex-center'}
          onClick={onClickToggler}
        >
          {isActive
            ? <PauseIcon width={13} height={14} color={'white'}/>
            : <PlayIcon width={14} height={14} color={'white'}/>
          }
        </PlayerController>
        <Counter value={period} setValue={onChangePeriod}/>
        <ValueBlock
          text={millisToMinutesAndSeconds(timer)}
          textSize={'large'}
          className={'flex-center'}
          style={{ width: 70, height: 30 }}
        />
      </div>
      <div className={'d-flex flex-row justify-content-between'} style={{ gap: 4, width: '100%' }}>
        <CountButton
          style={{ fontSize: 8, color: '#686A79' }}
          onClick={() => handleAddToTimer(-10 * 1000)}
        >-10</CountButton>
        <CountButton
          style={{ fontSize: 8, color: '#686A79' }}
          onClick={() => handleAddToTimer(-5 * 1000)}
        >-5</CountButton>
        <CountButton
          style={{ fontSize: 8, color: '#686A79' }}
          onClick={() => handleAddToTimer(-1 * 1000)}
        >-1</CountButton>
        <CountButton style={{ fontSize: 8, color: '#686A79' }} onClick={() => handleAddToTimer(1000)}>+1</CountButton>
        <CountButton
          style={{ fontSize: 8, color: '#686A79' }}
          onClick={() => handleAddToTimer(5 * 1000)}
        >+5</CountButton>
        <CountButton
          style={{ fontSize: 8, color: '#686A79' }}
          onClick={() => handleAddToTimer(10 * 1000)}
        >+10</CountButton>
      </div>
    </PanelItemWrapper>
  );
}

export default Period;
