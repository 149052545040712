import EmptyButton from "../../../../ud-ui/components/emptyButton";
import RoundPlusIcon from "../../../../ud-ui/components/icon/round-plus-icon";
import { SmoothChangeColorCss } from "../styles";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>;

function Plus(props: Props) {
  const { disabled } = props
  
  return (
    <div
      className={'flex-center'}
      css={SmoothChangeColorCss}
      style={{
        background: disabled ? '#48D27433' : '#48D274',
        padding: 2,
        borderRadius: 8,
        height: 'fit-content',
      }}
    >
      <EmptyButton
        {...props}
        css={SmoothChangeColorCss}
        disabledColor='#FFFFFF66'
        color={'white'}
        icon={
          <RoundPlusIcon
            color={'currentColor'}
            width={56}
            height={56}
            strokeWidth={2}
          />
        }
      />
    </div>
  );
}

export default Plus;
