import styled from '@emotion/styled';
import TabMenuButton from './tab-menu-button';
import { useCallback } from 'react';

type TabMenuProps = {
  className?: string;
  tabs: string[];
  activeTab?: number;
  onChange?: (index: number) => void;
};

const TabMenu = (props: TabMenuProps) => {
  const {
    className,
    tabs,
    activeTab = 0,
    onChange,
  } = props;
  
  const handleOnChange = useCallback((index: number) => () => {
    if (onChange && index !== activeTab) {
      onChange(index);
    }
  }, [onChange, activeTab]);
  
  return (
    <div className={className}>
      {tabs.map((title, index) => (
        title && (
          <TabMenuButton
            className='tab-menu-item'
            key={index}
            isActive={index === activeTab}
            onClick={handleOnChange(index)}
          >{title}</TabMenuButton>
        )
      ))}
    </div>
  );
};

const StyledTabMenu = styled(TabMenu)`
  display: flex;
  background: #EAEAEC;
  padding: 10px 24px;
  
  .tab-menu-item {
    margin-left: 32px;
    
    &:first-of-type {
      margin-left: 0;
    }
  }
`;

export default StyledTabMenu;
