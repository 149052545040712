import { TmBoardSliceState } from "./tmBoard";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchTmBoard } from "./actions";
import { toast } from "react-toastify";
import checkInitTime from "./handlers/checkInitTime";

type ReducerBuilder = ActionReducerMapBuilder<TmBoardSliceState>;

export function createFetchTmBoardReducer(builder: ReducerBuilder) {
  builder.addCase(fetchTmBoard.pending, (state) => {
    state.isLoading = true
    state.isLoaded = false
    state.type = 'online'
  });
  
  builder.addCase(fetchTmBoard.fulfilled, (state, action) => {
    state.isLoading = false
    state.isLoaded = true
    const data = action.payload
    
    state.period = data.period
    state.score = data.score
    state.teams = data.teams
    state.isReverse = data.isReverse
    
    state.time = checkInitTime(data.time, data.startTime, data.isReverse)
    const isTimerStart = Boolean(data.startTime)
    state.isTimerStart = isTimerStart
    
    if (isTimerStart) {
      state.startTime = Date.now()
      localStorage.setItem('startTime', state.startTime.toString())
    }
    
    localStorage.setItem('period', JSON.stringify(state.period))
    localStorage.setItem('score', JSON.stringify(state.score))
    localStorage.setItem('teams', JSON.stringify(state.teams))
    localStorage.setItem('isReverse', state.isReverse.toString())
    localStorage.setItem('time', state.time.toString())
  });
  
  builder.addCase(fetchTmBoard.rejected, (state) => {
    state.isLoading = false
    state.isLoaded = false
    toast.error('Не удалось выполнить операцию')
  });
}
