/** @jsxImportSource @emotion/react */
import { LabelCss, MainBlock } from "./styles";
import Header from "../../../components/board/header";
import Score from "../../../components/board/score";
import Period from "../../../components/board/period";
import Timer from "../../../components/board/timer";
import TMLabel from "../../../components/TMLabel";
import Advertisement from "../../../components/board/advertisement";
import { Team } from "../../../../domain/intefaces";

interface IProps {
  teams: [Team, Team],
  period: number,
  time: number,
  roundTo: 'floor' | 'ceil'
}

function Desktop(props: IProps) {
  const {
    teams,
    period,
    time,
    roundTo,
  } = props
  
  return (
    <MainBlock>
      <Header value='ПРИВЕТСТВУЕМ УЧАСТНИКОВ ИГРЫ'/>
      <div
        id='center'
        className='w-100 flex-row align-items-center justify-content-between mt-12'
      >
        <Score id='score' score={teams[ 0 ].score} teamName={teams[ 0 ].name}/>
        <div
          className='flex-center flex-column'
        >
          <Period id='period' value={period}/>
          <Timer id='timer' className='mt-8' milliseconds={time} roundTo={roundTo}/>
          <TMLabel
            id='label'
            css={LabelCss}
            fontSize={62}
            style={{ marginTop: 20 }}
          />
        </div>
        <Score id='score' score={teams[ 1 ].score} teamName={teams[ 1 ].name}/>
      </div>
      <Advertisement className='mt-14'/>
    </MainBlock>
  );
}

export default Desktop;
