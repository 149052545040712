import UDText from "../../../../ud-ui/components/text";
import { CountButton } from "./styles";
import RoundMinusIcon from "../../../../ud-ui/components/icon/round-minus-icon";
import RoundPlusIcon from "../../../../ud-ui/components/icon/round-plus-icon";
import { DivProps } from "../../../../../typings";

interface IProps extends DivProps {
  setValue: (value: number) => void
  value: number
  title?: string
  titleWidth?: number
}

function Counter(props: IProps) {
  const {
    value,
    setValue,
    title,
    titleWidth,
    className = '',
    ...rest
  } = props
  
  return (
    <div className={'d-flex flex-row ' + className} {...rest}>
      <div className={'d-flex'} style={{ width: titleWidth ?? 'unset' }}>
        <UDText type={"subhead"} color={"#686A79"}>{title && title + ':'}</UDText>
      </div>
      <div className={'ml-4 d-flex gap-2'}>
        <CountButton
          onClick={() => setValue(value - 1)}
          icon={<RoundMinusIcon/>}
        />
        <div className={'flex-center'} style={{ width: 20 }}>
          <UDText type={"subhead"} className={'fw-700'} color={"#686A79"}>{value}</UDText>
        </div>
        <CountButton
          onClick={() => setValue(value + 1)}
          icon={<RoundPlusIcon/>}
        />
      </div>
    </div>
  );
}

export default Counter;
