/** @jsxImportSource @emotion/react */
import TMLabel from "../../../components/TMLabel";
import BackgroundGlowing from "../../../components/main/background-glowing";
import * as S from "./styles";
import { GlowingCss, HoverLinkCss } from "./styles";
import Button from "../../../components/button";
import MonitorWithStandIcon from "../../../../../ud-ui/components/icon/monitor-with-stand-icon";
import ModalOptions from "../../../components/main/modal-options";
import { useEffect, useRef, useState } from "react";
import NoWifiIcon from "../../../../../ud-ui/components/icon/no-wifi-icon";
import { Option } from "../../../components/styles";
import WifiIcon from "../../../../../ud-ui/components/icon/wifi-icon";
import UDText from "../../../../../ud-ui/components/text";
import Link from "../../../../../ud-ui/components/link";
import useOfflineModal from "./hooks/useOfflineModal";
import useOnlineModal from "./hooks/useOnlineModal";
import size from "../../../../domain/constants/size";
import { DivProps } from "../../../../../../typings";

const supportUrl = 'https://imgameapp.ru/#contacts'

const backgroundProps = {
  style: { zIndex: -1 },
  color: '#511D7E80',
  size: 384,
  blur: 100,
  glowingProps: {
    css: GlowingCss('#511D7E80', '#511D7E'),
  },
}

function RightSide(props: DivProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [openModalOptions, setOpenModalOptions] = useState(false);
  const { open: openOfflineModal, Modal: OfflineModal } = useOfflineModal();
  const { open: openOnlineModal, Modal: OnlineModal } = useOnlineModal();
  
  const handleClickCreateBoard = () => {
    setOpenModalOptions((prevState) => !prevState);
  };
  
  const handleOpenOfflineModal = () => {
    openOfflineModal();
    setOpenModalOptions(false);
  };
  
  const handleOpenOnlineModal = () => {
    openOnlineModal();
    setOpenModalOptions(false);
  };
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        !buttonRef.current?.contains(event.target as Node)
      ) {
        setOpenModalOptions(false);
      }
    };
    
    if (openModalOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openModalOptions]);
  
  const options = [
    <Option key={'offline'} onClick={handleOpenOfflineModal}>
      <NoWifiIcon className='option-icon'/>
      <UDText type={'title-3'}>Оффлайн табло</UDText>
    </Option>,
    <Option key={'online'} onClick={handleOpenOnlineModal}>
      <WifiIcon className='option-icon'/>
      <UDText type={'title-3'}>Онлайн табло</UDText>
    </Option>,
  ];
  
  const { innerWidth: width, innerHeight: height } = window;
  const { width: maxWidth, height: maxHeight } = size.mobile;
  const orientation = window.screen.orientation;
  const isLandscape = orientation.type.includes('landscape');
  const isMobile =
    width <= maxWidth ||
    (isLandscape && height <= maxHeight && width <= size.tablet);
  
  return (
    <S.RightSide {...props} style={{ zIndex: 0, ...props.style }}>
      <BackgroundGlowing className='tm-logo' {...backgroundProps}>
        <TMLabel fontSize={320} style={{ height: 416 }} className={'flex-center'}/>
      </BackgroundGlowing>
      <Button className='create-board-button' ref={buttonRef} onClick={handleClickCreateBoard}>
        <MonitorWithStandIcon className='monitor-icon'/>
        Создать табло
      </Button>
      <UDText className={'mt-5'} color={'white'} type={'subhead'}>
        Если остались вопросы напишите нам в <Link to={supportUrl} css={HoverLinkCss}>поддержку</Link>
      </UDText>
      
      <ModalOptions
        ref={modalRef}
        active={openModalOptions}
        parentRef={buttonRef}
        direction={isMobile ? 'top' : "bottom"}
        options={options}
      />
      
      <OfflineModal/>
      <OnlineModal/>
    </S.RightSide>
  );
}

export default RightSide;
