import React, { useEffect, useState } from "react";
import useFabric from "../../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import useReplacer from "../../../../ud-ui/components/fabric-canvas/hooks/useReplacer";
import useTemplates from "../../../../template-editor/ui/hooks/useTemplates";
import { millisToMinutesAndSeconds } from "../../../domain/utils/convertTime";
import useTimer from "../../hooks/useTimer";
import { CanvasWrapper } from "./styles";
import useGameInfo from "../../hooks/useGameInfo";
import { useSelector } from "react-redux";
import { selectEvents } from "../../../store/overlay/selectors";
import useTime from "../../hooks/useTime";
import parseInfoEvent from "../../../store/gameInfo/utils/parse-info.event";
import useLoadEvent from "../../../../ud-ui/components/fabric-canvas/hooks/onLoadedHooks/useLoadEvent";
import useResizeCanvas from "../../../../ud-ui/components/fabric-canvas/hooks/useResizeCanvas";

export function CanvasEventBoards() {
  const { canvas, manager } = useFabric('static', { id: "events" })
  const replacer = useReplacer(manager)
  const { activeTemplate } = useTemplates()
  const { timer } = useTimer();
  const { period } = useTime()
  const events = useSelector(selectEvents)
  const gameInfo = useGameInfo()
  
  const [isManagerInit, setIsManagerInit] = useState(false)
  
  useLoadEvent({ manager })
  
  useResizeCanvas({ manager, isManagerInit })
  
  useEffect(() => {
    if (!manager) return
    if (!activeTemplate) return
    manager?.setResolution(activeTemplate.resolution)
    manager?.setDimensions(activeTemplate.resolution)
    manager.normalizedViewport()
    setIsManagerInit(true)
  }, [activeTemplate, manager])
  
  useEffect(() => {
    if (!manager) return
    const { goals, fouls } = gameInfo
    
    replacer.updateText({ indicator: 'teamScore0', text: goals.first.toString() })
    replacer.updateText({ indicator: 'teamScore1', text: goals.second.toString() })
    replacer.updateText({ indicator: 'teamFouls0', text: fouls.first.toString() })
    replacer.updateText({ indicator: 'teamFouls1', text: fouls.second.toString() })
    
    replacer.updateText({ indicator: 'teamScore', text: goals.first.toString(), teamIndex: 0 })
    replacer.updateText({ indicator: 'teamScore', text: goals.second.toString(), teamIndex: 1 })
    replacer.updateText({ indicator: 'teamFouls', text: fouls.first.toString(), teamIndex: 0 })
    replacer.updateText({ indicator: 'teamFouls', text: fouls.second.toString(), teamIndex: 1 })
    // eslint-disable-next-line
  }, [gameInfo.fouls, gameInfo.goals, replacer])
  
  useEffect(() => {
    // Обновление счёта в карточе события, так, как там другой placeholder
    const { goals, fouls, teams } = gameInfo
    
    const event = events[ 0 ]
    if (!event) return
    const { teamPrefix } = parseInfoEvent(event, teams)
    const { teamPrefix: reversePrefix } = parseInfoEvent(event, teams, { teamReverse: true })
    
    if (teamPrefix) {
      replacer.updateText({
        indicator: 'score',
        text: goals[ teamPrefix ].toString().toString(),
        teamReverse: false,
      })
      
      replacer.updateText({
        indicator: 'fouls',
        text: fouls[ teamPrefix ].toString().toString(),
        teamReverse: false,
      })
    }
    
    if (reversePrefix) {
      replacer.updateText({
        indicator: 'score',
        text: goals[ reversePrefix ].toString().toString(),
        teamReverse: true,
      })
      
      replacer.updateText({
        indicator: 'fouls',
        text: fouls[ reversePrefix ].toString().toString(),
        teamReverse: true,
      })
    }
  }, [events, gameInfo, replacer]);
  
  useEffect(() => {
    if (!manager) return
    replacer.updateText({ indicator: 'matchTime', text: millisToMinutesAndSeconds(timer) })
    replacer.updateText({ indicator: 'period', text: period.toString() })
  }, [replacer, timer, period, manager])
  
  
  return (
    <CanvasWrapper>
      {canvas}
    </CanvasWrapper>
  );
}
