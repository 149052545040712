/** @jsxImportSource @emotion/react */
import UDText, { Props as UDTextProps } from "../../../ud-ui/components/text";
import { TextGradient } from "./styles";

interface Props extends Omit<UDTextProps, 'type' | 'children'> {
  fontSize?: number
  lineHeight?: number
}

function TMLabel(props: Props) {
  const { fontSize, lineHeight, ...rest } = props
  
  return (
    <UDText
      type={'subhead'}
      {...rest}
      css={TextGradient('#EE00FF 13.32%', '#009DFF 102.09%', '127.46deg')}
      style={{
        fontFamily: 'Saira Stencil One',
        fontSize,
        lineHeight: lineHeight ? `${lineHeight}px` : 'unset',
        ...rest.style,
      }}
    >
      TM
    </UDText>
  );
}

export default TMLabel;
