import { millisToMinutesAndSeconds } from "../../domain/utils/convertTime";
import useTimer from "./useTimer";
import useTime from "./useTime";
import useGameInfo from "./useGameInfo";

function useDataForCommon() {
  const { timer } = useTimer()
  const { period } = useTime()
  const gameInfo = useGameInfo()
  
  return {
    ...gameInfo,
    timer: millisToMinutesAndSeconds(timer),
    period,
    championshipName: gameInfo.championship?.name,
    city: gameInfo.match?.address.city,
    organizerLogo: gameInfo.organizer?.logo,
    teams: {
      first: {
        ...gameInfo?.teams?.first,
        lastGamesResults: gameInfo.teams?.first.lastGamesResults.map(x => x.result),
      },
      second: {
        ...gameInfo?.teams?.second,
        lastGamesResults: gameInfo.teams?.second.lastGamesResults.map(x => x.result),
      },
    },
  }
}

export default useDataForCommon
