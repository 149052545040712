import styled from "@emotion/styled";
import size from "../../../../domain/constants/size";

export const MainBlock = styled.div`
  @media (max-width: ${size.tablet}px) {
    padding-right: 50px;
  }
  
  @media (max-width: ${size.mobile.width}px) {
    padding-right: unset;
    
    & > .header {
      font-size: clamp(24px, 5vw, 45px) !important;
      line-height: clamp(28px, 5vw, 45px) !important;
      
      margin-top: 20px !important;
    }
    
    
    & > .description {
      & p {
        font-weight: 300;
      }
      
      & p, span {
        font-size: 16px !important;
        line-height: 21px !important;
      }
      
      margin-top: 20px !important;
    }
  }
  
  @media screen and (orientation: landscape) and (max-height: ${size.mobile.height}px) and (max-width: ${size.tablet}px) {
    padding-right: unset;
    
    & > .header {
      width: 45% !important;
      
      font-size: clamp(24px, 2vw, 45px) !important;
      line-height: clamp(28px, 2vw, 45px) !important;
      
      margin-top: 12px !important;
    }
    
    & > .description {
      width: 90% !important;
      gap: 2px;
      
      & p {
        font-weight: 300;
      }
      
      & p, span {
        font-size: 14px !important;
        line-height: 18px !important;
      }
      
      margin-top: 12px !important;
    }
  }
`
